/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Grid from '@mui/material/Grid'
import useAuthorization, {AccessGroups} from '../../hooks/useAuthorization'

export const REPORT_TYPES = [
  {id: 'scada', label: 'SCADA'},
  {id: 'price', label: 'Price'},
  {id: 'reserves', label: 'Reserves'},
  {id: 'arc', label: 'Arc Flows'},
  {id: 'emissions', label: 'NZ Emissions'},
  {id: 'rcpd', label: 'RCPD'},
]

const ReportTypeFilter = ({filter, onChange}) => {

  const { permittedReports } = useAuthorization()

  const permittedReportTypes = REPORT_TYPES.filter(({id}) => permittedReports.includes(id))

  const handleChange = att => event => onChange({...filter, nodes: [], [att]: event.target.value})

  return (
    <Grid item xs={12} md={6}>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="report-type">Report</InputLabel>
        <Select
          labelId="report-type"
          value={filter.reportType || ''}
          label="Report"
          onChange={ handleChange('reportType') }
        >
          {permittedReportTypes.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
        </Select>
      </FormControl>
    </Grid>
  )

}

const styles = {
}

export default ReportTypeFilter