/** @jsxImportSource @emotion/react */
import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import { formatLocaleDateTime, formatLocaleDate } from 'utils'

export const formatTimestamp = (timestamp, {tradingPeriod=null, time=true}={}) => {
  const tp = tradingPeriod ? `TP${tradingPeriod} ` : ''
  return `${tp}${time ? formatLocaleDateTime(timestamp) : formatLocaleDate(timestamp)}`
}

export const TooltipList = ({children}) =>
  <List dense className="tooltip-list" css={styles.list}>
    {children}
  </List>


export const TooltipListItem = ({label, value, secondaryLabel, secondaryValue, color, strokeDasharray}) =>
  <ListItem disablePadding>
    {color &&
      <ListItemIcon css={styles.icon}>
        {strokeDasharray ? <FiberManualRecordOutlinedIcon style={{color}}/> : <FiberManualRecordSharpIcon style={{color}}/>}
      </ListItemIcon>}
    <ListItemText
      primary={<>{label} <span css={styles.value}>{value}</span></>}
      secondary={<>{secondaryLabel} <span css={styles.value}>{secondaryValue}</span></>}
    />
  </ListItem>

export const TooltipHeader = ({label}) =>
  <ListItem disablePadding>
    <ListItemText css={styles.header} primary={label}/>
  </ListItem>

const styles = {
  list: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    padding: 10,
    borderRadius: 10,
  },
  value: {
    float: 'right',
    marginLeft: 40,
  },
  header: {
    textAlign: 'center',
    '& .MuiListItemText-primary': {
      fontWeight: 600,
    }
  },
  icon: {
    minWidth: 35
  }
}
