/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import CycloneIcon from '@mui/icons-material/Cyclone'
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource, useFilter} from 'hooks'
import { Widgets } from './'
import {useSavedFilters} from 'contexts/SavedFiltersContext'

const DEFAULT_FILTER = {
  gridZone: '15'
}

const StackedGenerationGraph = (props) => {
  const widget = Widgets['stacked-generation-graph']
  const [filter, setFilter] = useFilter(null, 'stackedgwap')
  const savedFilters = useSavedFilters()

  const [price, priceActions] = useResource('price')
  const [loading, result, reload] = useDependency(
    () => priceActions.request('free', {grid_zone_id: filter.gridZone}),
    [filter])
  const updatedAt = price.free?.responseAt

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({...initialFilter, ...filter})
  }, [])

  return (
    <WidgetContainer
      widget={widget}
      title="Generation Quantities (MWh)"
      icon={<CycloneIcon/>}
      lastUpdatedAt={updatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={loading}
      onWidgetRefresh={reload}
      renderFilter={() => <Graphs.GridZoneFilter filter={filter} onChange={setFilter} />}
      onFilterSave={() => savedFilters.update(widget, filter)}
      zoom={zoom}
      {...props}
    >
      <Graphs.StackedGeneration
        data={price.free?.data}
        zoomCallback={zoomCallback}
      />
    </WidgetContainer>
  )
}

export default StackedGenerationGraph