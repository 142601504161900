import useQueryString from 'hooks/useQueryString'
import { useCallback } from 'react'

const emptyFilter = {}

const useFilter = (setPage, namespace) => {
  const [urlState, setUrlState] = useQueryString(namespace, 'filter')

  const filter = urlState.filter || emptyFilter
  const setFilter = useCallback((newFilter) => {
    setUrlState((prevState) => {
      const updatedFilter = { ...(prevState?.filter || {}), ...newFilter }
      const cleanedFilter = {}
      Object.keys(updatedFilter || {}).forEach((key) => {
        if (updatedFilter[key] || updatedFilter[key] === 0) {
          cleanedFilter[key] = updatedFilter[key]
        }
      })
      return { filter: cleanedFilter }
    })
    if (setPage) {
      setPage(1)
    }
  }, [setPage, setUrlState])

  return [filter, setFilter]
}
export default useFilter
