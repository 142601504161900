export type FormDataToJsonApiOpts = {
  nested?: string[];
  linked?: string[];
}
export function formDataToJsonApi(formData: any, {nested = [], linked = []}: FormDataToJsonApiOpts = {}) {
  const attributesToSave = {...formData, relationships: {}}

  linked.forEach(attribute => {
    const relationship = attributesToSave[attribute]
    delete attributesToSave[attribute]
    if (relationship) {
      if (Array.isArray(relationship)) {
        attributesToSave.relationships[attribute] = {data: relationship.map(({type, id}) => ({type, id}))}
      } else {
        attributesToSave.relationships[attribute] = {data: {type: relationship.type, id: relationship.id}}
      }
    }
  })
  nested.forEach(attribute => {
    const relationship = attributesToSave[attribute]
    delete attributesToSave[attribute]
    if (relationship) {
      attributesToSave.relationships[attribute] = {data: relationship}
    }
  })

  return attributesToSave
}