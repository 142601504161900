import React from 'react'

const CustomReferenceLabel = (props: any) => {
  const {
    fill, value,
    fontSize, viewBox, dy, dx,
  } = props;
  const x = viewBox.width + viewBox.x;
  const y = viewBox.y - 8;
  return (
    <text
      x={x}
      y={y}
      dy={dy}
      dx={dx}
      fill={fill}
      fontSize={fontSize || 14}
      textAnchor="end">
      {value}
    </text>
  )
}

export default CustomReferenceLabel