import React, { Children } from 'react'
import { humanize, deepGet, deepSet } from 'utils'
import {Checkbox} from "@mui/material";
/**
 * Goals for this component:
 *
 * * The ability to have multiple form contexts in a single render.
 *   Overcomes the disadvantages of the convention in the FormMixin to
 *   have a single context per component
 *
 * * Copy/Pasteability  - Works with any HTML5-like input component without
 *   the need for customised wrapping components
 *
 * * Supports complex nested properties as the FormMixin currently does
 *
 * * Supports an error context with an identical structure to the context
 *
 * * Plays nicely with enhanced input components (such as those in the material-ui library)
 *
 * * Looks like 'plain-old-react'. We want to be able to remove the ControlledForm and
 * still have a functioning and visually equivalent layout
 *
 * * Multiple contexts can be nested and reference different parts of the context tree
 *
 * * Zero coupling to our data model, redux, and API. All we need is
 *   React a context object, and a change handler
 */

export interface FieldPropProvider {
  (formControl: FormControl, elmProps: ElmProps): object;
}

export type ElmProps = Record<string, any> & {
  type?: string;
  name: string;
  errorName?: string;
  elmType: React.ElementType;
}

export interface OnChange {
  (values: Values): void
}

export type FormControl = {
  values: Values,
  errors: Errors,
  onChange: OnChange,
}

export type Errors = Record<string, string>;
export type Values = Record<string, any>;

interface Props {
  children?: React.ReactNode,
  fieldPropProvider: FieldPropProvider,
  onChange: OnChange,
  data: Values,
  errors?: Errors,
  onSubmit: (value: React.FormEvent<HTMLFormElement>) => void,
  autoComplete?: boolean,
  [x:string]: any
}

export const ControlledForm: React.FC<Props> = (props) => {
  const {children, fieldPropProvider, onChange, data, errors = {}, onSubmit, autoComplete, ...other} = props;

  const handleSubmit = (customOnClickHandler?: () => any) => async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    customOnClickHandler && (await customOnClickHandler());
    props.onSubmit && (await props.onSubmit(event));
  };

  const renderChild = (elm: any) => {
    if(!(elm && elm.type)) {
      /**
       * We are not a valid input element
       */
      return elm;
    }

    let {
      type: Type,
      props: {
        name, errorName, inputRef, uncontrolled, children, ...elmProps
      }
    } = elm;

    /**
     * We are a wrapped child
     */

    if (elmProps.type === 'submit') {
      return React.cloneElement(elm, {onClick: handleSubmit(elm.props.onClick)});
    }

    const formControl = {
      values: props.data,
      errors: errors,
      onChange: props.onChange,
    }

    const fieldProps = propsForField(
      formControl,
      props.fieldPropProvider,
      // eslint-disable-next-line
      {...elm.props, propTypes: Type.propTypes, elmType: elm.type}
    );
    children = Children.map(children, child => typeof child !== 'string' ? renderChild(child) : child);

    return (
      <Type ref={inputRef} {...{...fieldProps, ...elmProps}}>
        {(children && children.length === 1) ? children[0] : children}
      </Type>
    );
  };

  if (!onSubmit) {
    return (
      <>
        {Children.map(props.children, renderChild)}
      </>
    )
  } else {
    return (
      <form onSubmit={handleSubmit()} {...other}>
        {autoComplete !== true && <input type='hidden' autoComplete="false" />}
        {Children.map(props.children, renderChild)}
      </form>
    );
  }
}

const propsForField = (formControl: FormControl, fieldPropProvider: FieldPropProvider, props: ElmProps) => {
  let fieldProps = {}
  if (props.name !== undefined && !props.uncontrolled) {
    /**
     * We've opted in to the being controlled by the form.
     * Let's create some default props
     */
    fieldProps = fieldPropProvider(formControl, props)
  }

  return fieldProps
}

export const onChange = (elmProps: ElmProps, formControl: FormControl) => (event: React.ChangeEvent<any>) => {
  const {type, name, elmType} = elmProps;
  const {target: { value, checked }} = event;
  let newValues: Values;
  if (type === "checkbox" || elmType === Checkbox) {
    newValues = deepSet(checked, name, formControl.values);
  } else if (type === 'number') {
    newValues = deepSet(isNaN(parseFloat(value)) ? '' : parseFloat(value), name, formControl.values);
  } else {
    newValues = deepSet(value, name, formControl.values);
  }

  return formControl.onChange(newValues)
};

export const propsForMuiField: FieldPropProvider = (formControl, elmProps) => {
  const {type, name, errorName, elmType, multiple} = elmProps;
  const props: any = {};
  if (type === 'checkbox' || elmType === Checkbox) {
    props.checked = deepGet(name, formControl.values) || false;
  } else {
    props.value    = formControl.values && deepGet(name, formControl.values);
    if (props.value === undefined || props.value === null) {
      props.value = (!!multiple) ? [] : ''
    }
  }
  props.onChange = onChange(elmProps, formControl);
  const errorKey = errorName || name;
  const error = deepGet(errorKey, formControl.errors);
  if (error) {
    const errorText   = error;
    props.error       = true;
    props.helperText  = errorText;
  }
  props.name        = name;
  props.label       = humanize(name.replace(/^.*(?:\[(?=\d)|(?!=\d)\]|\.)(.*)$/, "$1"));
  props.placeholder = "";

  return props;
}

// eslint-disable-next-line
export default props => <ControlledForm fieldPropProvider={propsForMuiField} {...props}/>