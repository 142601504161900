import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import * as Containers from 'containers'
import { Route } from 'containers'
import { useAuthorization } from "hooks";

const Routes = () => {
  const authorization = useAuthorization()
  return (
    <Switch>
      <Route exact path="/favourites"                 component={Containers.Dashboard.Favourites}/>
      <Route exact path="/reports"                    component={Containers.Dashboard.Reports}/>
      <Route exact path="/trading"                    component={Containers.Dashboard.Trading}/>
      <Route exact path="/generation"                 component={Containers.Dashboard.Generation}/>
      <Route exact path="/demand"                     component={Containers.Dashboard.Demand}/>

      <Route exact path="/profile"                    component={Containers.User.Profile}/>
      <Route exact path="/tokens/sign_in"             component={Containers.Token.SignIn}/>
      <Route exact path="/tokens/forgot"              component={Containers.Token.Forgot}/>
      <Route exact path="/reset-password"             component={Containers.Token.Reset}/>

      <Route exact path="/"                           component={Containers.Dashboard.Home}/>
      <Route exact path="/not_found"                  component={Containers.Status.NotFound} />
      <Route exact path="/timed_out"                  component={Containers.Status.TimedOut} />
      <Route exact path="/inactive"                   component={Containers.Status.Inactive} />
      <Route exact path="/confirm_forgot_password"    component={Containers.Status.ForgotPassword} />
      <Route exact path="/unauthorized"               component={Containers.Status.Unauthorized} />
      <Route component={Containers.Status.NotFound} />
    </Switch>
  )
}

export default Routes