import Widget from './Widget'
import RegionalPriceMap from './RegionalPriceMap'
import NodeLoadMap from './NodeLoadMap'
import NodePriceMap from './NodePriceMap'
import IslandLoadGraph from './IslandLoadGraph'
import GenerationGraph from './GenerationGraph'
import ResidualsGraph from './ResidualsGraph'
import CarbonDial from './CarbonDial'
import Report from './Report'
import WindForecastGraph from './WindForecastGraph'
import StackedGenerationGraph from './StackedGenerationGraph'
import InterimPriceGraph from './InterimPriceGraph'
import RegionalPredictedLoadGraph from './NRSLRegionalPredictedLoadGraph'
import NodePredictedLoadGraph from './NodePredictedLoadGraph'
import ReservesGraph from './ReservesGraph'
import PreviousDayPricesGraph from './PreviousDayPricesGraph'
import HVDCGraph from './HVDCGraph'
import RegionalPeaksGraph from './RegionalPeaksGraph'
import YearlyRCPDPeaksGraph from './YearlyRCPDPeaksGraph'
import GenerationCapacityGraph from './GenerationCapacityGraph'
import NRSSRegionalPredictedLoadGraph from "./NRSSRegionalPredictedLoadGraph"
import { AccessGroups } from '../hooks/useAuthorization'

//
// List of widget definitions that (by default) can be added to Favourites
// Each widget should have a unique ID & map to a component
// Groups refers to the required AWS Cognito user groups
//
const Widgets = [
  {
    id: 'regional-price-map',
    name: 'Price Map',
    description: 'Map with energy prices per region',
    component: RegionalPriceMap,
    groups: [], // free widget
  },
  {
    id: 'node-load-map',
    name: 'Load Map',
    description: 'Map with load per node',
    component: NodeLoadMap,
    hideFilter: true,
    groups: [AccessGroups.EM6_PUBL],
  },
  {
    id: 'node-price-map',
    name: 'Price Map',
    description: 'Map with price per node',
    component: NodePriceMap,
    groups: [AccessGroups.EM6_PUBL],
    hideFilter: true,
  },
  {
    id: 'island-load-graph',
    name: 'NZ Load',
    description: 'Graph of NZ load',
    component: IslandLoadGraph,
    groups: [AccessGroups.EM6_PUBL],
  },
  {
    id: 'generation-graph',
    name: 'NZ Generation',
    description: 'Graph of NZ generation',
    component: GenerationGraph,
    groups: [AccessGroups.EM6_PUBL],
  },
  {
    id: 'residuals-graph',
    name: 'NZ Residuals',
    description: 'Graph of NZ Residuals',
    component: ResidualsGraph,
    groups: [AccessGroups.EM6_RCPD],
  },
  {
    id: 'carbon-dial',
    name: 'Carbon emissions dial',
    description: 'Graph of NZ carbon emissions',
    component: CarbonDial,
    groups: [],
  },
  {
    id: 'report',
    name: 'Report',
    description: 'Report',
    component: Report,
    size: {xs: 12},
    groups: [],
  },
  {
    id: 'wind-forecast-graph',
    name: 'Wind forecast',
    description: 'North Island wind forecast',
    component: WindForecastGraph,
    groups: [],
  },
  {
    id: 'stacked-generation-graph',
    name: 'Stacked generation',
    description: 'Stacked generation for the last 6 months',
    component: StackedGenerationGraph,
    groups: [],
  },
  {
    id: 'interim-price-graph',
    name: 'Dispatch and Interim price',
    description: 'Dispatch and Inerim price',
    component: InterimPriceGraph,
    groups: [AccessGroups.EM6_PRIC],
  },
  {
    id: 'regional-predicted-load-graph',
    name: 'Regional Predicted load',
    description: 'Predicted load by Region',
    component: RegionalPredictedLoadGraph,
    groups: [AccessGroups.EM6_PRIC],
  },
  {
    id: 'nrss-regional-predicted-load-graph',
    name: 'Regional Predicted load short',
    description: 'Predicted load by Region short',
    component: NRSSRegionalPredictedLoadGraph,
    groups: [AccessGroups.EM6_PRIC],
  },
  {
    id: 'node-predicted-load-graph',
    name: 'Node Predicted load',
    description: 'Predicted load by Node',
    component: NodePredictedLoadGraph,
    groups: [AccessGroups.EM6_PRIC],
  },
  {
    id: 'hvdc-graph',
    name: 'HVDC',
    description: 'HVDC',
    component: HVDCGraph,
    groups: [AccessGroups.EM6_PUBL],
  },
  {
    id: 'reserves-graph',
    name: 'Reserves',
    description: 'Reserves',
    component: ReservesGraph,
    groups: [AccessGroups.EM6_PUBL],
  },
  {
    id: "previous-day-prices-graph",
    name:"Price last 24 hours ($/MWh)",
    description:"",
    component: PreviousDayPricesGraph,
    groups: [],
  },
  {
    id: "regional-peaks-graph",
    name:"Regional Peaks Graph",
    description:"",
    component: RegionalPeaksGraph,
    groups: [AccessGroups.EM6_RCPD],
  },
  {
    id: 'yearly-rcpd-peaks-graph',
    name: 'Yearly Regional Peaks',
    description: 'Bar graph of current 100 RCPD peaks',
    component: YearlyRCPDPeaksGraph,
    groups: [AccessGroups.EM6_RCPD],
  },
  {
    id: 'generation-capacity',
    name: 'Generation Capacity',
    description: 'Generation Capacity',
    component: GenerationCapacityGraph,
    permissionsCheck: (user) => (user.groups || []).some(g => [AccessGroups.EM6_SCAD, AccessGroups.EM6_RT].includes(g)),
  }
].reduce((acc, curr) => ({...acc, [curr.id]: curr}), {})


export {
  Widget,
  Widgets,
  RegionalPriceMap,
  NodeLoadMap,
  NodePriceMap,
  IslandLoadGraph,
  GenerationGraph,
  ResidualsGraph,
  CarbonDial,
  Report,
  WindForecastGraph,
  StackedGenerationGraph,
  InterimPriceGraph,
  RegionalPredictedLoadGraph,
  NodePredictedLoadGraph,
  HVDCGraph,
  PreviousDayPricesGraph,
  ReservesGraph,
  RegionalPeaksGraph,
  YearlyRCPDPeaksGraph,
  GenerationCapacityGraph,
  NRSSRegionalPredictedLoadGraph
}
