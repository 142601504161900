/** @jsxImportSource @emotion/react */
import React, { Component, Children } from 'react'

export type ErrorBannerProps = {
  children?: React.ReactNode;
}
export class ErrorBanner extends Component<ErrorBannerProps> {

  render = () =>
    <div css={!!Children.count(this.props.children) && styles.banner}>
      {
        Children.map(this.props.children, (child, idx) => <p key={idx}>{child}</p>
        )
      }
    </div>
}

const styles = {
  banner: {
    padding: '10px 5px',
    borderLeft: '3px solid red',
    background: 'pink',
    display: 'block',
    margin: '10px 0',
    color: '#7e0202',
    fontSize: '0.85rem',
    width: '100%'
  }
}

export default ErrorBanner