import React, {useContext, useEffect} from "react";
import {useDependency, useResource} from 'hooks'
import {useTokens} from "contexts/TokensContext";
import { SessionStatus } from '../constants'

const NodesContext = React.createContext(undefined)

export const NodesProvider = ({ children }) => {

  const [tokens] = useTokens()
  const [node, nodeActions] = useResource('nodes')
  const [nodesLoading, _, fetchNodes] = useDependency(() => {
    if (tokens.loginState === SessionStatus.AUTHENTICATED){
      return nodeActions.request('details')
    }
  })

  // reload nodes if we've authenticated
  useEffect(() => {
    fetchNodes()
  }, [fetchNodes, tokens.loginState])
  
  const initialState = { nodes: [], loading: false }
  const value = {
    ...initialState,
    loading: nodesLoading,
    nodes: node.details?.data?.items || [],
  }
  return (
    <NodesContext.Provider value={value}>
      {children}
    </NodesContext.Provider>
  )
}

export const useNodes = () => {
  const nodes = useContext(NodesContext)
  if (!nodes) {
    console.warn("NodesContext must be provided in order to use it")
    return {}
  }

  return nodes
}