/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource} from 'hooks'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import { Widgets } from './'
import _ from 'lodash'

const GenerationGraph = (props) => {
  const widget = Widgets['generation-graph']
  const [daily, dailyActions] = useResource('daily')
  const [generationLoading, result, reloadGeneration] = useDependency(() => dailyActions.request('generation'))
  const generationUpdatedAt = _.last(daily.generation?.data?.items || [])?.timestamp

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  return (
    <WidgetContainer
      widget={widget}
      title="Generation Fuel Type (MW)"
      icon={<PolylineOutlinedIcon/>}
      lastUpdatedAt={generationUpdatedAt}
      loading={generationLoading}
      onWidgetRefresh={reloadGeneration}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      zoom={zoom}
      {...props}
    >
      <Graphs.Generation
        data={daily.generation?.data}
        zoomCallback={zoomCallback}
      />
    </WidgetContainer>
  )
}

export default GenerationGraph