import React from 'react'

const Nav = () => {
  return (
    <nav>
    </nav>
  )
}

export default Nav
