/** @jsxImportSource @emotion/react */
import React from 'react'
import { Widgets } from '../widgets'
import { useAuthorization } from 'hooks'
import Alert from '@mui/material/Alert'
import InfoIcon from '@mui/icons-material/Info'

const feedURL = "https://www.ems.co.nz/services/em6/em6-api-csv-and-sftp-data-feeds/"

export const WidgetsMissingInfo = ({ widgets=[], required }) => {

  const authorization = useAuthorization()
  const noWidgets = !widgets.some(id => authorization.isWidgetPermitted(Widgets[id]))

  return (noWidgets ?
    <Alert css={styles.alert} icon={<InfoIcon fontSize="inherit" />} severity="info" variant="filled">
      To view the widgets and information on this page, you need the {' '}
      <a href={feedURL} target="_blank"><strong>{required}</strong> data feed</a>.
      Contact <strong><a href="mailto:call@ems.co.nz">call@ems.co.nz</a></strong>.
    </Alert> : null)
}



const styles = {
  alert: {
    marginTop: 10,
    marginLeft: 10,
  }
}

export default WidgetsMissingInfo