/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export const NODES = [
  {id: 'BEN2201', label: 'BEN2201'},
  {id: 'HAY2201', label: 'HAY2201'},
  {id: 'OTA2201', label: 'OTA2201'},
]

const NodeFilter = ({filter, onChange, nodes=null}) => {
  const {node} = filter

  return (
    <div css={styles.container}>
      <FormControl variant="standard" fullWidth>
          <InputLabel id="node">Node</InputLabel>
          <Select
            labelId="node"
            value={node || ''}
            label="Node"
            onChange={({target: {value: node}}) => onChange({...filter, node})}
          >
            {(nodes || NODES).map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
    </div>
  )

}

const styles = {
  container: {
    display: 'flex',
  }
}

export default NodeFilter