/** @jsxImportSource @emotion/react */
import React from 'react'

const ReferenceLabel = ({fill, value, fontSize, viewBox, dy, dx, xOffset=0, yOffset=0, lineOffset=15}) => {
  const x = viewBox.width + viewBox.x + xOffset
  const y = viewBox.y + yOffset

  const lines = value.split('\n')
  return lines.map((line, idx) =>
    <text
      key={idx}
      x={x}
      y={y + (idx * lineOffset)}
      dy={dy}
      dx={dx}
      fill={fill}
      fontSize={fontSize || 14}
      textAnchor="end">
      {line}
    </text>
  )
}

export default ReferenceLabel