/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import AllOutIcon from '@mui/icons-material/AllOut';
import {WidgetContainer, Maps} from 'components'
import {useDependency, useResource, useFilter} from 'hooks'
import {useNodes} from 'contexts/NodesContext'
import {useSavedFilters} from 'contexts/SavedFiltersContext'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import { Widgets } from './'
import _ from 'lodash'

const DEFAULT_FILTER = {
  nodes: [
    'MPE1101',
    'PEN0331',
    'HAM0331',
    'EDG0331',
    'RDF0331',
    'TUI1101',
    'SFD0331',
    'BPE0331',
    'CPK0331',
    'STK0331',
    'ISL0661',
    'GYM0661',
    'OAM0331',
    'INV0331',
    'HWB0331'
  ]
}

const NodeLoadMap = (props) => {
  const widget = Widgets['node-load-map']

  const [filter, setFilter] = useFilter(null, 'node-load')
  const { nodes: allNodes } = useNodes()
  const savedFilters = useSavedFilters()

  const selectedNodes = _.compact((filter.nodes || []).map(nodeId => allNodes.find(n => n.nodeId === nodeId)))

  const [load, loadActions] = useResource('load')
  const [loading, result, reloadLoad] = useDependency(() => loadActions.request('recent', filter), [filter])

  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({...initialFilter, ...filter})
  }, [])

  const mapUpdatedAt = _.last(load.recent?.data?.items || [])?.timestamp

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  return (
    <WidgetContainer
      widget={widget}
      title="Load (MW)"
      icon={<AllOutIcon/>}
      lastUpdatedAt={mapUpdatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={loading}
      onWidgetRefresh={reloadLoad}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      renderFilter={() => <Maps.NodeFilter filter={filter} onChange={setFilter} nodeTypes={['loadNode']} />}
      onFilterSave={() => savedFilters.update(widget, filter)}
      zoom={zoom}
      {...props}
    >
      <Maps.NodeLoad
        data={load.recent?.data}
        nodes={selectedNodes}
        zoomCallback={zoomCallback}
      />
    </WidgetContainer>
);
}

const styles = {}


export default props => (
  <NodeLoadMap {...props}/>
)