import * as Containers from 'containers'

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Router } from 'react-router-dom';
import { history } from 'utils';
import { theme } from 'styles/theme'

export const AppContainer = () =>
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <Router history={history}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Containers.App />
        </ThemeProvider>
      </StyledEngineProvider>
    </Router>
  </LocalizationProvider>

export default AppContainer