/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import CommitIcon from '@mui/icons-material/Commit';
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource, useFilter} from 'hooks'
import { Widgets } from './'
import moment from 'moment'
import {useSavedFilters} from 'contexts/SavedFiltersContext'

const DEFAULT_FILTER = {
  node: 'BEN2201'
}

const DEFAULT_REFRESH_RATE = 1000 * 60 

const InterimPriceGraph = (props) => {
  const widget = Widgets['interim-price-graph']
  const [filter, setFilter] = useFilter(null, 'interimprice')
  const savedFilters = useSavedFilters()

  const [price, priceActions] = useResource('price')
  const [currentTime, setCurrentTime] = useState(moment())

  const updateWidget = () => {
    setCurrentTime(moment().startOf('minute'))
    return filter.node && Promise.all([
      priceActions.request('dispatch', {node_id: filter.node}),
      priceActions.request('market', {nodes: [filter.node], run_type: 'NR', trading_date: moment()}, {as: 'marketNR'}),
      priceActions.request('market', {nodes: [filter.node], run_type: 'T', trading_date: moment()}, {as: 'marketT'}),
    ])
  }

  const [loading, result, reload] = useDependency(updateWidget, [filter])
  const updatedAt = price.dispatch?.responseAt

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)


  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({...initialFilter, ...filter})
  }, [])

  return (
    <WidgetContainer
      widget={widget}
      title="Dispatch And Interim Price ($/MWh)"
      icon={<CommitIcon/>}
      lastUpdatedAt={updatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={loading}
      onWidgetRefresh={reload}
      widgetAutoRefreshMs={DEFAULT_REFRESH_RATE}
      renderFilter={() => <Graphs.NodeFilter filter={filter} onChange={setFilter} />}
      onFilterSave={() => savedFilters.update(widget, filter)}
      zoom={zoom}
      {...props}
    >
      <Graphs.InterimPrice
        dispatchData={price.dispatch?.data}
        marketNRData={price.marketNR?.data}
        marketTData={price.marketT?.data}
        zoomCallback={zoomCallback}
        currentTime={currentTime}
      />
    </WidgetContainer>
  )
}

export default InterimPriceGraph