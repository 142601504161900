/** @jsxImportSource @emotion/react */
import React, {useMemo} from 'react'
import moment from 'moment'
import { timeForInterval } from 'utils'
import {
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import { useSeries } from 'hooks'

const SERIES = {
  'generation': {
    label: 'Historic Generation',
    color: '#6388d7',
  },
}

const SERIES_OPTIONS = {
  xTicks: {every: 'day', add: 1, skipFirst: false},
  tooltipTreshold: 0.1
}

const GenerationHistory = ({historicGeneration, width='100%', height='100%'}) => {

  const data = useMemo(() => historicGeneration.reduce((acc, {tradingDate, ...rest}) => ([
    ...acc,
    // the data is returned as an item per trading date and one attribute per 48 trading intervals eg tp1Mw, tp2Mw, ..., tp48Mw
    ...Object.keys(rest).filter(key => key.includes('tp') && rest[key] !== null).map(key => {
        // we calculate the timestamp by combining trading date & trading period
        const tp = parseInt(key.replace(/[^0-9]/g, ''))
        const timestamp = timeForInterval(tp, moment(tradingDate, 'DD/MM/YYYY')).valueOf()
        return {timestamp, generation: rest[key], tradingPeriod: tp}
      })
    ].sort((a, b) => a.timestamp - b.timestamp)), []), [JSON.stringify(historicGeneration)])

  const [graph, actions] = useSeries(SERIES, data, SERIES_OPTIONS)

  return (
    <>
      <ResponsiveContainer width={width} height={height} aspect="2">
        <LineChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('DD MMM')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            width={40}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />

          {graph.visibleSeries.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={SERIES[g].color}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={actions.isSeriesActive(g)}
            />
          )}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3"/>
        </LineChart>
      </ResponsiveContainer>
    </>
  )

}

export default GenerationHistory