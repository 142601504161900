/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource} from 'hooks'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import { Widgets } from './'
import _ from 'lodash'

const HVDCGraph = (props) => {
  const widget = Widgets['hvdc-graph']
  const [daily, dailyActions] = useResource('daily')
  const [hvdcLoading, result, reloadHVDC] = useDependency(() => dailyActions.request('hvdc'))
  const hvdcUpdatedAt = _.last(daily.hvdc?.data?.items || [])?.timestamp

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  return (
    <WidgetContainer
      widget={widget}
      title="HVDC (MW)"
      icon={<PolylineOutlinedIcon/>}
      lastUpdatedAt={hvdcUpdatedAt}
      loading={hvdcLoading}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      onWidgetRefresh={reloadHVDC}
      zoom={zoom}
      {...props}
    >
      <Graphs.HVDC
        data={daily.hvdc?.data}
        zoomCallback={zoomCallback}
      />
    </WidgetContainer>
  )
}

export default HVDCGraph