/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react'
import { Autocomplete } from './'
import {useNodes} from 'contexts/NodesContext'
import _ from 'lodash'

const filterNodesByType = (nodes, nodeTypes) => nodes?.filter(node => nodeTypes.some(type => node[type] === 'TRUE'))

const nodeEquals = (node1, node2) => node1.id === node2.id

export const NodePicker = ({ label='Nodes', value, onChange, fullWidth, nodeTypes=[], multiple, attributes=['nodeName'] }) => {

  // all 339 nodes
  // NodesContext must be provided
  const { nodes, loading } = useNodes()

  const filteredNodes = useMemo(
    () => {
      // filter by pricingNode, generationNode, ...
      const filtered = nodeTypes && nodeTypes.length ? filterNodesByType(nodes, nodeTypes) : nodes
      // only keep attributes we need
      return filtered?.map(node => ({id: node.nodeId, ..._.pick(node, attributes)}))
    },
    [nodes, nodeTypes, attributes]
  )

  const getOptionLabel = ({id, nodeName}) => nodeName ? `${id} - ${nodeName}` : id
  const currentValue = value || (multiple ? [] : '')

  return (
    <Autocomplete
      loading={loading}
      fullWidth={fullWidth}
      label={label}
      getOptionLabel={getOptionLabel}
      options={filteredNodes}
      value={currentValue}
      onChange={onChange}
      multiple
      isOptionEqualToValue={nodeEquals}
    />
  )

}

export default NodePicker