import { ProtectedJsonAPI } from './ProtectedJsonAPI'
import {REGIONS, RUN_TYPES} from '../components/graphs/ResidualsFilter'
import { filterQueryString } from 'utils'

export * from './Reports'

export const Nodes = ProtectedJsonAPI.extend(
  '',
  {
    details: endpoint => endpoint.path(filter => {
      return `/nodes${filterQueryString(filter)}`
    }),
  }
)

export const Daily = ProtectedJsonAPI.extend(
  '',
  {
    generation: endpoint => endpoint.path(() => {
      return '/generation_type/24hrs/'
    }),
    reserves: endpoint => endpoint.path(() => {
      return '/reserves_nz/24hrs/'
    }),
    hvdc: endpoint => endpoint.path(() => {
      return '/hvdc/24hrs/'
    }),
    load: endpoint => endpoint.path(() => {
      return '/nz/24hrs/'
    }),
    price: endpoint => endpoint.path(filter => {
      return `/price/24hrs${filterQueryString(filter)}`
    }),
  }
)
export const Carbon = ProtectedJsonAPI.extend(
  '',
  {
    intensity: endpoint => endpoint.path(() => {
      return '/current_carbon_intensity'
    }),
  }
)
export const Demand = ProtectedJsonAPI.extend(
  '',
  {
    residuals: endpoint => endpoint.path(({regions, runTypes}) => {
      if (regions?.length === REGIONS.length){ regions = [] }
      if (runTypes?.length === RUN_TYPES.length){ runTypes = [] }
      return `/residuals${filterQueryString({region: regions, run_type: runTypes})}`
    }),
  }
)

export const Wind = ProtectedJsonAPI.extend(
  '',
  {
    generation: endpoint => endpoint.path(() => {
      return '/ni_wind_generation'
    }),
    forecast: endpoint => endpoint.path(() => {
      return '/windforecast'
    }),

  }
)

export const Price = ProtectedJsonAPI.extend(
  '',
  {
    free: endpoint => endpoint.path(filter => {
      return `/free/price${filterQueryString(filter)}`
    }),
    dispatch: endpoint => endpoint.path(filter => {
      return `/dispatch/price${filterQueryString(filter)}`
    }),
    market: endpoint => endpoint.path(filter => {
      return `/market_price${filterQueryString(filter)}`
    }),
    recent: endpoint => endpoint.path(filter => {
      return `/price${filterQueryString(filter)}`
    }),
    region: endpoint => endpoint.path(() => {
      return '/region/price/'
    }),
  }
)

export const Load = ProtectedJsonAPI.extend(
  '',
  {
    actual: endpoint => endpoint.path(filter => {
      return `/actualload/regions${filterQueryString(filter)}`
    }),
    forecast: endpoint => endpoint.path(filter => {
      return `/loadforecast/regions${filterQueryString(filter)}`
    }),
    recent: endpoint => endpoint.path(filter => {
      return `/recent_load${filterQueryString(filter)}`
    }),
    prediction: endpoint => endpoint.path(filter => {
      return `/loadforecast/predictions${filterQueryString(filter)}`
    }),
    scada: endpoint => endpoint.path(filter => {
      return `/scada_load${filterQueryString(filter)}`
    }),
  }
)
export const Generation = ProtectedJsonAPI.extend(
  '',
  {
    current: endpoint => endpoint.path(filter => {
      return `/free/current_generation${filterQueryString(filter)}`
    }),
    scada: endpoint => endpoint.path(filter => {
      return `/scada_generation${filterQueryString(filter)}`
    }),
  }
)
export const RegionalPeak = ProtectedJsonAPI.extend(
  "",
  {
    load48hrs: endpoint => endpoint.path(filter => {
      return `/rcpd_load/${filterQueryString(filter)}`
    }),
    load2hrs: endpoint => endpoint.path(filter => {
      return `/rcpd_1min/${filterQueryString(filter)}`
    }),
    rcpdPeaks: endpoint => endpoint.path(filter => {
      return `/rcpd_peaks/${filterQueryString(filter)}`
    }),
  }
)
