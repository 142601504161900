/** @jsxImportSource @emotion/react */
import React, { Component }                from 'react'
import Select                              from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import { uuid }                            from 'utils'
import MenuItem from '@mui/material/MenuItem';
import {SelectProps} from "@mui/material/Select/Select";

export type LabeledSelectProps = SelectProps & {
  options: Record<string, string>;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  className?: string;
  label?: string;
  error?: boolean;
  helperText?: string;
  value?: string;
  children?: React.ReactNode;
};
export class LabeledSelect extends Component<LabeledSelectProps> {
  htmlElmId: string

  constructor(props){
    super(props)
    this.htmlElmId = uuid()
  }

  get children(){
    if(this.props.options){
      return Object.entries(this.props.options).map(([value, label], index) =>
        <MenuItem key={index} value={value}>{label}</MenuItem>
      )
    }else{
      return this.props.children
    }
  }

  render = () => {
    const {fullWidth, style, className, label, error, children, options, helperText, ...props } = this.props
    return (
      <FormControl variant="standard" fullWidth={!!fullWidth} error={!!error} style={style} className={className}>
        <InputLabel htmlFor={this.htmlElmId}>{label}</InputLabel>
        <Select
          inputProps={{id: this.htmlElmId}}
          {...props}
          value={props.value || ''}
        >
          {this.children}
        </Select>
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

}

export default LabeledSelect