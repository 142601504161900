/** @jsxImportSource @emotion/react */
import React  from 'react'
import Grid from '@mui/material/Grid'

const LINKS = [
  {
    href: 'https://www.ems.co.nz/em6-carbon-methodology/',
    text: 'Carbon methodology',
  },
  {
    href: 'https://www.ems.co.nz/em6-privacy-policy/',
    text: 'Privacy Policy',
  },
  {
    href: 'https://www.ems.co.nz/em6-disclaimer/',
    text: 'Disclaimer',
  },
  {
    href: 'https://www.ems.co.nz/em6-api-integration-guide/',
    text: 'APIs',
  },

]

const Footer = () => {

  const renderLinks = () =>
    <>
      {LINKS.map(({text, href}, idx) =>
        <a key={idx} rel="noopener noreferrer" target="_blank" href={href}>{text}</a>
      )}
    </>

  const renderContact = () =>
    <>
      To get more data or to open an account, contact us at {' '}
      <strong>
        <a href="mailto:call@ems.co.nz">call@ems.co.nz</a>
      </strong>.
    </>

  return (
    <Grid container spacing={1} css={styles.container}>

      {/* xl screens only, blurb & links on one line */}
      <Grid item xs={12} sx={{display: {xs: 'none', xl: 'flex'}}}>
        <img src="/logo_small.svg" alt="EM6" css={styles.logo}/>
        <div style={{width: '100%'}}>
          {renderContact()}
        </div>
        <div css={styles.links}>
          {renderLinks()}
        </div>
      </Grid>

      {/* smaller than xl, links on one line, blurb below */}
      <Grid item xs={12} sx={{display: {xl: 'none', xs: 'flex'}}}>
        <img src="/logo_small.svg" alt="EM6" css={styles.logo}/>
        <div css={styles.links}>
          {renderLinks()}
        </div>
      </Grid>
      <Grid item xs={12} sx={{display: {xl: 'none', xs: 'block'}}}>
        {renderContact()}
      </Grid>
    </Grid>
  )
}

const styles = {
  logo: {
    height: 25,
    width: 100,
  },
  container: theme => ({
    paddingLeft: 100,
    marginTop: 5,
    paddingTop: 10,
    paddingBottom: 20,
    paddingRight: 10,
    backgroundColor: '#0c3345',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 20
    },
    fontSize: 14,
  }),
  links: theme => ({
    display: 'inline-block',
    '& a': {
      paddingRight: 10,
      textDecoration: 'underline'
    },
    [theme.breakpoints.only('xl')]: {
      whiteSpace: 'nowrap',
    },
  })
}

export default Footer
