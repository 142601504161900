/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Grid from '@mui/material/Grid'
import { RCPD_YEARS, RCPD_REGIONS } from 'constants'

const RCPD_TYPES = [
  {id: 'load', label: 'Load'},
  {id: 'peaks', label: 'Peaks'},
]

const RCPDFilter = ({filter, onChange}) => {

  const handleChange = att => event => {
    onChange({...filter, [att]: event.target.value})
  }

  const { rcpdType, year, region } = filter

  return (
    <>
      <Grid item xs={12} md={6}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="rcpdType">Type</InputLabel>
          <Select
            labelId="rcpdType"
            value={rcpdType || ''}
            label="Type"
            onChange={ handleChange('rcpdType') }
          >
            {RCPD_TYPES.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="region">Region</InputLabel>
          <Select
            labelId="region"
            value={region || ''}
            label="Region"
            onChange={ handleChange('region') }
          >
            {RCPD_REGIONS.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      {rcpdType === 'peaks' &&
      <Grid item xs={12} md={6}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="year">Year</InputLabel>
          <Select
            labelId="year"
            value={year || ''}
            label="Year"
            onChange={ handleChange('year') }
          >
            {RCPD_YEARS.map((id) => <MenuItem key={id} value={id}>{id}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>}
    </>
  )

}

const styles = {
}

export default RCPDFilter