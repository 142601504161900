/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined'
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource} from 'hooks'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import { Widgets } from './'
import _ from 'lodash'

const GenerationGraph = (props) => {
  const widget = Widgets['carbon-dial']
  const [carbon, carbonActions] = useResource('carbon')
  const [carbonLoading, result, reloadCarbon] = useDependency(() => carbonActions.request('intensity'))
  const carbonUpdatedAt = _.first(carbon.intensity?.data?.items || [])?.timestamp
  const tooltips = useState(false)
  return (
    <WidgetContainer
      widget={widget}
      title="Carbon Emissions (g/kWh)"
      icon={<Co2OutlinedIcon/>}
      lastUpdatedAt={carbonUpdatedAt}
      loading={carbonLoading}
      onWidgetRefresh={reloadCarbon}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      tooltips={tooltips}
      {...props}
      toggleFullScreen={null}
    >
      <Graphs.CarbonDial data={carbon.intensity?.data} tooltips={tooltips} />
    </WidgetContainer>
  )
}

export default GenerationGraph