/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource} from 'hooks'
import { Widgets } from './'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import _ from 'lodash'

const NODES = [
  'OTA2201',
  'WKM2201',
  'HAY2201',
  'KIK2201',
  'ISL2201',
  'BEN2201',
]

const PreviousDayPricesGraph = (props) => {

  const widget = Widgets['previous-day-prices-graph']
  const [daily, dailyActions] = useResource('daily')
  const [pricesLoading, result, reloadPrices] = useDependency(() => dailyActions.request('price', {nodes: NODES}))
  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  const pricesUpdatedAt = _.last(daily.price?.data?.items || [])?.timestamp

  return(
    <WidgetContainer
      widget={widget}
      title="Price Last 24 Hours ($/MWh)"
      icon={<AttachMoneyIcon/>}
      lastUpdatedAt={pricesUpdatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={pricesLoading}
      onWidgetRefresh={reloadPrices}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      zoom={zoom}
      {...props}
    >
      <Graphs.PreviousDayPrices
        data={daily?.price?.data}
        zoomCallback={zoomCallback}
      />
    </WidgetContainer>
  )
}
export default PreviousDayPricesGraph