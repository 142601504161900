/** @jsxImportSource @emotion/react */
import React from 'react'
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip, Label, Customized } from 'recharts'
import { TooltipList, TooltipListItem, TooltipHeader } from './Tooltip'
import { round } from 'utils'
import CarbonMarketSummary from './CarbonMarketSummary'
import { InfoTip } from 'components/Info'
import _ from 'lodash'
import Typography from '@mui/material/Typography'
const CARBON_TOTAL = 210
const CARBON_RANGES = [
  {
    color: '#cbe612',
    value: CARBON_TOTAL / 3,
    label: 'Low',
    actual: 'Less than 70'
  },
  {
    color: '#f6c580',
    value: CARBON_TOTAL / 3,
    label: 'Medium',
    actual: '70 to 140'
  },
  {
    color: '#e780ac',
    value: CARBON_TOTAL / 3,
    label: 'High',
    actual: 'More than 140'
  },
]
const PIE_CONFIG = {
  startAngle: 180,
  endAngle: 0,
  cx: '50%',
  cy: '50%',
}

const renderTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { color, label, actual } = payload[0].payload

    return (
      <TooltipList>
        <TooltipHeader label="Carbon Emissions" />
        <TooltipListItem
          label={label}
          color={color}
          value={actual && `${actual} g/kWh`}
        />
      </TooltipList>
    )
  }
}

// markers are rendered as another pie graph with a thin colored slice where the value should be marked
// the slices before and after the value slice are left transparent
const PieMarker = ({ value, total = CARBON_TOTAL, width = 5, innerRadius = "50%", outerRadius = "100%", color = 'red', label = false }) => {

  const markerData = [
    {
      value: value - 0.5 * width,
      color: 'none',
    },
    {
      value: width,
      color,
      label,
      actual: round(value, 0), // value on tooltip
    },
    {
      value: total - value - 0.5 * width,
      color: 'none',
    }
  ]

  return (
    <Pie
      dataKey="value"
      data={markerData}
      stroke="none"
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      {...PIE_CONFIG}
    >
      {markerData.map(({ color }, idx) => (
        <Cell key={idx} fill={color} />
      ))}
    </Pie>
  )
}

const CustomizedLabel = ({ viewBox, nzCarbonChangeGkwh, nzCarbonGkwh }) => {
  const { cx, cy, innerRadius } = viewBox;
  const rotateAmount = nzCarbonChangeGkwh > 0 ? 0 : 180
  return (
    <svg x={cx - (innerRadius * .95)} y={cy - (innerRadius / 2)} viewBox='0 0 300 300'>
        <text css={styles.labelText} x={0} y={14}>
          Current C02
        </text>
        <text css={styles.labelValue} x={0} y={32} dx=".7em"> {round(nzCarbonGkwh, 0)} g/kWh</text>
        <svg>
          <path
           css={{
              transform: `rotate(${rotateAmount}deg)`,
              transformBox: 'fill-box',
              transformOrigin: 'center',
            }}
            d={`M 0 32 h 10 l -5 -10`} fill="white" stroke="white" />
        </svg>
    </svg>

  );
};

const CarbonDial = ({ data, width = '100%', height = '100%', tooltips }) => {
  const items = data?.items || []
  if (!items.length) {
    return null
  }

  const { nzCarbonGkwh, min24hrsGkwh, max24hrsGkwh, nzCarbonChangeGkwh, currentYearAvgGkwh, currentMonthAvgGkwh, nzRenewable } = items[0]
  const ranges = [...CARBON_RANGES]

  // if max24hrsGkwh is beyond the range of the last carbon dial zone, extend the last zone
  const rangeTotal = Math.max(CARBON_TOTAL, max24hrsGkwh + 5)
  if (rangeTotal > CARBON_TOTAL) {
    _.last(ranges).value = rangeTotal - (2 / 3 * CARBON_TOTAL)
  }

  return (
    <div>
      <ResponsiveContainer width={width} aspect={1.25} height={height}>
        <PieChart    
        css={styles.pie}
        >
          <Pie
            dataKey="value"
            data={ranges}
            stroke="none"
            innerRadius="60%"
            outerRadius="90%"
            style={{
              outline: 'none'
            }
            }
            {...PIE_CONFIG}
          >
            {CARBON_RANGES.map(({ color }, idx) => (
              <Cell
              key={idx} fill={color} />
            ))}
            <Label
            content={<CustomizedLabel
              nzCarbonChangeGkwh={nzCarbonChangeGkwh}
              nzCarbonGkwh={nzCarbonGkwh}
              position="center"
              tooltips={tooltips}
            ></CustomizedLabel>}
            ></Label>
          </Pie>
          {PieMarker({ value: nzCarbonGkwh, total: rangeTotal, color: '#FFF', width: 5, label: 'Current' })}
          {PieMarker({ value: min24hrsGkwh, total: rangeTotal, color: '#D0D3D4', width: 2, label: 'Min (24hrs)', innerRadius: '55%', outerRadius: '95%' })}
          {PieMarker({ value: max24hrsGkwh, total: rangeTotal, color: '#D0D3D4', width: 2, label: 'Max (24hrs)', innerRadius: '55%', outerRadius: '95%' })}
          <Tooltip content={renderTooltip} />
          <Customized nzRenewable={nzRenewable} nzCarbonChangeGkwh={nzCarbonChangeGkwh} nzCarbonGkwh={nzCarbonGkwh} currentYearAvgGkwh={currentYearAvgGkwh} currentMonthAvgGkwh={currentMonthAvgGkwh} component={CarbonMarketSummary}></Customized>
        </PieChart>
      </ResponsiveContainer>
      <InfoTip tooltips={tooltips} css={{marginTop:"-12%"}}>
        <Typography css={styles.infoTipText}>
        Current CO2 is calculated for each trading period. A carbon intensity factor (NZ) for each fuel type (C) is multiplied by the generation of that fuel type (G) and divided by the NZ national grid demand (D).
        <br/>
        NZ = (CxG)/D
        </Typography>
        <br/>
        <Typography css={styles.infoTipText}>
        Renewable Energy percentage is calculated by adding Hydro, Geothermal, Wind, Battery and Solar generation and dividing by the total grid connected generation.
        </Typography>
        <br/>
        <Typography css={styles.infoTipText}>
          For more information go to <a css={{textDecoration: 'underline'}} target="_blank" href='https://www.ems.co.nz/em6-carbon-methodology/'>https://www.ems.co.nz/em6-carbon-methodology/</a>
        </Typography>
      </InfoTip>
    </div>
  )
}

const styles = {
  labelText: {
    fontWeight: 250,
    fill:"white"
  },
  labelValue:{
    fontWeight: 900,
    fill:"white"
  },
  infoTipText:{
    fontSize: "0.875rem"
  },
  pie:{
    "& .recharts-layer.recharts-pie":{
      outline: 'none'
    }
  }

}

export default CarbonDial