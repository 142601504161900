import { camelized } from './StringUtils'

function deepCamelize(o) {
  var newO, origKey, newKey, value
  if (o instanceof Array) {
    return o.map(function(value) {
        if (typeof value === "object") {
          value = deepCamelize(value)
        }
        return value
    })
  } else {
    newO = {}
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = camelized(origKey, {lower: true})
        value = o[origKey]
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = deepCamelize(value)
        }
        newO[newKey] = value
      }
    }
  }
  return newO
}

export function CamelizeResponseDecorator(endpoint) {
  endpoint.resultsHandler(response => deepCamelize(response))
  return endpoint
}
