/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import { WidgetContainer, Graphs } from 'components'
import { useDependency, useResource, useFilter } from 'hooks'
import { useNodes } from 'contexts/NodesContext'
import { Widgets } from './'
import _ from 'lodash'
import { useSavedFilters } from 'contexts/SavedFiltersContext'
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import moment from 'moment'

const RESTRICTED_GENERATION_NODES = [
  'BPE0331 TWF0',
  'HWB0331 MAH0',
  "KUM0661 KUM0",
  "LTN0331 TWF0",
  "NSY0331 PAE0",
  "TGA0331 KMI0",
  "TWC2201 TWF0"
]
const DEFAULT_FILTER = {
  generatorSize: ["extraLarge", "large"],
  generatorType: [
    "Hydro",
    "Co-Gen",
    "Wind",
    "Battery",
    "Coal",
    "Gas",
    "Geothermal",
    "Solar",
    "Liquid"
  ]
}

const GenerationCapacityGraph = (props) => {
  const widget = Widgets['generation-capacity']
  const [generation, generationActions] = useResource('generation')
  const [filter, setFilter] = useFilter(null, 'generationCapacity')
  const { nodes: allNodes } = useNodes()
  const generationNodes = allNodes.filter(node => node.generationNode === 'TRUE' && !RESTRICTED_GENERATION_NODES.includes(node.nodeId))

  const [loadingGeneration, result, reloadGeneration] = useDependency(() => Promise.all([
    generationActions.request('current'),
    generationActions.request('scada', {from_trading_date: moment().add(-7, 'day'), to_trading_date: moment()}),
  ]))
  const savedFilters = useSavedFilters()
  const currentGeneration = generation.current?.data?.items || []
  const historicGeneration = generation.scada?.data?.items || []
  const generationUpdatedAt = _.last(currentGeneration)?.timestamp

  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({ ...initialFilter, ...filter })
  }, [])

  return (
    <WidgetContainer
      widget={widget}
      title="Generation Capacity (MW)"
      icon={<Battery5BarIcon/>}
      renderFilter={() => <Graphs.GenerationCapacityFilter filter={filter} onChange={setFilter} />}
      onFilterSave={() => savedFilters.update(widget, filter)}
      lastUpdatedAt={generationUpdatedAt}
      lastUpdatedAtAddMinutes={0}
      onWidgetRefresh={reloadGeneration}
      loading={loadingGeneration}
      {...props}
    >
      <Graphs.GenerationCapacity
        filter={filter}
        generationNodes={generationNodes}
        currentGeneration={currentGeneration}
        historicGeneration={historicGeneration}
      />
    </WidgetContainer>
  )
}

export default GenerationCapacityGraph