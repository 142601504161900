/** @jsxImportSource @emotion/react */
import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import {useTokens} from 'contexts/TokensContext'
import {SessionStatus} from '../constants'

export type PageContainerProps = {
  children?: React.ReactNode;
}
export const PageContainer = ({ children, ...other }) => {
  const [tokens, tokenActions] = useTokens()
  const authenticated = tokens.loginState === SessionStatus.AUTHENTICATED

  return (
    <Card css={[styles.card, authenticated && styles.withNav, styles.mobile]} {...other}>
      <CardContent css={styles.cardContent}>{children}</CardContent>
    </Card>
  )
}

const styles = {
  withNav: {
    // make room for left navbar
    paddingLeft: 77,
  },
  mobile: theme => ({
    // always hide on mobile
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 0,
      paddingTop: 0,
      paddingRight: 0,
    },
  }),
  card: {
    paddingLeft: 12,
    paddingTop: 12,
    paddingRight: 12,
    backgroundColor: '#002030',
  },
  cardContent: {
    maxWidth: 'none',
  }
}

export default PageContainer