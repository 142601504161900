import { capitalize, uuid } from "utils";
import * as API from "api"
import { useMemo, useContext, useRef } from "react";

export type Meta = {
  count?: number;
  page?: number;
  totalPages?: number;
  warnings?: Warning[];
}

export type Warning = {
  message: string;
}

const useRequest = ({ raiseOnOutOfOrder }: { raiseOnOutOfOrder?: boolean } = { raiseOnOutOfOrder: false }) => {
  const tracker = useRef(new RequestTracker(raiseOnOutOfOrder)).current;

  return useMemo(() => performRequest(tracker), [tracker])
}

const performRequest = (tracker: RequestTracker) => async (
  setState: any,
  resourceName: string,
  actionName: string,
  ...args
) => {
  const requestProxy = { id: uuid(), seq: tracker.next(resourceName, actionName) }

  setState(prevState => {
    return ({
      ...prevState,
      requests: { ...prevState.requests, [actionName]: [...(prevState.requests[actionName] || []), requestProxy] },
      count: prevState.count + 1
    })
  })
  let error: any = null
  try {
    const data = await apiRequest()(resourceName, actionName, ...args)
    const inOrder = tracker.complete(resourceName, actionName, requestProxy.seq)
    return { data, inOrder }
  } catch (err) {
    error = err
    console.error(err)
    tracker.complete(resourceName, actionName, requestProxy.seq)
    throw err
  } finally {
    setState(prevState => {
      let newState = {
        ...prevState,
        errors: { ...prevState.errors, [actionName]: error }
      }
      if (prevState.requests[actionName]) {
        newState.requests[actionName] = prevState.requests[actionName].filter(request => request !== requestProxy)
      }

      return newState
    })
  }
}

const apiRequest = () => async (resourceName: string, actionName: string, ...args: any[]) => {
  resourceName = capitalize(resourceName)
  const resource = API[resourceName];
  if (!resource) {
    throw new Error(`Required API Resource ${resourceName} not found.`)
  }

  if (!resource[actionName]) {
    throw new Error(`Endpoint "${actionName}" not defined on API.${resourceName})}`)
  }

  return await resource[actionName](...args)
}

class RequestTracker {
  raiseOnOutOfOrder: boolean
  constructor(raiseOnOutOfOrder: boolean | undefined) {
    this.raiseOnOutOfOrder = !!raiseOnOutOfOrder
  }
  next(resourceName, actionName) {
    this[resourceName] ||= {}
    this[resourceName][actionName] ||= { seq: 0, latestProcessedSeq: 0 }
    return this[resourceName][actionName].seq += 1
  }
  complete(resourceName, actionName, seq) {
    const inOrder = this[resourceName][actionName].latestProcessedSeq < seq
    if (this.raiseOnOutOfOrder && !inOrder) {
      throw new Error(`Resource: ${resourceName}, Action: ${actionName} received a response out of order`);
    }
    if (inOrder) {
      this[resourceName][actionName].latestProcessedSeq = seq
    }
    return inOrder
  }
}

export default useRequest