/** @jsxImportSource @emotion/react */
import React, {useMemo} from 'react'
import moment from 'moment'
import { trailingZero } from 'utils'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import _ from 'lodash'
import { TooltipList, TooltipListItem, TooltipHeader, formatTimestamp } from './Tooltip'
import Legend from './Legend'
import { useSeries } from 'hooks'

const SERIES = {
  'OTA2201': {
    label: 'OTA2201',
    color: "#CF0025"
  },
  'WKM2201': {
    label: 'WKM2201',
    color: "#ED8B00"
  },
  'HAY2201': {
    label: 'HAY2201',
    color: "#FFD52D"
  },
  'KIK2201': {
    label: 'KIK2201',
    color: "#00A9A5"
  },
  'ISL2201': {
    label: 'ISL2201',
    color: "#B1E9E6"
  },
  'BEN2201': {
    label: 'BEN2201',
    color: "#8552AF"
  },
}


const CustomTooltip = ({ active, series, payload }: any) => {
  if (active && payload?.length && series?.length) {
    const { timestamp, tradingPeriod, ...data } = payload[0].payload ?? {};
    return (
      <TooltipList>
        <TooltipHeader label={formatTimestamp(timestamp, {tradingPeriod})} />
        {series.map((g, idx) => {
          const { label, color } = SERIES[g]
          return (
            <TooltipListItem
              key={idx}
              label={label}
              color={color}
              value={`$${trailingZero(data[g])}`}
            />
          )
        })
        }
      </TooltipList>
    )
  }
}

const PreviousDayPrices = ({ data, width = '100%', height = '100%', zoomCallback }) => {

  const dataByTimestamp = _.groupBy(data?.items, 'timestamp')

  const pricesData = useMemo(() => Object.keys(dataByTimestamp).map(
    timestamp => ({
      timestamp: moment(timestamp).valueOf(),
      ...(dataByTimestamp[timestamp].reduce((acc: any, curr) => ({
        ...acc,
        tradingPeriod: curr.tradingPeriod,
        [`${curr.nodeId}`]: curr.price
      }), {}))
    })
  ), [JSON.stringify(data)])

  const [graph, actions] = useSeries(SERIES, pricesData, {zoomCallback})

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('HH:mm')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />

          <Tooltip
            content={args => CustomTooltip({...args, series: graph.activeSeries})}
          />

          {graph.visibleSeries.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={SERIES[g].color}
              strokeDasharray={SERIES[g].strokeDasharray}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={graph.activeSeries.includes(g)}
            />
          )}

          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3"/>
        </LineChart>
      </ResponsiveContainer>
      <Legend
        series={SERIES}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries} />
    </>
  )
}


export default PreviousDayPrices