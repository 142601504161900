/** @jsxImportSource @emotion/react */
import React from 'react'
import moment from 'moment'
import { round } from 'utils'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { TooltipList, TooltipListItem, TooltipHeader, formatTimestamp } from './Tooltip'
import Legend from './Legend'
import { useSeries } from 'hooks'
import _ from 'lodash'
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import Typography from '@mui/material/Typography';


const SERIES = {
  'dcnFlow': {
    label: 'North Flow',
    color: '#B1E9E6',
  },
  'dcsFlow': {
    label: 'South Flow',
    color: '#CF0059',
  },
}

const styles = {
  directionHeader: {
    display: 'flex',
    justifyContent: "flex-end"
  },
  directionIcon: {
    fontSize: "34px",
  },
}

const DirectionSummary = ({ data }) => {
  const { direction, dcsFlow, dcnFlow } = data
  const flowDirection = direction === "NORTH"
  const currentDcFlow = direction === "NORTH" ? dcnFlow : dcsFlow
  return (
    <div css={styles.directionHeader}>
      {flowDirection && <NorthIcon css={{ color: SERIES['dcnFlow'].color, ...styles.directionIcon }} />}
      {!flowDirection && <SouthIcon css={{ color: SERIES['dcsFlow'].color, ...styles.directionIcon }} />}
      <Typography variant='h5'>{round(currentDcFlow, 0)} MW</Typography>
    </div>
  )
}

const CustomTooltip = ({ active, series, payload }: any) => {
  if (active && payload?.length && series?.length) {
    const { timestamp, tradingPeriod, ...data } = payload[0].payload ?? {};
    return (
      <TooltipList>
        <TooltipHeader label={formatTimestamp(timestamp, {tradingPeriod})} />
        {series.map((g, idx) => {
          const { label, color } = SERIES[g]
          const { northLimitMw, southLimitMw } = data
          const flowLimit = g === "dcnFlow" ? northLimitMw : southLimitMw
          const flowDirectonLabel = g === "dcnFlow" ? "North" : "South"
          return (
            <TooltipListItem
              key={idx}
              label={label}
              color={color}
              value={`${round(data[g])} MW`}
              secondaryLabel={`${flowDirectonLabel} Flow Limit`}
              secondaryValue={flowLimit}
            />
          )
        })
        }
      </TooltipList>
    )
  }
}

const HVDC = ({ data, width = '100%', height = '100%', zoomCallback }) => {

  const hvdcData = data?.items || []
  const [graph, actions] = useSeries(SERIES, hvdcData, {zoomCallback})
  const lastHVDCRecord = _.last(hvdcData || [])
  return (
    <>
      {!!lastHVDCRecord && <DirectionSummary data={lastHVDCRecord} />}
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('HH:mm')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />
          <Tooltip
            content={args => CustomTooltip({ ...args, series: graph.activeSeries })}
          />
          {graph.visibleSeries.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={SERIES[g].color}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={graph.activeSeries.includes(g)}
            />
          )}
          {actions.renderZoomOverlay()}
          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
        </LineChart>
      </ResponsiveContainer>
      <Legend series={SERIES} visible={graph.visibleSeries} onSeriesToggle={actions.setVisibleSeries} />
    </>
  )
}

export default HVDC