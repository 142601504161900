/** @jsxImportSource @emotion/react */
import React from 'react'
import Grid from '@mui/material/Grid'
import { PageContainer, WidgetsMissingInfo } from 'components'
import { StylesObject } from "types"
import { Widget } from '../../widgets'

const Trading = () => {

  return (
    <PageContainer>
      <Grid container spacing={2}>

        <WidgetsMissingInfo
          widgets={['regional-predicted-load-graph', 'node-predicted-load-graph', 'interim-price-graph']}
          required="Market Prices"
        />

        <Widget id="regional-predicted-load-graph"/>
        
        <Widget id="node-predicted-load-graph"/>

        <Widget id="nrss-regional-predicted-load-graph"/>

        <Widget id="interim-price-graph"/>

      </Grid>
    </PageContainer>
  );
}

const styles = {

} as StylesObject

export default Trading