/** @jsxImportSource @emotion/react */
import React from 'react'
import Grid from '@mui/material/Grid'
import { PageContainer, WidgetsMissingInfo } from 'components'
import { StylesObject } from "types"
import { Widget } from '../../widgets'

const Generation = () => {

  return (
    <PageContainer>
      <Grid container spacing={2}>

        <WidgetsMissingInfo
          widgets={['residuals-graph', 'regional-peaks-graph', 'yearly-rcpd-peaks-graph']}
          required="Peak Demand"
        />

        <Widget id="residuals-graph"/>

        <Widget id="regional-peaks-graph"/>

        <Widget id="yearly-rcpd-peaks-graph"/>

      </Grid>
    </PageContainer>
  );
}

const styles = {

} as StylesObject

export default Generation