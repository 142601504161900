/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import TireRepairOutlinedIcon from '@mui/icons-material/TireRepairOutlined'
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource} from 'hooks'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import { Widgets } from './'
import _ from 'lodash'

const IslandLoadGraph = (props) => {
  const widget = Widgets['island-load-graph']
  const [daily, dailyActions] = useResource('daily')
  const [loadLoading, result, reloadLoad] = useDependency(() => dailyActions.request('load'))
  const loadUpdatedAt = _.last(daily.load?.data?.items || [])?.timestamp

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  return (
    <WidgetContainer
      widget={widget}
      title="Island Load (MW)"
      icon={<TireRepairOutlinedIcon/>}
      lastUpdatedAt={loadUpdatedAt}
      loading={loadLoading}
      onWidgetRefresh={reloadLoad}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      zoom={zoom}
      {...props}
    >
      <Graphs.Load
        data={daily.load?.data}
        zoomCallback={zoomCallback}
      />
    </WidgetContainer>
  )
}

export default IslandLoadGraph