import NavAuthenticated   from './authenticated/Nav'
import NavUnauthenticated from './unauthenticated/Nav'
import NavUnknown         from './unknown/Nav'

import RoutesAuthenticated   from './authenticated/Routes'
import RoutesUnauthenticated from './unauthenticated/Routes'
import RoutesUnknown         from './unknown/Routes'

export { default as App } from './App'

export const Routes   = { Authenticated: RoutesAuthenticated, Unknown: RoutesUnknown, Unauthenticated: RoutesUnauthenticated}
export const Nav      = { Authenticated: NavAuthenticated,    Unknown: NavUnknown,    Unauthenticated: NavUnauthenticated}