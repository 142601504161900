/** @jsxImportSource @emotion/react */
import React from 'react'
import moment from 'moment'
import { round } from 'utils'
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';

import { TooltipList, TooltipListItem, TooltipHeader, formatTimestamp } from './Tooltip'
import Legend from './Legend'
import { useSeries } from 'hooks'

const SERIES = {
  'niMw': {
    label: 'North Island',
    color: '#ed8b00',
  },
  'niMw1wk': {
    label: 'North Island -1 week',
    color: '#ED8B00',
    strokeDasharray: "5 4",
  },
  'siMw': {
    label: 'South Island',
    color: '#B1E9E6',
  },
  'siMw1wk': {
    label: 'South Island -1 week',
    color: '#B1E9E6',
    strokeDasharray: "5 4",
  },
}

const SERIES_OPTIONS = {
  xTicks: {every: 'hour', add: 3}
}

const CustomTooltip = ({ active, series, payload }: any) => {
  if (active && payload?.length && series?.length){
    const {timestamp, tradingPeriod, ...data} = payload[0].payload ?? {};
    return (
      <TooltipList>
        <TooltipHeader label={formatTimestamp(timestamp, {tradingPeriod})}/>
        { series.map((g, idx) => {
            const {label, color, strokeDasharray} = SERIES[g]
            const peakAtt = g === 'niMw' ? 'ni7DayMax' : (g === 'siMw' ? 'si7DayMax' : null)
            return (
              <TooltipListItem
                key={idx}
                label={label}
                color={color}
                strokeDasharray={strokeDasharray}
                value={`${round(data[g])} MW`}
                secondaryLabel={peakAtt && '7 Day Peak'}
                secondaryValue={peakAtt && `${round(data[peakAtt])} MW`}
              />
          )})
        }
      </TooltipList>
    )
  }
}


const Load = ({data, width='100%', height='100%', zoomCallback}) => {

  const [graph, actions] = useSeries(SERIES, data?.items, {...SERIES_OPTIONS, zoomCallback})

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('HH:mm')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />

          <Tooltip
            content={args => CustomTooltip({...args, series: graph.activeSeries})}
          />

          {graph.visibleSeries.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={SERIES[g].color}
              strokeDasharray={SERIES[g].strokeDasharray}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={actions.isSeriesActive(g)}
            />
          )}

          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3"/>
        </LineChart>
      </ResponsiveContainer>
      <Legend
        series={SERIES}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
      />
    </>
  )

}

export default Load