import { yearFilterOptions } from 'utils'
import SessionStatus from './SessionStatus'

// RCPD year runs from 1 September to 31 August
const CURRENT_RCPD_YEAR = new Date().getMonth() >= 8 ? new Date().getFullYear() + 1 : new Date().getFullYear()
const RCPD_YEARS = yearFilterOptions({from: 2016, to: CURRENT_RCPD_YEAR})

const RCPD_REGIONS = [
  {id: 'UNI', label: 'Upper North Island'},
  {id: 'LNI', label: 'Lower North Island'},
  {id: 'USI', label: 'Upper South Island'},
  {id: 'LSI', label: 'Lower South Island'},
]

const DEFAULT_WIDGET_REFRESH_MS = parseInt(process.env.REACT_APP_DEFAULT_REFRESH_MS || '60000')

export {
  SessionStatus,
  RCPD_YEARS,
  RCPD_REGIONS,
  DEFAULT_WIDGET_REFRESH_MS,
}