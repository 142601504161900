/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import { Widgets } from './'
import { useAuthorization } from 'hooks'

//
// Generic wrapper to render a widget by id
// handles user authorization & grid sizing
//

const DEFAULT_GRID_SIZE = {
  xs: 12,
  lg: 6,
  xl: 4,
}

const Widget = ({id, favourite=false}) => {
  const widget = Widgets[id]
  const [fullScreen, setFullScreen] = useState(false)
  const authorization = useAuthorization()
  const permitted = authorization.isWidgetPermitted(widget)

  if (!widget) {
    console.warn(`Widget with id ${id} not found`)
    return null
  }

  if (!permitted){
    return null
  }

  const gridSize = widget.size || DEFAULT_GRID_SIZE
  const toggleFullScreen = () => setFullScreen(!fullScreen)

  return (
    <Grid item {...gridSize}>
      <Dialog
        fullWidth
        onClose={() => setFullScreen(false)}
        open={fullScreen}
        maxWidth="xl"
        css={styles.dialog}
      >
        {React.createElement(Widgets[id].component, {favourite, fullScreen: true, toggleFullScreen})}
      </Dialog>

      {React.createElement(Widgets[id].component, {favourite, fullScreen: false, toggleFullScreen})}
    </Grid>
  )
}

const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      // actual size determined by WidgetContainer .fullScreen
      maxWidth: '100vw',
      maxHeight: '100vh',
    },
    '& .widget-container': {
      overflowY: 'auto',
    },
    '& .map': {
      width: '100%',
      height: '100%',
      // center maps
      display: 'flex',
      // 'alignItems': 'center', // not working in Safari
      justifyContent: 'center',
      '& svg': {
        // avoid scroll on full screen by keeping aspect ratio
        maxHeight: 'min(100vw, 100vh)',
        maxWidth: 'min(100vw, 100vh)',
      }
    }
  }
}

export default Widget