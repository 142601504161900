import { useState, useEffect } from 'react'
import { ReferenceArea } from 'recharts'

//
// defines a set of event handlers enabling a Recharts drag & drop zoom
// the zoom works by slicing the data between from & to
// pass chartProps on to the chart and call renderZoomOut and renderZoomOverlay
//

const useZoom = (data, options={}) => {

  const [zoom, setZoom] = useState({
    zoomed: false,
    from: null,
    to: null,
    data
  })

  const zooming = !!(zoom.from && zoom.to && zoom.from !== zoom.to) // drag & drop in progress

  const zoomOut = () => {
    setZoom({
      zoomed: false,
      from: null,
      to: null,
      data
    })

    options.zoomCallback && options.zoomCallback({zoomOut, zoomed: false })
  }

  // when data changes in, auto zoom out
  useEffect(zoomOut, [JSON.stringify(data)]) // TODO could more efficiently check on some updatedAt timestamp

  const zoomIn = () => {
    const { from: _from, to: _to, data } = zoom
    // its possible the user dragged from right to left
    const from = Math.min(_from, _to)
    const to = Math.max(_from, _to)

    setZoom({
      ...zoom,
      zoomed: true,
      from: null,
      to: null,
      data: data.filter(d => d.timestamp >= from && d.timestamp <= to)
    })

    options.zoomCallback && options.zoomCallback({zoomOut, zoomed: true})
  }

  const onMouseUp = e => zooming ? zoomIn() : zoomOut()
  const onMouseDown = e => setZoom({ ...zoom, from: e.activeLabel })
  const onMouseMove = e => zoom.from && setZoom({ ...zoom, to: e.activeLabel })
  const onMouseLeave = e => setZoom({...zoom, from: null, to: null})

  const renderZoomOverlay = () => {
    const {from, to} = zoom
    return zooming ?
      <ReferenceArea
        x1={from}
        x2={to}
        fillOpacity={0.15}
      /> : null
  }

  const renderZoomOut = () => <button disabled={!zoom.zoomed} onClick={zoomOut}> Zoom Out </button>

  return [
    {...zoom, zooming},
    { renderZoomOverlay, renderZoomOut, zoomOut, chartProps: { onMouseDown, onMouseMove, onMouseUp, onMouseLeave}}
  ]
}

export default useZoom