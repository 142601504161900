import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0c3345',
    },
    secondary: {
      main: '#f50057',
    },

    text: {
      primary: '#fff',
      secondary: '#fff'
      // disabled: ''
    },

    action: {
      active: '#fff',
      hover: '#002030',
      selected: '#fff',
      disabled: '#D5D5D5',
      focus: '#fff',
      // disabledBackground
    },

    background: {
      default: '#002030',
      paper: '#003b59',
    },

    // divider:

  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
  components: {
    MuiButtonBase: {
      styleOverrides:{
        root: {
          whiteSpace: 'nowrap' // fixes button text wrapping on older Safari
        }
      }
    },
    MuiCheckbox: {
      styleOverrides:{
        root: {
          color: "#00a9a5",
          '&.Mui-checked': {
            color:  "#00a9a5",
          },
        }
      }
    },
    MuiSlider:{
      styleOverrides:{
        root: {
          color:"#00a9a5"
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '& .link-small': {
            margin: 5,
            textAlign: 'center',
            display: 'block',
            color: '#fff',
            fontSize: '0.8rem',
            '&:hover': {
              textDecoration: 'underline'
            }
          }
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          maxWidth: 1000,
          margin: '0 auto',
          width: '100%',
        }
      }
    },
    
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: '10px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          marginBottom: '10px',
          '&+.MuiButton-root': {
            marginLeft: '10px'
          }
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        // variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#002030',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#fff',
          textDecoration: 'underline',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#00a9a5'
          }
        }
      }
    }
  }
})

// .css-1xbvxso-MuiFormLabel-root-MuiInputLabel-root.Mui-focused