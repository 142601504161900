/** @jsxImportSource @emotion/react */
import React from 'react'
import moment from 'moment'
import { round } from 'utils'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { TooltipList, TooltipListItem, TooltipHeader, formatTimestamp } from './Tooltip'
import Legend from './Legend'
import { useSeries } from 'hooks'

const SERIES = {
  'mwcleared': {
    label: "Cleared",
    color: '#B1E9E6',
  },
  "mwoffered": {
    label: "Offered",
    color: '#00A9A5',
  }
}

const CustomTooltip = ({ active, series, payload }: any) => {
  if (active && payload?.length && series?.length) {
    const { timestamp, tradingPeriod, ...data } = payload[0].payload ?? {};
    return (
      <TooltipList>
        <TooltipHeader label={formatTimestamp(timestamp, {tradingPeriod})} />
        {series.map((g, idx) => {
          const { label, color } = SERIES[g]
          return (
            <TooltipListItem
              key={idx}
              label={label}
              color={color}
              value={`${round(data[g])} MW`}
            />
          )
        })
        }
      </TooltipList>
    )
  }
}

const Reserves = ({ data, width = '100%', height = '50%', zoomCallback }) => {

  const [graph, actions] = useSeries(SERIES, data, {zoomCallback})

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('HH:mm')}
            axisLine={false}
            tickLine={false}
          />

          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />

          <Tooltip
            content={args => CustomTooltip({ ...args, series: graph.activeSeries })}
          />


          {graph.visibleSeries.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={SERIES[g].color}
              strokeDasharray={SERIES[g].strokeDasharray}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={actions.isSeriesActive(g)}
            />
          )}

          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3" />

        </LineChart>
      </ResponsiveContainer>
      <Legend
        series={SERIES}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
      />
    </>
  )
}


export default Reserves