import { useCallback, useState } from 'react'
import qs from 'qs'

const getUrlState = (namespace?: string, attributes?: string | string[]) => {
  const queryString = window.location.search.slice(1)
  const state = qs.parse(queryString, { allowDots: true })
  const namespaceState = namespace ? (state[namespace] || {}) : state
  if (attributes) {
    Object.keys(namespaceState).forEach(key => {
      if (!attributes.includes(key)) {
        delete namespaceState[key]
      }
    })
  }
  return namespaceState as qs.ParsedQs
}

const useQueryString = (namespace?: string, attributes?: string | string[]): [any, (state: any) => void] => {
  const [urlState, _setUrlState] = useState(getUrlState(namespace, attributes))

  const setUrlState = useCallback((state) => {
    _setUrlState((prevState) => {
      if (typeof state === 'function') {
        state = state(prevState)
      }
      const updatedState = { ...prevState, ...state }
      const namespacedState = namespace ? { [namespace]: { ...getUrlState(namespace), ...updatedState } } : updatedState
      const combinedUrlState = { ...getUrlState(), ...namespacedState }
      const queryString = qs.stringify(combinedUrlState, { arrayFormat: 'indices', allowDots: true })
      const location = [window.location.pathname, queryString].filter((i) => i).join('?')
      window.history.replaceState({}, '', location)
      return updatedState
    })
  }, [namespace, _setUrlState])

  return [urlState, setUrlState]
}
export default useQueryString
