/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import BatteryCharging90Icon from '@mui/icons-material/BatteryCharging90'
import Typography from '@mui/material/Typography'
import { WidgetContainer, Graphs } from 'components'
import { useDependency, useResource } from 'hooks'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import { Widgets } from './'
import _ from 'lodash'

const ReservesGraph = (props) => {
  const widget = Widgets['reserves-graph']
  const [daily, dailyActions] = useResource('daily')
  const [reservesLoading, result, reloadReserves] = useDependency(() => dailyActions.request('reserves'))

  const [NIZoom, setNIZoom] = useState({})
  const [SIZoom, setSIZoom] = useState({})

  const zoom = {
    zoomed: NIZoom.zoomed || SIZoom.zoomed,
    zoomOut: () => {
      NIZoom.zoomOut()
      SIZoom.zoomOut()
    }
  }

  const reservesUpdatedAt = _.last(daily.reserves?.data?.items || [])?.timestamp
  const groupByRegion = _.groupBy(daily.reserves?.data?.items, 'islandid')

  return (
    <>
      <WidgetContainer
        widget={widget}
        title="Reserves (MW)"
        icon={<BatteryCharging90Icon />}
        lastUpdatedAt={reservesUpdatedAt}
        loading={reservesLoading}
        onWidgetRefresh={reloadReserves}
        widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
        zoom={zoom}
        {...props}
      >
        <Typography css={styles.graphTitle}>North Island</Typography>
        <Graphs.Reserves
          data={groupByRegion["NI"]}
          zoomCallback={zoom => setNIZoom(zoom)}
        />
        <br/>
        <Typography variant='h5' css={styles.graphTitle}>South Island</Typography>
        <Graphs.Reserves
          data={groupByRegion["SI"]}
          zoomCallback={zoom => setSIZoom(zoom)}
        />

      </WidgetContainer>
    </>
  )
}

const styles = {
  graphTitle:{
    fontSize: 18,
    paddingLeft: 25,
    paddingBottom: 10,
  }
}

export default ReservesGraph