import moment from 'moment'
import { roundDateOrMoment } from './DateUtils'
import _ from 'lodash'

export function generateTimeTicks(from, to, {every='hour', add=1, skipFirst=true, skipLast=false, nearestMins=null}={}){
  let start = moment(from).startOf(every)
  if (nearestMins) start = roundDateOrMoment(start, nearestMins)
  const end = moment(to)
  let ticks = []

  while(start < end){
    ticks.push(start.valueOf())
    start.add(add, every)
  }
  if (skipFirst){
    ticks = ticks.splice(1)
  }
  if (skipLast){
    ticks.pop()
  }
  return ticks
}

export function toolTipTradingPeriodFormatter(label, payload){
  const ts = moment(payload[0]?.payload?.timestamp).format('DD/MM/YYYY')
  const tp = payload[0]?.payload.tp
  return `${ts} - TP${tp}`
}

// when memoising
// a cheap way to check if graph data has changed
export function memoGraphKey(data, att='timestamp'){
  const items = (Array.isArray(data) ? data : data?.items) || []
  return `${(items[0] || {})[att]}_${(_.last(items) || {})[att]}`
}

// fills data so it has a point at every interval (minute)
// the value for each point is the last known value up to then
// expects data with unix timestamps ordered ascending
export function fillChartData(data, {x='timestamp', y='value', every='minute', add=1, from=null, to=null}={}){
  const fillFrom = from || (data[0] || {})[x]
  const fillTo = to || (_.last(data) || {})[x]
  const results = []
  const reversed = data.toReversed()

  if (fillFrom && fillTo && fillFrom <= fillTo){
    let current = moment(fillFrom)
    while(current.valueOf() < to){
      // TODO find more efficiently if used in bigger data sets
      const lastKnown = reversed.find(item => item[x] <= current.valueOf()) || {}
      results.push({...lastKnown, [x]: current.valueOf()})
      current.add(add, every)
    }
  }

  return results
}

// insert a rolling average
export function addRollingAverage(data, {x='timestamp', y='value', series=`${y}Avg`, from=null, to=null, getValue=null}={}) {
  let sum = 0
  let count = 0
  let last = 0

  data.forEach((item, idx) => {
    if ((from === null || item[x] >= from) && (to === null || item[x] <= to)){
      const value = (getValue ? getValue(item, idx) : item[y]) || last
      last = value
      count += 1
      sum += value
      item[series] = sum / count
    }
  })
}

export function mergeChartData(data1, data2, {connect=true}={}){
  if (data1?.length && data2?.length){
    const combined = [...data1, ...data2]
    if (connect){
      const last = _.last(data1)
      const {timestamp, ...atts} = data2[0]
      combined[data1.length-1] = {...last, ...atts}
    }
    return combined
  } else {
    return []
  }
}

export function round(number, digits=2){
  return !(number === '' || number === null || isNaN(number)) &&
    Math.round((parseFloat(number) + Number.EPSILON) * Math.pow(10, digits)) / Math.pow(10, digits)
}

export function trailingZero(number, digits=2){
  return number && number.toFixed(digits)
}

export function yearFilterOptions({from=2016, to=2024}){
  const opts = []
  for (let i = to; i >= from; i--){
    opts.push(`${i-1}-${i}`)
  }
  return opts
}
