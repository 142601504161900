import { useMemo } from 'react'
import { useTokens } from "contexts/TokensContext";

export enum AccessGroups {
  EM6_PUBL = 'EM6_PUBL',
  EM6_RCPD = 'EM6_RCPD',
  EM6_PRIC = 'EM6_PRIC',
  EM6_SCAD = 'EM6_SCAD',
  EM6_RSRV = 'EM6_RSRV',
  EM6_NTWK = 'EM6_NTWK',
  EM6_CARB = 'EM6_CARB',
  EM6_RT   = 'EM6_RT',
}

export enum ReportGroups {
  scada     = AccessGroups.EM6_SCAD,
  price     = AccessGroups.EM6_PRIC,
  reserves  = AccessGroups.EM6_RSRV,
  arc       = AccessGroups.EM6_NTWK,
  emissions = AccessGroups.EM6_CARB,
  rcpd      = AccessGroups.EM6_RCPD,
}

const useAuthorization = () => {
  const [tokens,] = useTokens()

  const user: any = tokens.currentUser || {}
  const groups = user.groups || []
  const permittedReports = Object.keys(ReportGroups).filter(key => groups.includes(ReportGroups[key]))
  const isWidgetPermitted = widget => {
    if (!widget) return false
    if (widget.permissionsCheck) return widget.permissionsCheck(user) // custom permissions check
    if (!widget.groups?.length) return true
    return widget.groups.every(g => groups.includes(g))
  }

  return useMemo(() => ({
    user,
    groups,
    permittedReports,
    isWidgetPermitted
  }), [user])
}

export default useAuthorization