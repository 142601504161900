import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelect({value=[], onChange, label, options, allSelectedLabel='', noneSelectedLabel='', fullWidth=true}) {

  const handleChange = (event: any) => {
    const { target: { value }} = event
    const v = typeof value === 'string' ? value.split(',') : value
    onChange(v)
  };

  const renderValue = ids => {
    if (!ids?.length && noneSelectedLabel){
      return <em>{noneSelectedLabel}</em>
    }
    if (allSelectedLabel && allSelected(ids)){
      return allSelectedLabel
    } else {
      const labels = ids.map(id => options.find(optn => optn.id === id)?.label)
      return labels.join(', ')
    }
  }

  const allSelected = ids => ids.length === options.length

  return (
    <Box className='multi-select' sx={{ minWidth: 120 }}>
      <FormControl fullWidth={fullWidth}>
        <InputLabel {...(noneSelectedLabel ? {shrink: true} : {})}>{label}</InputLabel>
        <Select
          multiple
          displayEmpty
          value={value}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={renderValue}
          MenuProps={MenuProps}
        >
          {options.map(({id, label}) => (
            <MenuItem key={id} value={id}>
              <Checkbox checked={value.indexOf(id) > -1} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}