/** @jsxImportSource @emotion/react */
import React, {useMemo} from 'react'
import moment from 'moment'
import { previousIntervalTime, fillChartData, addRollingAverage, roundDateOrMoment, trailingZero, intervalForTime, timeForInterval, mergeChartData } from 'utils'
import {
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ReferenceArea,
  ReferenceDot,
} from 'recharts'
import _ from 'lodash'

import { TooltipList, TooltipListItem, TooltipHeader, formatTimestamp } from './Tooltip'
import Legend from './Legend'
import ReferenceLabel from './ReferenceLabel'
import { useSeries } from 'hooks'

const SERIES = {
  'dispatchPrice': {
    type: 'stepAfter',
    label: 'Dispatch price',
    color: '#87E6E5',
  },
  'dispatchPriceAvg': {
    type: 'monotone',
    label: 'Interim price trend',
    color: '#cbe612',
    strokeDasharray: '5 4',
  },
  'dispatchPriceAvgNextTP': {
    type: 'monotone',
    label: 'Interim price trend',
    color: '#cbe612',
    strokeDasharray: '5 4',
  },
  'nrssPrice': {
    type: 'stepBefore',
    label: 'NRSS forecast',
    color: '#ed8b00',
    strokeDasharray: '5 4',
  }
}

const SERIES_OPTIONS = {
  xTicks: {every: 'minute', add: 15, nearestMins: 15, skipFirst: true}
}

const CustomTooltip = ({ active, series, payload }: any) => {
  if (active && payload?.length && series?.length){
    const {timestamp, ...data} = payload[0]?.payload ?? {};
    const tradingPeriod = intervalForTime(timestamp)
    return (
      <TooltipList>
        <TooltipHeader label={formatTimestamp(timestamp, {tradingPeriod})}/>
        { series.map((g, idx) => {
            const {label, color, strokeDasharray} = SERIES[g]
            return (
              <TooltipListItem
                key={idx}
                label={label}
                color={color}
                strokeDasharray={strokeDasharray}
                value={`$${trailingZero(data[g])}`}
              />
          )})
        }
      </TooltipList>
    )
  }
}

const latestDispatchPrice = (timestamp, prices) => {
  const last = _.last(prices.filter(p => (p.intervalTime <= timestamp) && p.dispatchPrice))
  return last?.dispatchPrice
}

// dispatchData for plotting past prices
// marketNRData (NRSS) for future prices (next 2 intervals only)
// marketTData  (T) for current interval price (label on vertical green line)
const InterimPrice = ({dispatchData, marketNRData, marketTData, width='100%', height='100%', zoomCallback, currentTime}) => {

  const currentIntervalTime = roundDateOrMoment(currentTime, 30)
  const historicCutoff      = previousIntervalTime().valueOf()
  const futureCutoff        = moment(currentIntervalTime).add(1, 'hour').valueOf()

  // data for vertical reference lines
  const intervalPlus0Time   = currentIntervalTime.valueOf()
  const intervalPlus1Time   = moment(currentIntervalTime).add(30, 'minutes').valueOf()
  const intervalPlus2Time   = moment(currentIntervalTime).add(1, 'hour').valueOf()

  const intervalPlus1       = intervalForTime(currentTime) // reference lines are at end of tp
  const intervalPlus0       = (intervalPlus1 - 1) || 1 // if 0 then first interval of previous day
  const intervalPlus2       = (intervalPlus1 + 1) % 48 // if 49 then first interval of next day

  // official interim price at start of current interval
  const intervalPlus0Price  = (marketTData?.items || []).find(({tradingPeriod}) => tradingPeriod === intervalPlus0)?.price

  const data = useMemo(() => {
    // plot when the price change happened using runtime
    const dispatchPrices = (dispatchData?.items || []).map(({timestamp, runtime, nodereadings}) => ({timestamp: moment(timestamp).valueOf(), runTime: moment(runtime).valueOf(), intervalTime: moment(timestamp).valueOf(), dispatchPrice: nodereadings && nodereadings[0]?.price}))
    let historic = [...dispatchPrices]
    
    // from previous interval onward
    historic = historic.filter(({intervalTime}) => intervalTime >= historicCutoff)
    // add a point for every minute, data gets duplicated between gaps
    historic = fillChartData(historic, {every: 'minute', add: 1, to: currentTime.valueOf()})
    // add rolling average per minute
    addRollingAverage(historic, {series: 'dispatchPriceAvg', to: currentIntervalTime.valueOf(), getValue: item => latestDispatchPrice(item.timestamp, dispatchPrices)})
    addRollingAverage(historic, {series: 'dispatchPriceAvgNextTP', from: currentIntervalTime.valueOf(), getValue: item => latestDispatchPrice(item.timestamp, dispatchPrices)})

    // the rolling average price trendline we calculate does not always end at the actual intervalPlus0Price we get from the API
    // we override our calculation with the actual value
    const currentIntervalItem = historic.find(item => item.timestamp === intervalPlus0Time)
    if (currentIntervalItem){
      currentIntervalItem.dispatchPriceAvg = intervalPlus0Price
    }
    // add NRSS data, plot the end of each tradingPeriod so timestamp = tp+1
    let future = (marketNRData?.items || []).map(({tradingPeriod, price}) => ({timestamp: timeForInterval(tradingPeriod+1).valueOf(), nrssPrice: price, tradingPeriod}))
    // future only, up to 1 hour from now
    future = future.filter(({timestamp}) => timestamp >= currentTime.valueOf() && timestamp <= futureCutoff)
    // merge & remove gap between historic & future data
    return mergeChartData(historic, future)
  }, [JSON.stringify(dispatchData), JSON.stringify(marketNRData)])


  const [graph, actions] = useSeries(SERIES, data, {...SERIES_OPTIONS, zoomCallback})

  const intervalPlus1Price  = graph.data.find(({timestamp}) => timestamp === intervalPlus1Time)?.nrssPrice
  const intervalPlus2Price  = graph.data.find(({timestamp}) => timestamp === intervalPlus2Time)?.nrssPrice

  // dispatchPriceAvg and dispatchPriceAvgNextTP are rendered as two seperate disconnected series but represent the same data 
  const visibleSeries = graph.visibleSeries.filter(g => graph.visibleSeries.includes('dispatchPriceAvg') ? true : g !==  'dispatchPriceAvgNextTP')

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('HH:mm')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />

          <Tooltip
            content={args => CustomTooltip({...args, series: graph.activeSeries})}
          />

          {visibleSeries.map(g =>
            <Line
              key={g}
              type={SERIES[g].type || 'linear'}
              dataKey={g}
              stroke={SERIES[g].color}
              strokeDasharray={SERIES[g].strokeDasharray}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={actions.isSeriesActive(g)}
            />
          )}

          <defs>
            <linearGradient id="currentTimeGradient" x1="0" y1="1" x2="0" y2="0">
              <stop offset="0%" stopColor="#B1E9E6" stopOpacity={0} />
              <stop offset="100%" stopColor="#B1E9E6" stopOpacity={0.18} />
            </linearGradient>
          </defs>

          <ReferenceArea x1={intervalPlus0Time} x2={currentTime.valueOf()} fill="url(#currentTimeGradient)" />

          {actions.isSeriesVisible('dispatchPriceAvg') &&
          <>
            <ReferenceLine
              x={intervalPlus0Time}
              stroke="#cbe612"
              label={
                <ReferenceLabel
                  fill="#cbe612"
                  value={`TP ${intervalPlus0}\n$${intervalPlus0Price || ''}`}
                  yOffset={10}
                  xOffset={-5}
                />}
            />

            <ReferenceDot
              x={intervalPlus0Time}
              y={intervalPlus0Price}
              r={3}
              fill="#cbe612"
              stroke="#cbe612"
            />
          </>}

          {actions.isSeriesVisible('nrssPrice') &&
          <>
            <ReferenceLine
              x={intervalPlus1Time}
              stroke="#ed8b00"
              label={
                <ReferenceLabel
                  fill="#ed8b00"
                  value={`TP ${intervalPlus1}\n$${intervalPlus1Price || ''}`}
                  yOffset={10}
                  xOffset={-5}
                />}
            />
            <ReferenceLine
              x={intervalPlus2Time}
              stroke="#ed8b00"
              label={
                <ReferenceLabel
                  fill="#ed8b00"
                  value={`TP ${intervalPlus2}\n$${intervalPlus2Price || ''}`}
                  yOffset={10}
                  xOffset={-5}
                />}
            />

            <ReferenceDot
              x={intervalPlus1Time}
              y={intervalPlus1Price}
              r={3}
              fill="#ed8b00"
              stroke="#ed8b00"
            />

            <ReferenceDot
              x={intervalPlus2Time}
              y={intervalPlus2Price}
              r={3}
              fill="#ed8b00"
              stroke="#ed8b00"
            />
          </>}

          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3"/>
        </ComposedChart>
      </ResponsiveContainer>
      <Legend
        series={_.omit(SERIES, ['dispatchPriceAvgNextTP'])}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
      />
    </>
  )

}

export default InterimPrice