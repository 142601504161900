/** @jsxImportSource @emotion/react */
import React from 'react'
import Grid from '@mui/material/Grid'
import { PageContainer } from 'components'
import { StylesObject } from "types"
import { Widget } from '../../widgets'

const Home = () => {

  return (
    <PageContainer>
      <Grid container spacing={2}>

        <Widget id="regional-price-map"/>

        <Widget id="carbon-dial"/>

        <Widget id="previous-day-prices-graph"/>

        <Widget id="stacked-generation-graph"/>

        <Widget id="wind-forecast-graph"/>

      </Grid>
    </PageContainer>
  );
}

const styles = {

} as StylesObject

export default Home