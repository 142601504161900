import 'react-app-polyfill/ie11';
import './polyfills'
import './index.css';
import './DevTools';
import { createRoot } from 'react-dom/client';
import AppContainer from './containers/AppContainer';

// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<AppContainer/>);