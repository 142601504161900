/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import Button from '@mui/material/Button'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import { StylesObject } from 'types'

export type PaginationProps = {
  totalPages: number;
  page: number;
  onPageSelected?: (index: number) => void;
  style?: React.CSSProperties;
  linkStyle?: React.CSSProperties;
  showNext: boolean;
  showPrev: boolean;
  showFirst: boolean;
  showLast: boolean;
  lookAround: number;
  startAdornment: React.ReactNode;
}
export class Pagination extends Component<PaginationProps> {
  static defaultProps = {
    showNext:   true,
    showPrev:   true,
    showFirst:  true,
    showLast:   true,
    lookAround: 2,
    startAdornment: null
  }

  renderButtons = () => {
    const { showNext, showPrev, showFirst, showLast, page, totalPages, lookAround } = this.props
    if(totalPages <= 1) return false

    const buttons: React.ReactChild[] = []

    if(showFirst && totalPages > 1) buttons.push(this.renderButton('First', 1))
    if(showPrev && page > 1)        buttons.push(this.renderButton(<ChevronLeft css={styles.chevron}/>, page - 1))

    for(let index = page - lookAround; index <= page + lookAround; index++){
      if(index < 1 || index > totalPages) continue
      buttons.push(this.renderButton(index, index, index === page))
    }
    if(showNext && page < totalPages) buttons.push(this.renderButton(<ChevronRight css={styles.chevron}/>, page + 1))
    if(showLast && totalPages > 1)    buttons.push(this.renderButton('Last', totalPages))

    return buttons
  }

  renderButton = (text: React.ReactChild, index: number, disabled: boolean = false) => (
    <Button css={[styles.btn, disabled && styles.active]}
            className='btn'
            size='small'
            onClick={() => this.props.onPageSelected && this.props.onPageSelected(index)}
            disabled={disabled}
            key={`${text}-${index}`}>
      {text}
    </Button>
  )


  render = () =>
    <div css={styles.pagination}>
      <div css={styles.startAdornment}>
        { this.props.startAdornment }
      </div>
      { this.renderButtons() }
    </div>
}


const styles = {
  pagination: theme => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    background: 'rgba(0, 0, 0, 0.025)',
    borderRight: `6px solid ${theme.palette.primary.main}`,
    fontSize: '0.8rem',
    '& > .btn.btn': {
      marginLeft: 0,
      marginBottom: 0
    }
  }),
  startAdornment: {
    width: '100%',
    marginLeft: 6
  },
  btn: {
    padding: '5px',
    minWidth: 25,
  },
  chevron: theme => ({
    color: theme.palette.primary.main
  }),
  active: theme => ({
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.text.secondary,
    background: theme.palette.background.default,
    boxShadow: 'inset 0 0 2px rgba(0, 0, 0, 0.05)'
  }),
} as StylesObject
export default Pagination
