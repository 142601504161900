/** @jsxImportSource @emotion/react */
import React, {useMemo} from 'react'
import moment from 'moment'
import { formatLocaleDateTime, timeForInterval, round } from 'utils'
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import { TooltipList, TooltipListItem, TooltipHeader } from './Tooltip'
import Legend from './Legend'
import { useSeries } from 'hooks'
import _ from 'lodash'

const SERIES = {
  'uni': {
    label: 'Upper North Island',
    color: '#CBE612',
  },
  'lni': {
    label: 'Lower North Island',
    color: '#8552AF',
  },
  'usi': {
    label: 'Upper South Island',
    color: '#B1E9E6',
  },
  'lsi': {
    label: 'Lower South Island',
    color: '#CF0059',
  },
}

const SERIES_OPTIONS = {
  xTicks: false,
  tooltipTreshold: false,
}

const CustomTooltip = ({ active, series, payload }: any) => {
  if (active && payload?.length && series?.length){
    const {ranking, ...data} = payload[0].payload ?? {}
    return (
      <TooltipList>
        <TooltipHeader label={`Ranking ${ranking}`}/>
        { series.map((g, idx) => {
            const {label, color} = SERIES[g]
            const volume = data[g]
            const { tradingDate, tradingPeriod, source } = data[`${g}Details`] || {}
            const timestamp = timeForInterval(tradingPeriod, moment(tradingDate))

            return volume !== undefined ? (
              <TooltipListItem
                key={idx}
                label={label}
                color={color}
                value={`${round(volume)} MW`}
                secondaryLabel={`${formatLocaleDateTime(timestamp)} (${source})`}
              />
          ) : null })
        }
      </TooltipList>
    )
  }
}


const YearlyRCPDPeaks = ({data, width='100%', height='100%', zoomCallback}) => {

  const items = useMemo(() => {
    const itemsByRanking = _.groupBy(data.items || [], 'ranking')
    const rankings = Object.keys(itemsByRanking)
    return rankings.map(ranking => ({
      ranking,
      timestamp: parseInt(ranking),
      ...itemsByRanking[ranking].reduce((acc, {region, mw, ...details}) => ({
        ...acc,
        [region]: mw,
        [`${region}Details`]: details,
      }), {})}))
  }, [JSON.stringify(data)])

  const [graph, actions] = useSeries(SERIES, items, {...SERIES_OPTIONS, zoomCallback})
  const xTicks = [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].filter(tick => tick >= graph.from && tick <= graph.to)

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <BarChart
          throttleDelay={50}
          stackOffset="sign"
          data={graph.data}
          barGap={0}
          barCategoryGap={0}
          reverseStackOrder
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            dataKey="timestamp"
            scale="linear"
            domain={graph.xDomain}
            ticks={xTicks}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />

          <Tooltip
            content={args => CustomTooltip({...args, series: graph.visibleSeries})}
          />

          {graph.visibleSeries.map(g =>
            <Bar
              key={g}
              dataKey={g}
              stackId="1"
              fill={SERIES[g].color}
            />
          )}

          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3"/>
        </BarChart>
      </ResponsiveContainer>
      <Legend
        series={SERIES}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
      />
    </>
  )

}

export default YearlyRCPDPeaks
