/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop'
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource, useFilter} from 'hooks'
import { Widgets } from './'
import { RCPD_YEARS, RCPD_REGIONS } from 'constants'
import {useSavedFilters} from 'contexts/SavedFiltersContext'

const DEFAULT_FILTER = {
  year: RCPD_YEARS[0],
}

const YearlyRCPDPeaksGraph = (props) => {
  const widget = Widgets['yearly-rcpd-peaks-graph']
  const [filter, setFilter] = useFilter(null, 'top-peaks')
  const savedFilters = useSavedFilters()

  const [peak, peakActions] = useResource('regionalPeak')

  // we can only request 1 region at a time
  const fetchPeaks = ({year}) => year && Promise.all(RCPD_REGIONS.map(({id: region}) =>
    peakActions.request('rcpdPeaks', {region, cm_year: year}, {as: region.toLowerCase()})
  ))

  const [loading, result, reloadPeaks] = useDependency(() => (
    fetchPeaks(filter)
  ), [filter])

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({...initialFilter, ...filter})
  }, [])

  const data = {
    // append regional peaks data from 4 seperate requests and add a region attribute
    items: RCPD_REGIONS.reduce((acc, curr) => {
      const region = curr.id.toLowerCase()
      const items = peak[region]?.data?.items || []
      return [...acc, ...items.map(item => ({region, ...item}))]
    }, [])
  }

  const updatedAt = peak.uni?.responseAt

  return (
    <WidgetContainer
      widget={widget}
      title="Top Regional Peaks (MW)"
      icon={<AlignVerticalTopIcon/>}
      lastUpdatedAt={updatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={loading}
      onWidgetRefresh={reloadPeaks}
      renderFilter={() => <Graphs.YearFilter filter={filter} onChange={setFilter} />}
      onFilterSave={() => savedFilters.update(widget, filter)}
      zoom={zoom}
      {...props}
    >
      <Graphs.YearlyRCPDPeaks
        data={data}
        zoomCallback={zoomCallback}
      />
    </WidgetContainer>
  )
}

export default YearlyRCPDPeaksGraph