/** @jsxImportSource @emotion/react */
import React, { PureComponent } from 'react'

import Button from '@mui/material/Button'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { StylesObject } from 'types';

type ConfirmationDialogValue = any;

type State = { value: ConfirmationDialogValue };

export type ConfirmationDialogProps = {
  onCancel?: (value: ConfirmationDialogValue) => void;
  onConfirm?: (value: ConfirmationDialogValue) => void;
  open?: boolean;
  title: React.ReactNode;
  value?: ConfirmationDialogValue;
  canCancel?: boolean;
  canConfirm?: boolean;
  theme?: string;
  confirmLabel?: string,
  cancelLabel?: string;
  className?: string;
  children?: React.ReactNode;
}

export class ConfirmationDialog extends PureComponent<ConfirmationDialogProps, State> {
  constructor(props) {
    super(props)
    this.state = { value: this.defaultValue }
  }

  static defaultProps = {
    onConfirm: () => { },
    canCancel: true,
    canConfirm: true,
    confirmLabel: 'OK',
    cancelLabel: 'CANCEL',
    theme: 'light'
  }

  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.resetValue()
    }
  }

  resetValue() {
    this.setState({ value: this.defaultValue })
  }

  get defaultValue() {
    let { value } = this.props
    return (value ? value : '')
  }

  get value() {
    return this.state.value ? this.state.value.trim() : ''
  }

  handleCancel = () => {
    this.props.onCancel && this.props.onCancel(this.value)
  }

  handleConfirm = e => {
    if (e.target.checkValidity()) {
      e.preventDefault()
      this.props.onConfirm && this.props.onConfirm(this.value)
    }
  }

  canCancel() {
    return !!this.props.canCancel
  }

  canConfirm() {
    return !!this.props.canConfirm
  }

  setValue(value) {
    this.setState({ value })
  }

  renderBody() {
    return this.props.children
  }

  get hasCancelHandler() {
    return !!this.props.onCancel
  }

  render = () => {
    return (
      <Dialog open={this.props.open ?? false} css={styles.root}>
        <form onSubmit={this.handleConfirm}>
          <DialogTitle>
            {this.props.title}
          </DialogTitle>
          <DialogContent css={styles.content}>
            {this.renderBody()}
          </DialogContent>
          <DialogActions>
            {this.hasCancelHandler &&
              <Button css={styles.button}
                variant='contained' onClick={this.handleCancel}
                color='primary' disabled={!this.canCancel()}>{this.props.cancelLabel}</Button>
            }
            <Button css={styles.button} type='submit'
              color='secondary' variant='contained' disabled={!this.canConfirm()}>{this.props.confirmLabel}</Button>
          </DialogActions>
        </form>
      </Dialog>
    )
  }
}

const styles = {
  root: {
    '& .MuiPaper-root': {
      maxWidth: '400px',
      flex: 1,
    }
  },
  content: {
  },
  button: {
    width: '100%',
    height: '45px'
  }
} as StylesObject

export default ConfirmationDialog