/** @jsxImportSource @emotion/react */
import React from 'react'
import moment from 'moment'
import { round } from 'utils'
//
// Market Summary for Carbon Dial
//
const CarbonMarketSummary = ({ width, height, margin, currentYearAvgGkwh, currentMonthAvgGkwh, nzRenewable }) => {
  const piestyles = styles.piestyles
  const percentageToDecimal = (percentage) => (
    parseFloat(percentage) / 100
  )
  const { left, right, top, bottom } = margin
  const cx = (percentageToDecimal(piestyles.cx)) * width
  const cy = (percentageToDecimal(piestyles.cy)) * height
  const innerRadius = percentageToDecimal(piestyles.outerRadius) || 0.7
  const calculateDiameter = () => {
    return Math.min(
      Math.abs(width - (left || 0) - (right || 0)),
      Math.abs(height - (top || 0) - (bottom || 0)),
    ) * innerRadius;
  }
  const diameter = calculateDiameter(width, height)
  const radius = diameter / 2
  return (
    <React.Fragment>
      <svg x={cx - radius} y={cy} viewBox='0 0 300 250'>
        <text css={styles.summaryLabel} textDecoration="underline" x={10} y={24}>{moment().format("YYYY")} Average</text>
        <text css={styles.summaryValue} x={10} dx="1em" y={27} dy="1em">{round(currentYearAvgGkwh, 0)} g/kWh</text>
        <text css={styles.summaryLabel} textDecoration="underline" x={110} y={24}>{moment().format("MMMM")} Average</text>
        <text css={styles.summaryValue} x={110} dx="1.3em" y={27} dy="1em"> {round(currentMonthAvgGkwh, 0)} g/kWh</text>
        <text css={styles.summaryLabel} textDecoration="underline" x={5} dx="1em" y={48} dy="1em"> Renewable Energy Generation</text>
        <text css={styles.summaryValue} x={5} dx="6.5em"  y={50} dy="2em">{round(nzRenewable, 0)}%</text>
      </svg>
    </React.Fragment>
  )
}
const styles = {
  piestyles: {
    cx: '50%',
    cy: '50%',
    innerRadius: "60%",
    outerRadius: "90%"
  },
  summaryLabel: {
    fontSize: 13,
    fontWeight: 250,
    fill:"white"
  },
  summaryValue:{
    fontWeight: 900,
    fontSize: 13,
    fill:"white"
  }
}
export default CarbonMarketSummary