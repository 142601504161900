import * as API from "api"
import { useState } from "react";

export type Version = string;

const useVersion = (): [Version | null] => {
  const version = process.env.REACT_APP_VERSION || ''
  return [version]
}

export default useVersion