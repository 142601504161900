/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import {WidgetContainer, Maps} from 'components'
import {useDependency, useResource, useFilter} from 'hooks'
import {useNodes} from 'contexts/NodesContext'
import {useSavedFilters} from 'contexts/SavedFiltersContext'
import { Widgets } from './'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import _ from 'lodash'
import moment from 'moment'

const DEFAULT_FILTER = {
  nodes: [
    'BRB0331',
    'OTA2201',
    'HAM2201',
    'WKM2201',
    'RDF2201',
    'TUI1101',
    'SFD2201',
    'BPE2201',
    'HAY2201',
    'KIK2201',
    'ISL2201',
    'DOB0661',
    'BEN2201',
    'INV2201',
    'HWB2201',
  ]
}

const NodePriceMap = (props) => {
  const widget = Widgets['node-price-map']

  const [filter, setFilter] = useFilter(null, 'node-price')
  const { nodes: allNodes } = useNodes()
  const savedFilters = useSavedFilters()

  const selectedNodes = _.compact((filter.nodes || []).map(nodeId => allNodes.find(n => n.nodeId === nodeId)))

  const [price, priceActions] = useResource('price')
  const [loading, result, reloadPrice] = useDependency(() => priceActions.request('recent', filter), [filter])

  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({...initialFilter, ...filter})
  }, [])

  const last = _.last(price.recent?.data?.items || [])
  const mapUpdatedAt = last ? moment(last.timeStamp, 'HH:mm') : null

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  return (
    <WidgetContainer
      widget={widget}
      title="Price ($/MWh)"
      icon={<AccountTreeIcon/>}
      lastUpdatedAt={mapUpdatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={loading}
      onWidgetRefresh={reloadPrice}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      renderFilter={() => <Maps.NodeFilter filter={filter} onChange={setFilter} nodeTypes={['pricingNode']} />}
      onFilterSave={() => savedFilters.update(widget, filter)}
      zoom={zoom}
      {...props}
    >
      <Maps.NodePrice
        data={price.recent?.data}
        nodes={selectedNodes}
        zoomCallback={zoomCallback}
      />
    </WidgetContainer>
);
}

const styles = {}


export default props => (
  <NodePriceMap {...props}/>
)