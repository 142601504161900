/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { Loader } from '.'

export type LoaderOverlayProps = {
  size?: number;
}
export class LoaderOverlay extends Component<LoaderOverlayProps> {
  static defaultProps = {
    size: 100,
    color: '#FF4A00'
  }

  render = () =>
    <div css={{
      height: '100%',
      width: '100%',
      minHeight: 120,
      position: 'absolute',
      left: 0,
      zIndex: 3
    }}>
      <div >
        <Loader size={this.props.size}/>
      </div>
    </div>
}

export default LoaderOverlay
