import { useState, useEffect, useMemo } from 'react'
import { scaleLinear } from 'd3-scale'
import { generateTimeTicks } from 'utils'
import useZoom from './useZoom'
import moment from 'moment'


const DEFAULT_OPTIONS = {
  xTicks: {every: 'hour', add: 3},
  tooltipTreshold: 0.3
}

//
// for Rechart time series
// handles graph zooming, series toggle & mouse hover tooltips
// pass chartProps on to the chart, axis domains & call renderZoomOut and renderZoomOverlay
//

const useSeries = (series, rawData, options={}) => {

  const formattedData = rawData || []
  if (typeof formattedData[0]?.timestamp === 'string'){
    formattedData.forEach(item => item.timestamp = moment(item.timestamp).valueOf())
  }

  const [{data, zooming, zoomed}, zoomActions] = useZoom(formattedData, options)
  // series visibility can be toggled from the legend
  const [visibleSeries, setVisibleSeries] = useState(Object.keys(series))
  // we keep track of any visible series that have a value close to the current mouse pointer
  const [activeSeries, setActiveSeries] = useState([])

  useEffect(() => setVisibleSeries(Object.keys(series)), [JSON.stringify(series)])

  const yValues = useMemo(
    () => data.reduce((acc, curr) => ([...acc, ...visibleSeries.filter(g => !!curr[g] || curr[g] === 0).map(g => curr[g])]), []),
    [JSON.stringify(data), JSON.stringify(visibleSeries)]
  )
  const yMin = Math.min(...yValues)
  const yMax = Math.max(...yValues)
  const yOffset = (yMax - yMin) / 10
  const yScale = useMemo(
    () => scaleLinear().domain([yMin - yOffset, yMax + yOffset]),
    [yMin, yMax]
  )
  const treshold = (yMax - yMin) * (options.tooltipTreshold || DEFAULT_OPTIONS.tooltipTreshold)
  const yDomain = [yMin - yOffset, yMax + yOffset]

  const from = data[0]?.timestamp
  const to = data[data.length-1]?.timestamp
  const xTickOpts = options.xTicks || DEFAULT_OPTIONS.xTicks
  const xTicks = useMemo(
    () => (options.xTicks !== false) && generateTimeTicks(from, to, xTickOpts),
    [from, to]
  )

  const xDomain = ['dataMin', 'dataMax']
  const xScale = 'time'

  const onMouseMove = event => {

    if (options.tooltipTreshold !== false) {
      if (zooming){
        // dont show tooltips while selecting an area to zoom
        setActiveSeries([])
      } else {
        const {isTooltipActive, activePayload, chartY} = event
        if (isTooltipActive && activePayload?.length) {
          const activeY = yScale.invert(chartY)
          const data = activePayload[0].payload ?? {}
          setActiveSeries(visibleSeries.filter(series => Math.abs(activeY - data[series]) < treshold))
        }
      }
    }

    zoomActions.chartProps.onMouseMove(event)
  }

  const isSeriesActive = key => activeSeries.includes(key)

  const isSeriesVisible = key => visibleSeries.includes(key)

  return [
    { data, visibleSeries, activeSeries, from, to, yMin, yMax, yScale, yDomain, xTicks, xScale, xDomain, zoomed },
    {
      setVisibleSeries,
      setActiveSeries,
      isSeriesActive,
      isSeriesVisible,
      chartProps: { ...zoomActions.chartProps, onMouseMove },
      zoomOut: zoomActions.zoomOut,
      renderZoomOverlay: zoomActions.renderZoomOverlay,
      renderZoomOut: zoomActions.renderZoomOut,
    }
  ]
}
export default useSeries
