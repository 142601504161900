import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import {ControlledForm, CenteredCard, ErrorBanner, PromiseButton} from 'components'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography'
import {useSnackbar} from "contexts/SnackbarContext";
import {ForgotPasswordParams, useTokens} from "contexts/TokensContext";
import {useHistory} from 'react-router-dom'

const Forgot = () => {
  const [tokens, actions] = useTokens()
  const snackbar = useSnackbar()
  const history = useHistory()

  const [formData, setFormData] = useState<Partial<ForgotPasswordParams>>({})

  const submit = async () => {
    await actions.forgot(formData as ForgotPasswordParams)
    snackbar.show("Request Sent")
    setFormData({})
    history.push('/confirm_forgot_password')
  }

  return (
    <CenteredCard centerVertically={false}>
      <ControlledForm data={formData} onChange={setFormData} onSubmit={submit}>
        <CardContent>
          <Typography variant='h6'>Forgot your password?</Typography>
          <p>
            Enter your email and we'll send a link to reset your password.
          </p>
          {tokens.errors && <ErrorBanner>{tokens.errors.forgot?.message}</ErrorBanner>}
          <FormGroup>
            <TextField fullWidth name='email' autoFocus/>
          </FormGroup>
        </CardContent>
        <CardActions>
          <PromiseButton variant='contained' fullWidth type='submit'>Send link</PromiseButton>
        </CardActions>
        <Link className='link-small' to='/tokens/sign_in'>Sign in</Link>
      </ControlledForm>
    </CenteredCard>
  )
}

export default Forgot