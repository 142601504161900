/** @jsxImportSource @emotion/react */
import React from 'react'
import MultiSelect from 'components/MultiSelect'
import Grid from '@mui/material/Grid'

export const REGIONS = [
  {id: 'NZ', label: 'Nationwide'},
  {id: 'NI', label: 'North Island'},
  {id: 'SI', label: 'South Island'},
]

export const RUN_TYPES = [
  {id: 'NRS', label: 'NRS'},
  {id: 'PRS', label: 'PRS'},
  {id: 'RTD', label: 'RTD'},
  {id: 'WDS', label: 'WDS'},
]

const ResidualsFilter = ({filter, onChange}) => {

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <MultiSelect
          value={filter.regions}
          label="Region"
          allSelectedLabel="All Regions"
          noneSelectedLabel="None"
          options={REGIONS}
          onChange={regions => onChange({...filter, regions})}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MultiSelect
          value={filter.runTypes}
          label="Run Type"
          allSelectedLabel="All Run Types"
          noneSelectedLabel="None"
          options={RUN_TYPES}
          onChange={runTypes => onChange({...filter, runTypes})}
          fullWidth
        />
      </Grid>
    </Grid>
  )

}


export default ResidualsFilter