/** @jsxImportSource @emotion/react */
import React from 'react'
import { LabeledSelect } from 'components'
import { MenuItem } from '@mui/material'
import Grid from '@mui/material/Grid'

export const REGIONS = [
  {
    id: 'UNI', label: "Upper North Island - UNI",
  },
  {
    id: 'LNI', label: "Lower North Island - LNI",
  },
  {
    id: 'USI', label: "Upper South Island - USI",
  },
  {
    id: 'LSI', label: "Lower South Island - LSI",
  }
]
export const REGION_LOAD = [
  {
    id: 'load48hrs', label: "Region Load - 48hrs",
  },
  {
    id: 'load2hrs', label: "Region Load - 2hrs",
  }
]
const RegionalPeaksFilter = ({ filter, onChange }) => {


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <LabeledSelect
          value={filter.region}
          label="Region"
          onChange={({ target: { value } }) => onChange({ ...filter, region: value })}
          fullWidth
        >
          {REGIONS.map(({ id, label }, idx) => <MenuItem key={idx} value={id}>{label}</MenuItem>)}
        </LabeledSelect>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LabeledSelect
          label="Region Load"
          value={filter.regionLoad}
          onChange={({ target: { value } }) => onChange({ ...filter, regionLoad: value })}
          fullWidth
        >
          {REGION_LOAD.map(({ id, label }, idx) => <MenuItem key={idx} value={id}>{label}</MenuItem>)}
        </LabeledSelect>
      </Grid>
    </Grid>
  )
}



export default RegionalPeaksFilter