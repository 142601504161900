import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect, {SelectProps as MuiSelectProps} from '@mui/material/Select'

export type SelectProps = {
  helperText?: string;
  classes?: {root?: string, select?: string}
} & MuiSelectProps
export class Select extends Component<SelectProps> {
  static propTypes = {
    error: PropTypes.string,
    helperText: PropTypes.string,
    classes: PropTypes.object
  }

  render = () => {
    const { error, label, helperText, classes, ...rest} = this.props
    return (
      <FormControl variant="standard" fullWidth={this.props.fullWidth} error={error} className={classes?.root}>
        <InputLabel>{label}</InputLabel>
        <MuiSelect className={classes?.select} {...rest}/>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

}

export default Select