import React  from 'react'
import Button from '@mui/material/Button'
import MuiSnackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import {useSnackbar} from "contexts/SnackbarContext";

const Snackbar = () => {
  const {clear, notification} = useSnackbar()

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!notification}
      autoHideDuration={5000}
      onClose={clear}
      message={<span id="message-id">{notification?.message}</span>}
      color={"error"}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={clear}
          size="large">
          <CloseIcon />
        </IconButton>,
      ]}
    />
  );
}

export default Snackbar
