import { useRef, useState, useEffect, useCallback } from 'react'
import { history } from 'utils';

const redirect = (path) => {
  history.replace(path)
}

const handleDependenciesFailed = (err) => {
  const error = err.length ? err[0] : err
  switch (error.status) {
    case 403: break
    case 408: {
      redirect('/timed_out')
      break
    }
    case 504: {
      redirect('/timed_out')
      break
    }
    case 404: {
      redirect('/not_found')
      break
    }
    case undefined:
    case null: {
      break
    }
    default: {
      break
    }
  }
}

export type Loading = boolean
export type Result<T> = T | null
export type ResolveFn = (...args: unknown[]) => Promise<void>
export type Return<T> = [Loading, Result<T>, ResolveFn]
export type DependencyResolverFn<T> = (...args: any[]) => Promise<T>

const useDependency = <T = any>(resolver: DependencyResolverFn<T>, deps: any = []): Return<T> => {
  const [_unresolvedPromisses, _setUnresolvedPromisses] = useState<Promise<T>[]>([]);
  const loading = _unresolvedPromisses.length !== 0

  // TODO: result is modified concurrently. This can lead to inconsistant
  // values. Discuss with Oleksandr if we should just remove this property as
  // it does not seem to be used by components that use this hook.
  const [result, setResult] = useState<T | null>(null)

  const resolverRef = useRef(resolver)
  resolverRef.current = resolver

  const resolve = useCallback(async (...opts) => {
    setResult(null)
    let promise = resolverRef.current(...opts)
    _setUnresolvedPromisses((promises) => {
      return [...promises, promise]
    })

    try {
      const newResult = await promise
      setResult(newResult)
    } catch (error) {
      handleDependenciesFailed(error)
    } finally {
      _setUnresolvedPromisses((promises) => {
        let newPromises = [...promises]
        let index = newPromises.indexOf(promise)
        if(-1 !== index) {
          newPromises.splice(index, 1)
        }

        return newPromises
      })
    }
  }, [])

  useEffect(() => {
    resolve()
    // eslint-disable-next-line
  }, deps)

  return [loading, result, resolve]
}

export default useDependency
