/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { DatePicker, NodePicker } from 'components'
import Grid from '@mui/material/Grid'

export const SCADA_TYPES = [
  {id: 'load', label: 'Load'},
  {id: 'generation', label: 'Generation'},
  {id: 'hvdc', label: 'HVDC'},
]

export const NODE_TYPES = {
  load: ['loadNode'],
  generation: ['generationNode'],
  hvdc: [],
}

const ScadaFilter = ({filter, onChange}) => {

  const handleChange = att => event => {
    const updated = {...filter, [att]: event.target.value}
    if (att === 'scadaType'){
      // reset node selection as different node types apply
      updated.nodes = []
    }
    onChange(updated)
  }

  const { scadaType, nodes, from, to } = filter

  return (
    <>
      <Grid item xs={12} md={6}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="scada-type">Type</InputLabel>
          <Select
            labelId="scada-type"
            value={scadaType || ''}
            label="Type"
            onChange={ handleChange('scadaType') }
          >
            {SCADA_TYPES.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      {scadaType !== 'hvdc' &&
        <Grid item xs={12}>
          <NodePicker
            value={nodes}
            nodeTypes={NODE_TYPES[filter.scadaType]}
            onChange={ handleChange('nodes') }
            multiple
            fullWidth
          />
        </Grid>}
      <Grid item xs={6}>
        <DatePicker
          label="From"
          value={from}
          onChange={handleChange('from')}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label="To"
          value={to}
          onChange={handleChange('to')}
          fullWidth
        />
      </Grid>
    </>
  )

}

const styles = {
}

export default ScadaFilter