/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export const REGIONS = [
  {id: 'NZ', label: 'Nationwide'},
  {id: 'NI', label: 'North Island'},
  {id: 'SI', label: 'South Island'},
  {id: 'UNI', label: 'Upper North Island'},
  {id: 'LNI', label: 'Lower North Island'},
  {id: 'USI', label: 'Upper South Island'},
  {id: 'LSI', label: 'Lower South Island'},
]

const RegionFilter = ({filter, onChange}) => {
  const {region} = filter

  return (
    <div css={styles.container}>
      <FormControl variant="standard" fullWidth>
          <InputLabel id="region">Region</InputLabel>
          <Select
            labelId="region"
            value={region || ''}
            label="Region"
            onChange={({target: {value: region}}) => onChange({...filter, region})}
          >
            {REGIONS.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
    </div>
  )

}

const styles = {
  container: {
    display: 'flex',
  }
}

export default RegionFilter