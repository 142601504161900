/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MultiSelect from 'components/MultiSelect'

import { LabeledSelect } from 'components'
import Grid from '@mui/material/Grid'

const GENERATOR_SIZE = [
  { label: "Extra Large", id: "extraLarge" },
  { label: "Large", id: "large" },
  { label: "Medium", id: "medium" },
  { label: "Small", id: "small" }
]
const GENERATIOR_TYPES = [
  { label: 'Hydro', id: "Hydro" },
  { label: "CoGen", id: "Co-Gen" },
  { label: "Wind", id: "Wind" },
  { label: "Battery", id: "Battery" },
  { label: "Coal", id: "Coal" },
  { label: "Gas", id: "Gas" },
  { label: "Geothermal", id: "Geothermal" },
  { label: "Solar", id: "Solar" },
  { label: "Liquid", id: "Liquid" },
]
const GenerationCapacityFilter = ({ filter, onChange }) => {

  return (

    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <MultiSelect label="Generation Size" fullWidth
          allSelectedLabel="All Sizes"
          noneSelectedLabel="None"
          value={filter.generatorSize}
          onChange={generatorSize => onChange({ ...filter, generatorSize })}
          options={GENERATOR_SIZE}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MultiSelect label="Generator Type" fullWidth
          allSelectedLabel="All Types"
          noneSelectedLabel="None"
          options={GENERATIOR_TYPES}
          value={filter.generatorType}
          onChange={generatorType => onChange({ ...filter, generatorType })}

        />
      </Grid>
    </Grid>

  )
}

const styles = {
  container: {
    display: 'flex',
  }
}
export default GenerationCapacityFilter