/** @jsxImportSource @emotion/react */
import React from 'react'
import Chip from '@mui/material/Chip'

const COLORS = {
  staging: '#1BCD7F',
  development: '#D7B22C',
}

export const EnvironmentLabel = () => {

  const label = process.env.REACT_APP_ENVIRONMENT_LABEL
  const color = COLORS[(label || '').toLowerCase()]
  const version = process.env.REACT_APP_VERSION

  return (
    label ? <Chip css={styles.chip} style={{backgroundColor: color}} label={`${label} v${version}`}/> : null
  )
}

const styles = {
  chip: {
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 15,
  },
}

export default EnvironmentLabel