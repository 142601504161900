/** @jsxImportSource @emotion/react */
import React, {useMemo} from 'react'
import moment from 'moment'
import { formatLocaleDate, round, trailingZero } from 'utils'
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import { TooltipList, TooltipListItem, TooltipHeader } from './Tooltip'
import Legend from './Legend'
import { useSeries } from 'hooks'

const SERIES = {
  'batMwh': {
    label: 'Battery',
    color: '#6388d7',
  },
  'cogMwh': {
    label: 'Co-Gen',
    color: '#00a9a5',
  },
  'cgMwh': {
    label: 'Coal',
    color: '#ED8B00',
  },
  'gasMwh': {
    label: 'Gas',
    color: '#C6C6C6',
  },
  'geoMwh': {
    label: 'Geothermal',
    color: '#CBE612',
  },
  'hydMwh': {
    label: 'Hydro',
    color: '#8552AF',
  },
  'winMwh': {
    label: 'Wind',
    color: '#B1E9E6',
  },
  'liqMwh': {
    label: 'Diesel/Oil',
    color: '#CF0059',
  },
  'solMwh': {
    label: 'Solar',
    color: '#FFD52D',
  }
}

const SERIES_OPTIONS = {
  xTicks: false,
  tooltipTreshold: false,
}

const CustomTooltip = ({ active, series, payload }: any) => {
  if (active && payload?.length && series?.length){
    const {timestamp, ...data} = payload[0].payload ?? {}
    return (
      <TooltipList>
        <TooltipHeader label={formatLocaleDate(timestamp)}/>
        { series.map((g, idx) => {
            const {label, color} = SERIES[g]
            const volume = data[g]
            const price = data[g.replace('Mwh', 'Wap')]
            return volume !== undefined ? (
              <TooltipListItem
                key={idx}
                label={label}
                color={color}
                value={`${new Intl.NumberFormat('en').format(round(volume))} MWh`}
                secondaryLabel="Price"
                secondaryValue={`$${trailingZero(price)}`}
              />
          ) : null })
        }
      </TooltipList>
    )
  }
}


const StackedGeneration = ({data, width='100%', height='100%', zoomCallback}) => {

  // reformat the API data to something we can chart
  const generationData = useMemo(() => (data?.items || []).map(({tradingDate, generationType}) => ({
    ...(generationType || []).reduce((acc, curr) => ({...acc, ...curr}), {}),
    timestamp: moment(tradingDate).valueOf()
  })), [JSON.stringify(data)])


  const [graph, actions] = useSeries(SERIES, generationData, {...SERIES_OPTIONS, zoomCallback})

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <BarChart
          throttleDelay={50}
          stackOffset="sign"
          data={graph.data}
          barGap={0}
          barCategoryGap={0}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            tickFormatter={tick => moment(tick).format('DD MMM')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            tickFormatter={(value) => new Intl.NumberFormat('en').format(value)}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />

          <Tooltip
            wrapperStyle={{zIndex: 2}}
            content={args => CustomTooltip({...args, series: graph.visibleSeries})}
          />

          {graph.visibleSeries.map(g =>
            <Bar
              key={g}
              dataKey={g}
              stackId="1"
              fill={SERIES[g].color}
            />
          )}

          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3"/>
        </BarChart>
      </ResponsiveContainer>
      <Legend
        series={SERIES}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
      />
    </>
  )

}

export default StackedGeneration
