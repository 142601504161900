import React, {useContext, useEffect, useRef, useState} from "react";
import {SessionStatus} from "../constants";
import { history } from 'utils'
import jwt_decode from 'jwt-decode'
import { CognitoService, TokenStore } from 'services'
import {User} from "types";

export const SECOND                  = 1000
export const MINUTE                  = 60 * SECOND
export const INACTIVITY_PERIOD       = 0 * MINUTE // disabled
export const REFRESH_TOKEN_THRESHOLD = 10 * SECOND

type ValueOf<T> = T[keyof T];

export type ResourceError = {
  code: string;
  message: string;
  meta: { [fieldName: string]: string[] } | null;
  status: number;
  title: string;
}

export type Token = {
  auth: string;
  refresh: string;
}

export type LogInCredentials = {
  email: string;
  password: string;
}

export type ResendConfirmationParams = {email: string};
export type ForgotPasswordParams = {email: string};

export type TokenState = {
  loginState: ValueOf<typeof SessionStatus>;
  currentUser?: User;
  exp?: number;
  savedLocation: string | null;
  errors: any;
}

export type TokensResource = [
  TokenState,
  TokensActions
]
export type TokensActions = {
  create: (credentials: LogInCredentials) => Promise<void>;
  verify: (initial?: boolean) => Promise<void>;
  destroy: () => Promise<void>;
  refresh: (saveLocation?: boolean) => Promise<void>;
  forgot: (params: ForgotPasswordParams) => Promise<void>;
  reset: (params: PasswordChangeParams) => Promise<void>;
  registerActivity: () => void;
  clearSavedWindowLocation: () => void;
}
export type PasswordChangeParams = {
  email: string;
  code: string;
  password: string;
  passwordConfirmation: string;
  token: string;
}
const TokensContext = React.createContext<TokensResource | undefined>(undefined);
type Timer = ReturnType<typeof setTimeout>

export const TokensProvider = ({ children }) => {
  const tokens = useTokensResource()

  return (
    <TokensContext.Provider value={tokens}>
      {children}
    </TokensContext.Provider>
  )
}

export const useTokens = () => {
  const tokens = useContext<TokensResource | undefined>(TokensContext)
  if (!tokens) {
    throw new Error("TokensContext must be provided in order to use it")
  }

  return tokens
}

const useTokensResource = (): TokensResource => {

  const [state, setState] = useState<TokenState>({
    loginState: SessionStatus.UNKNOWN,
    savedLocation: null,
    errors: {}
  })

  const timerRef = useRef<Timer | undefined>(undefined)
  const lastActivityRef = useRef<number | undefined>();
  useEffect(() => {
    lastActivityRef.current = currentTimestamp()
  }, [])

  const registerActivity = () => {
    lastActivityRef.current = currentTimestamp()
  }

  // This ref is needed to get current value inside interval closure
  const tokenRef = useRef<TokensResource[0]>(state);
  tokenRef.current = state;

  const saveWindowLocation = () => {
    setState(prevState => ({...prevState, savedLocation: window.location.pathname}))
  }

  const clearSavedWindowLocation = () => {
    setState(prevState => ({...prevState, savedLocation: null}))
  }

  const clearTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
      timerRef.current = undefined
    }
  }

  const timedOut = () => {
    clearTimer()
    saveWindowLocation()
    history.push('/inactive', {})
  }

  const startInactivityTimeout = () => {
    clearTimer()

    timerRef.current = setInterval(() => {
      if (!tokenRef.current.exp) {
        return
      }
      const inactivityCheckTime = (tokenRef.current.exp * SECOND) - REFRESH_TOKEN_THRESHOLD
      if(currentTimestamp() > inactivityCheckTime) {
        clearTimer()
        const lastActivity = lastActivityRef?.current
        if (!lastActivity) {
          return
        }
        const inactive = INACTIVITY_PERIOD ? lastActivity < (currentTimestamp() - INACTIVITY_PERIOD) : false
        inactive ? timedOut() : verify()
      }
    }, SECOND)
  }

  const verify = async (initial?: boolean) => {
    if (initial) {
      saveWindowLocation()
    }
    try {
      const AuthenticationResult = await CognitoService.refresh()
      startInactivityTimeout()
      setState(prevState => ({...prevState, loginState: SessionStatus.AUTHENTICATED, ...decodeToken(AuthenticationResult?.IdToken)}))
    } catch(error) {
      setState(prevState => ({...prevState, loginState: SessionStatus.UNAUTHENTICATED }))
      console.warn("Not logged in")
    }
  }

  return [state, {
    registerActivity,
    verify,
    clearSavedWindowLocation,
    create: async ({email, password}) => {
      try{
        setState(prevState => {
          const {create: _, ...errorsWithoutCreate} = prevState.errors
          return {...prevState, errors: errorsWithoutCreate}
        })
        const AuthenticationResult = await CognitoService.signIn(email, password);
        registerActivity()
        startInactivityTimeout()
        setState(prevState => ({...prevState, loginState: SessionStatus.AUTHENTICATED, ...decodeToken(AuthenticationResult?.IdToken)}))
      }catch(error){
        setState(prevState => ({...prevState, loginState: SessionStatus.UNAUTHENTICATED, errors: {...prevState.errors, create: error as ResourceError}}))
        throw error
      }
    },
    destroy: async () =>{
      clearSavedWindowLocation()
      try{
        // TODO
        // await API.Tokens.destroy()
      }catch(error){
        setState(prevState => ({...prevState, errors: {...prevState.errors, destroy: error as ResourceError}}))
        throw error
      } finally {
        TokenStore.destroy()
        setState(prevState => ({...prevState, currentUser: undefined, loginState: SessionStatus.UNAUTHENTICATED }))
        history.push('/', {})
      }
    },
    refresh: async (saveLocation=true) => {
      try{
        if(saveLocation) {
          saveWindowLocation()
        }
        await CognitoService.refresh()
        registerActivity()
        startInactivityTimeout()
      } catch(error){
        setState(prevState => ({...prevState, errors: {...prevState.errors, refresh: error as ResourceError}}))
        throw error
      }
    },
    forgot: async ({email}) => {
      const errors: string[] = []
      if (!email){
        errors.push('Please provide your email')
      }

      if (errors.length){
        const message = errors.join(', ')
        setState(prevState => ({...prevState, errors: {...prevState.errors, forgot: {message} as ResourceError}}))
        throw new Error(message)
      }

      try{
        await CognitoService.forgotPassword(email)
      } catch(error){
        setState(prevState => ({...prevState, errors: {...prevState.errors, forgot: error as ResourceError}}))
        throw error
      }
    },
    reset: async ({email, code, password, passwordConfirmation}) => {
      const errors: any = []
      if (!password){
        errors.push('Please enter a password')
      }

      if (password !== passwordConfirmation){
        errors.push('Password confirmation does not match')
      }

      if (errors.length){
        const message = errors.join(', ')
        setState(prevState => ({...prevState, errors: {...prevState.errors, reset: {message} as ResourceError}}))
        throw new Error(message)
      }

      try{
        await CognitoService.confirmForgotPassword(email, code, password)
        clearSavedWindowLocation()
      } catch(error: any){
        if (error.message?.includes('must satisfy regular expression pattern')){
          error.message = 'Password does not meet the requirements'
        }
        setState(prevState => ({...prevState, errors: {...prevState.errors, reset: error as ResourceError}}))
        throw error
      }
    },
  }]
}

const decodeToken = token => {
  const { exp, 'cognito:groups': groups, 'cognito:username': username, email } = jwt_decode(token)
  return { exp, currentUser: { username, email, groups } }
}

const currentTimestamp = () => {
  return + new Date()
}

export default TokensContext