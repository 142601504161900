/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { RCPD_YEARS } from 'constants'

const YearFilter = ({filter, onChange}) => {
  const {year} = filter

  return (
    <div css={styles.container}>
      <FormControl variant="standard" fullWidth>
          <InputLabel id="year">Year</InputLabel>
          <Select
            labelId="year"
            value={year || ''}
            label="Year"
            onChange={({target: {value: year}}) => onChange({...filter, year})}
          >
            {RCPD_YEARS.map(year => <MenuItem key={year} value={year}>{year}</MenuItem>)}
          </Select>
        </FormControl>
    </div>
  )

}

const styles = {
  container: {
    display: 'flex',
  }
}

export default YearFilter