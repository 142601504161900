/** @jsxImportSource @emotion/react */
import React from 'react'
import { NodePicker } from 'components'

const NodeFilter = ({filter, onChange, nodeTypes=[]}) => {
  const {nodes} = filter

  const handleNodesChange = event => {
    onChange({...filter, nodes: event.target.value.map(({id}) => id)})
  }

  return (
    <div css={styles.container}>
      <NodePicker
        value={(nodes || []).map(id => ({id}))}
        nodeTypes={nodeTypes}
        onChange={ handleNodesChange }
        multiple
        fullWidth
      />
    </div>
  )

}

const styles = {
  container: {
    display: 'flex',
  }
}

export default NodeFilter