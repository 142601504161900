/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import Card from '@mui/material/Card';
import { StylesObject } from 'types';

export type CenteredCardProps = {
  centerVertically: boolean;
  children?: React.ReactNode;
}
export class CenteredCard extends Component<CenteredCardProps>{

  static defaultProps = {
    centerVertically: true
  }

  render = () =>
    <section css={[styles.wrapper, this.props.centerVertically && styles.centerVertically, !this.props.centerVertically && styles.topMargin]}>
      <Card css={styles.card}>
        {this.props.children}
      </Card>
    </section>

}

const styles = {
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
    margin: '0 auto',
    alignSelf: 'flex-start'
  },
  centerVertically: {
    alignItems: 'center',
    minHeight: '100vh',
    flex: 1
  },
  topMargin: {
    marginTop: 20,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 350px'
  }
} as StylesObject

export default CenteredCard