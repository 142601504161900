/** @jsxImportSource @emotion/react */
import React, {Component} from 'react'
import { StylesObject } from 'types'

export type LinkButtonProps = {
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
} & React.HTMLProps<HTMLButtonElement>
export class LinkButton extends Component<LinkButtonProps> {
  render = () => {
    const {disabled, type, ...props} = this.props
    return (
      <button type={type || 'button'} {...props} css={styles.root}/>
    )
  }
}

const styles = {
  root: theme => ({
    border: 'none',
    background: 'transparent',
    color: theme.palette.primary.main,
    display: 'inline',
    appearance: 'none',
    textAlign: 'left',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:focus': {
      border: 'none',
      background: 'transparent',
      outline: 'none',
    }
  }),
  disabled: {
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'auto',
  }
} as StylesObject

export default LinkButton