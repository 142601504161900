/** @jsxImportSource @emotion/react */
import React from 'react'
import Grid from '@mui/material/Grid'
import { PageContainer } from 'components'
import { StylesObject } from "types"
import { Widget } from '../../widgets'

const Generation = () => {

  return (
    <PageContainer>
      <Grid container spacing={2}>

        <Widget id="generation-capacity"/>

        <Widget id="wind-forecast-graph"/>

        <Widget id="generation-graph"/>

        <Widget id="stacked-generation-graph"/>

      </Grid>
    </PageContainer>
  );
}

const styles = {

} as StylesObject

export default Generation