/** @jsxImportSource @emotion/react */
import React, {useMemo} from 'react'
import moment from 'moment'
import { formatLocaleDateTime, timeForInterval, round } from 'utils'
import {
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ReferenceArea,
} from 'recharts'
import _ from 'lodash'

import { TooltipList, TooltipListItem, TooltipHeader, formatTimestamp } from './Tooltip'
import Legend from './Legend'
import { useSeries } from 'hooks'

const ACTUAL_MAX_HOURS_AGO = 18

const SERIES = {
  'actual': {
    label: 'Actual load',
    color: '#B1E9E6',
  },
  'nrsl0': {
    label: 'NRSL Most recent',
    color: '#FFD52D',
    strokeDasharray: '5 4',
  },
  'nrsl2': {
    label: 'NRSL +2 hours',
    color: '#CBE612',
    strokeDasharray: '5 4',
  },
  'nrsl4': {
    label: 'NRSL +4 hours',
    color: '#00A976',
    strokeDasharray: '5 4',
  },
  'nrsl6': {
    label: 'NRSL +6 hours',
    color: '#00A9A5',
    strokeDasharray: '5 4',
  },
  'nrsl8': {
    label: 'NRSL +8 hours',
    color: '#0076A9',
    strokeDasharray: '5 4',
  },
}

const SERIES_OPTIONS = {
  xTicks: {every: 'hour', add: 4}
}

const CustomTooltip = ({ active, series, payload }: any) => {
  if (active && payload?.length && series?.length){
    const {timestamp, tradingPeriod, ...data} = payload[0].payload ?? {};
    return (
      <TooltipList>
        <TooltipHeader label={formatTimestamp(timestamp, {tradingPeriod})}/>
        { series.map((g, idx) => {
            const {label, color, strokeDasharray} = SERIES[g]
            const runTime = g.includes('nrsl') ? data[`runTime${_.last(g)}`] : null
            return (
              <TooltipListItem
                key={idx}
                label={label}
                color={color}
                strokeDasharray={strokeDasharray}
                value={`${round(data[g])} MW`}
                secondaryLabel={runTime && 'Run Time'}
                secondaryValue={runTime && formatLocaleDateTime(runTime)}
              />
          )})
        }
      </TooltipList>
    )
  }
}


const NodePredictedLoad = ({recentData, actualData, forecastData, width='100%', height='100%', zoomCallback, currentTime}) => {

  const actualCutOff = moment().subtract(ACTUAL_MAX_HOURS_AGO, 'hours').valueOf()

  const data = useMemo(() => {
    // historic/actual data
    let actual = (actualData?.items || []).reduce((acc, {tradingDate, ...rest}) => ([
      // annoyingly the data is returned as an item per trading date and one attribute per 48 trading intervals eg tp1Mw, tp2Mw, ..., tp48Mw
      ...Object.keys(rest).filter(key => key.includes('tp') && rest[key] !== null).map(key => {
          // we calculate the timestamp by combining trading date & trading period
          const tp = parseInt(key.replace(/[^0-9]/g, ''))
          const timestamp = timeForInterval(tp, moment(tradingDate, 'DD/MM/YYYY')).valueOf()
          return {timestamp, actual: rest[key], tradingPeriod: tp}
        })
      ]), [])

    // limit to 18 hours in the past
    actual = actual.filter(({timestamp}) => timestamp >= actualCutOff)
    // current/most recent data not present in actualData
    const recent = (recentData?.items || []).map(({tradingDate, scadaMwCurrent, tradingPeriod}) => ({
      // a timestamp is returned but its not the trading interval time so we combine trading date & period again
      timestamp: timeForInterval(tradingPeriod, moment(tradingDate, 'DD/MM/YYYY')).valueOf(),
      tradingPeriod,
      actual: scadaMwCurrent
    }))
    // forecast data is grouped by runTime, there should be up to 5 runTimes (NRSL +0, +2, +4, +6, +8)
    const forecast = (forecastData?.items || []).reduce((acc, {runTime, forecasts}, idx) => ([
        ...(forecasts || []).map(({timestamp, tradingperiod, loadMw, runtype}) => ({ [`runTime${idx*2}`]: runTime, timestamp: moment(timestamp).valueOf(), [`nrsl${idx*2}`]: loadMw, tradingPeriod: tradingperiod})),
        ...acc,
      ]), [])
    // recharts expects points to have a unique timestamp but each forecast run will introduce duplicate timestamps
    const dataByTimestamp = _.groupBy([...actual, ...recent, ...forecast], 'timestamp')
    // we merge all forecast data into unque timestamps
    return Object.keys(dataByTimestamp).sort().map(timestamp => dataByTimestamp[timestamp].reduce((acc, curr) => ({...acc, ...curr}), {}))

  }, [JSON.stringify(actualData), JSON.stringify(forecastData)])

  const [graph, actions] = useSeries(SERIES, data, {...SERIES_OPTIONS, zoomCallback})

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('HH:mm')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />

          <Tooltip
            content={args => CustomTooltip({...args, series: graph.activeSeries})}
          />

          <defs>
            <linearGradient id="currentTimeGradient" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#B1E9E6" stopOpacity={0} />
              <stop offset="100%" stopColor="#B1E9E6" stopOpacity={0.18} />
            </linearGradient>
          </defs>

          <ReferenceArea x1={graph.data[0]?.timestamp} x2={currentTime.valueOf()} fill="url(#currentTimeGradient)" />
          <ReferenceLine x={currentTime.valueOf()} stroke="#fff"/>

          {graph.visibleSeries.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={SERIES[g].color}
              strokeDasharray={SERIES[g].strokeDasharray}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={actions.isSeriesActive(g)}
            />
          )}

          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3"/>
        </ComposedChart>
      </ResponsiveContainer>
      <Legend
        series={SERIES}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
      />
    </>
  )

}

export default NodePredictedLoad
