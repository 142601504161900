import React from 'react'
import {useHistory} from 'react-router-dom'
import StatusPage from 'containers/status/StatusPage'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {useSnackbar} from "contexts/SnackbarContext";
import {useTokens} from "contexts/TokensContext";

const Inactive = () =>
  <StatusPage>
    <Typography variant='h6'>User session inactive</Typography>
    <Typography variant='body1'>Your session has expired.</Typography>
  </StatusPage>

export default Inactive