//
// AWS Cognito Auth Service
//
import { CognitoIdentityProviderClient, InitiateAuthCommand, ForgotPasswordCommand, ConfirmForgotPasswordCommand } from "@aws-sdk/client-cognito-identity-provider";
import { TokenStore } from 'services'

const config = {
  region:     process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  clientId:   process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
}

export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
});

export const signIn = async (username, password) => {
  const params = {
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);
    if (AuthenticationResult) {
      TokenStore.auth = AuthenticationResult.IdToken || ''
      TokenStore.access = AuthenticationResult.AccessToken || ''
      TokenStore.refresh = AuthenticationResult.RefreshToken || ''

      return AuthenticationResult;
    }
  } catch (error) {
    console.error("Error signing in: ", error);
    throw error;
  }
};

export const refresh = async () => {
  const params = {
    AuthFlow: "REFRESH_TOKEN_AUTH",
    ClientId: config.clientId,
    AuthParameters: {
      REFRESH_TOKEN: TokenStore.refresh,
    },
  };
  try {
    const command = new InitiateAuthCommand(params);
    const { AuthenticationResult } = await cognitoClient.send(command);

    if (AuthenticationResult) {
      // Cognito doesn't rotate the refresh token
      TokenStore.auth = AuthenticationResult.IdToken || ''
      TokenStore.access = AuthenticationResult.AccessToken || ''

      return AuthenticationResult;
    }
  } catch (error) {
    console.error("Error refreshing token: ", error);
    throw error;
  }
};

// sends out a password reset link with a code by email
export const forgotPassword = async (username) => {
  const params = {
    ClientId: config.clientId,
    Username: username,
  }
  try {
    const command = new ForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    return response
  } catch (error) {
    console.error("Error forgot password", error);
    throw error;
  }
};

// reset password using token sent by email
export const confirmForgotPassword = async (username, code, password) => {
  const params = {
    ClientId: config.clientId,
    ConfirmationCode: code,
    Password: password,
    Username: username,
  }
  try {
    const command = new ConfirmForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    return response
  } catch (error) {
    console.error("Error confirm forgot password", error);
    throw error;
  }
};

