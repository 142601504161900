
// To calculate if labels on a NZ map should be positioned left or right of the map
// latitude = x, longitude = y
export function positionNzMiddle(x, y){
  // we divide NZ in two halves by imagining a straight line defined by two points
  // from top/right (around Whakatane) to bottom/left (around Invercargill)
  const top    = {x: -34.85571, y: 179.34077}
  const bottom = {x: -47.87691, y: 165.60786}

  // y = mx + c
  const m = (bottom.y - top.y) / (bottom.x - top.x)
  const c = top.y - m * top.x

  // find x on our imaginary line for the given y
  const xIntersect = (y - c) / m

  // left or right of the line
  return {left: x > xIntersect, distance: Math.abs(x - xIntersect)}
}