import React, { useState } from 'react'

//
// defines a set of event handlers enabling a React Simple Maps zoom
// ZoomableGroup doesn't resize map markers according to zoom levels
// passing a scale to ComposableMap seems to be the easiest workaraound for complex markers
//

const useZoom = ({coordinates: initialCoordinates, zoom: initialZoom=1, zoomCallback}) => {

  const [position, setPosition] = useState({ coordinates: initialCoordinates, zoom: initialZoom})

  const zoomOut = () => {
    setPosition({coordinates: initialCoordinates, zoom: initialZoom})
    zoomCallback && zoomCallback({zoomOut, zoomed: false})
  }

  const handleMoveEnd = ({coordinates, zoom}) => {
    const zoomingOut = zoom > 1 && position.zoom > 1
    const updatedZoom = zoom === 1 ? position.zoom : (zoomingOut ? 1 : 2)
    const updatedCoordinates = zoomingOut ? initialCoordinates : coordinates

    setPosition({coordinates: updatedCoordinates, zoom: updatedZoom})
    zoomCallback && zoomCallback({zoomOut, zoomed: updatedZoom > 1})
  }

  // disable scroll wheel zoom
  // simplified zoom by double click to toggle
  const filterZoomEvent = ({ constructor: { name } }) => {
    return name !== 'WheelEvent'
  }

  return {
    position,
    handleMoveEnd,
    zoomableGroupProps: {
      center: position.coordinates,
      zoom: 1,
      filterZoomEvent,
      onMoveStart: () => {},
      onMoveEnd: handleMoveEnd,
      minZoom: 1,
      // prevents zoom animation when actually about to toggle zoom out
      maxZoom: position.zoom > 1 ? 1.0001 : 2,
    },
  }
}

export default useZoom