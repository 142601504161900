import qs from 'qs'
import moment from 'moment'

export function filterQueryString(filter){
  const objID = obj => (typeof obj === 'object') ? obj.id : obj

  filter && Object.keys(filter).forEach(key => {
    const value = filter[key]
    // API expects trading dates in non standard format
    if (key.includes('trading_date')){
      filter[key] = value && moment(value).format('DD/MM/YYYY')
    }
    // API exects comma seperated list of values
    if (Array.isArray(value)){
      filter[key] = value.map(objID)
    }
  })

  const { nodes, ...params } = filter
  // API expects nodes before querystring
  const nodesString = nodes?.length ? `/${nodes.join(',')}` : ''
  // API expects empty array as param=
  const queryString = qs.stringify(params, { allowEmptyArrays: true, arrayFormat: 'comma', encode: false })
                        .replace('[]', '=')

  return `${nodesString}?${queryString}`
}

export const downloadBlob = ({blob}, filename) => {
  const objectUrl = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.style.display = 'none'
  document.body.appendChild(anchor)

  anchor.href = objectUrl
  anchor.download = filename
  anchor.click()

  window.URL.revokeObjectURL(objectUrl)
  document.body.removeChild(anchor)
}