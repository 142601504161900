/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource, useFilter} from 'hooks'
import { Widgets } from './'
import moment from 'moment'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import {useSavedFilters} from 'contexts/SavedFiltersContext'

const NODES = [
  {id: 'BPE0331', label: 'BPE0331'},
  {id: 'CPK0331', label: 'CPK0331'},
  {id: 'EDG0331', label: 'EDG0331'},
  {id: 'GYM0661', label: 'GYM0661'},
  {id: 'HAM0331', label: 'HAM0331'},
  {id: 'HWB0331', label: 'HWB0331'},
  {id: 'INV0331', label: 'INV0331'},
  {id: 'ISL0661', label: 'ISL0661'},
  {id: 'MPE1101', label: 'MPE1101'},
  {id: 'OAM0331', label: 'OAM0331'},
  {id: 'PEN0331', label: 'PEN0331'},
  {id: 'RDF0331', label: 'RDF0331'},
  {id: 'SFD0331', label: 'SFD0331'},
  {id: 'STK0331', label: 'STK0331'},
  {id: 'TUI1101', label: 'TUI1101'},
]

const DEFAULT_FILTER = {
  node: 'BPE0331'
}

const NodePredictedLoadGraph = (props) => {
  const widget = Widgets['node-predicted-load-graph']
  const [filter, setFilter] = useFilter(null, 'predictedload-n')
  const savedFilters = useSavedFilters()
  const [load, loadActions] = useResource('load')
  const [currentTime, setCurrentTime] = useState(moment())

  const updateWidget = () => {
    const {node} = filter
    const from_trading_date = moment().subtract(1, 'days')
    const to_trading_date = moment().add(2, 'days')

    setCurrentTime(moment().startOf('minute'))

    return node && Promise.all([
      loadActions.request('scada', {nodes: [node], from_trading_date, to_trading_date}),
      loadActions.request('recent', {nodes: [node]}),
      loadActions.request('prediction', {from_trading_date, to_trading_date, run_type: 'L', node_id: node}),
    ])
  }

  const [loading, result, reload] = useDependency(updateWidget, [filter])
  const updatedAt = load.scada?.responseAt

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({...initialFilter, ...filter})
  }, [])

  return (
    <WidgetContainer
      widget={widget}
      title="Predicted Load By Node (MW)"
      icon={<ScatterPlotIcon/>}
      lastUpdatedAt={updatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={loading}
      onWidgetRefresh={reload}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      renderFilter={() => <Graphs.NodeFilter filter={filter} onChange={setFilter} nodes={NODES} />}
      onFilterSave={() => savedFilters.update(widget, filter)}
      zoom={zoom}
      {...props}
    >
      <Graphs.NodePredictedLoad
        recentData={loading ? null : load.recent?.data}
        actualData={loading ? null : load.scada?.data}
        forecastData={loading ? null : load.prediction?.data}
        zoomCallback={zoomCallback}
        currentTime={currentTime}
      />
    </WidgetContainer>
  )
}

export default NodePredictedLoadGraph
