/** @jsxImportSource @emotion/react */
import React from 'react'
import StatusPage from './StatusPage'
import {useHistory} from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const ForgotPassword = () => {
  const history = useHistory()

  return (
    <StatusPage>
      <Typography variant='h6'>Forgot Password</Typography>
      <br/>
      <Typography variant='body2'>We've sent a link to your email address explaining how to reset your password.</Typography>
      <br/>
      <Typography variant='body1'>
        If you do not receive a link please try again or contact us at <a href="mailto:call@ems.co.nz">call@ems.co.nz</a>
      </Typography>
      <br/>
      <Button fullWidth variant='contained' onClick={() => history.push('/')} css={{ marginLeft: 0 }}>
        Go Back
      </Button>
    </StatusPage>
  )
}

export default ForgotPassword