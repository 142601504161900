import React, {useContext, useState} from "react"
import {useSnackbar} from 'contexts/SnackbarContext'

//
// Persist filter state into localstorage
//

const LOCAL_STORAGE_KEY = 'filters'

const SavedFiltersContext = React.createContext(undefined)

export const SavedFiltersProvider = ({ children }) => {

  const snackbar = useSnackbar()
  const initialStateDefault = {}
  let initialState

  try{
    initialState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || initialStateDefault
  } catch(e){
    initialState = initialStateDefault
  }

  const [filters, setFilters] = useState(initialState)

  const updateFilters = filters => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(filters))
    setFilters(filters)
  }

  const value = {
    filters,
    update: (widget, filter) => {
      updateFilters({...filters, [widget.id]: filter})
      snackbar.show(`Saved default filters for ${widget.name}`)
    },
    load: (widget) => filters[widget.id],
  }
  return (
    <SavedFiltersContext.Provider value={value}>
      {children}
    </SavedFiltersContext.Provider>
  )
}

export const useSavedFilters = () => {
  const filters = useContext(SavedFiltersContext)
  if (!filters) {
    console.warn("SavedFiltersContext must be provided in order to use it")
    return {}
  }

  return filters
}