import moment from 'moment'

// A new Cognito token takes a few seconds to be accepted by the API..
// We delay any requests after a new auth token has been set
const DELAY_REQUEST_AFTER_TOKEN_REFRESH = 6 // seconds

class TokenStore{
  set auth(value){
    this._valid_from = moment().add(DELAY_REQUEST_AFTER_TOKEN_REFRESH, 'seconds')
    global.localStorage.auth = value || ''
  }

  get auth(){
    return global.localStorage.auth
  }

  set access(value){
    global.localStorage.access = value || ''
  }

  get access(){
    return global.localStorage.access
  }

  set refresh(value){
    global.localStorage.refresh = value || ''
  }

  get refresh(){
    return global.localStorage.refresh
  }

  destroy = () => {
    global.localStorage.removeItem('auth')
    global.localStorage.removeItem('refresh')
  }

  protected = endpoint => {
    endpoint.headers(headers => {

      if(this.auth) {
        headers.Authorization = `Bearer ${this.auth}`
      }

      const now = moment()
      const delayMs = this._valid_from && (now < this._valid_from) ? this._valid_from.diff(now) : 0
      endpoint.delay(delayMs)

      return headers
    })

    return endpoint
  }
}

const TokenStoreSingleton = new TokenStore()
const ProtectResourceDecorator = TokenStoreSingleton.protected.bind(TokenStoreSingleton)
export { TokenStoreSingleton as TokenStore, ProtectResourceDecorator }
