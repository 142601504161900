import React, { Component } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import UndoIcon from '@mui/icons-material/Undo'
import PropTypes from 'prop-types'

export type DestroyButtonProps = {
  value?: string;
  onChange?: (event: {target: {value: boolean}}) => void;
  onDestroy?: (destroy: boolean) => void;
}

export class DestroyButton extends Component<DestroyButtonProps> {
  static displayName: string = 'DestroyButton';
  static propTypes: any;

  static defaultProps = {
    onDestroy: () => {},
    value: undefined
  }

  handleDestroy = () => {
    const destroy = !this.props.value
    this.props.onChange && this.props.onChange({target: {value: destroy}})
    this.props.onDestroy && this.props.onDestroy(destroy)
  }

  render = () => {
    return (
      <IconButton
        onClick={this.handleDestroy}
        color="primary"
        aria-label="Remove"
        size="large">
        {
          !!this.props.value ? <Tooltip title='Restore'><UndoIcon/></Tooltip> : <Tooltip title='Delete'><DeleteIcon/></Tooltip>
        }
      </IconButton>
    );
  }
}


DestroyButton.propTypes = {
    /** This comment will be visible in the Storybook PropTypes table */
    value: PropTypes.string,
    /** This comment will be visible in the Storybook PropTypes table */
    onChange: PropTypes.func,
     /** This comment will be visible in the Storybook PropTypes table */
    onDestroy: PropTypes.func
};

export default DestroyButton