import React, {useContext, useState} from "react"
import { Widgets } from '../widgets'

//
// In this proof of concept the favourites are added to localStorage
//

const LOCAL_STORAGE_KEY = 'favourites'

const FavouritesContext = React.createContext(undefined)

export const FavouritesProvider = ({ children }) => {
  
  const initialStateDefault = { widgets: [] }
  let initialState

  try{
    initialState = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || initialStateDefault
  } catch(e){
    initialState = initialStateDefault
  }

  const [favourites, setFavourites] = useState(initialState)
  // filter out any old/invalid data
  const widgets = favourites.widgets.filter(widget => widget?.id && Widgets[widget.id])

  const updateFavourites = favourites => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(favourites))
    setFavourites(favourites)
  }

  const widgetExists = widget => !!widgets.find(current => current.id === widget.id)

  const value = {
    favourites,
    widgets,
    setFavourites: updateFavourites,
    addWidget: ({component, ...widget}) => {
      if (!widgetExists(widget)){
        const updated = {...favourites, widgets: [...widgets, widget]}
        updateFavourites(updated)
      }
    },
    removeWidget: ({component, ...widget}) => {
      const updated = {...favourites, widgets: widgets.filter(current => current.id !== widget.id)}
      updateFavourites(updated)
    },
    moveWidgetLeft: (widget) => {
      const updated = [...widgets]
      const idx = widgets.findIndex(({id}) => widget.id === id)
      if (idx >= 0){
        const left = (idx-1) >= 0 ? idx-1 : widgets.length-1
        updated[left] = widgets[idx]
        updated[idx]  = widgets[left]
        updateFavourites({...favourites, widgets: updated})
      }
    },
    moveWidgetRight: (widget) => {
      const updated = [...widgets]
      const idx = widgets.findIndex(({id}) => widget.id === id)
      if (idx >= 0){
        const right = (idx+1) < widgets.length ? idx+1 : 0
        updated[right] = widgets[idx]
        updated[idx]  = widgets[right]
        updateFavourites({...favourites, widgets: updated})
      }
    }
  }
  return (
    <FavouritesContext.Provider value={value}>
      {children}
    </FavouritesContext.Provider>
  )
}

export const useFavourites = () => {
  const favourites = useContext(FavouritesContext)
  if (!favourites) {
    console.warn("FavouritesContext must be provided in order to use it")
    return {}
  }

  return favourites
}