import React, { useMemo, useState } from "react";
import useRequest from "./useRequest";
import moment from 'moment'

const useResource = (resourceName, additionalInitialState) => {

  const initialStateDefault = {
    requests: {},
    errors: {},
    count: 0,
  }

  const initialState = { ...additionalInitialState, ...initialStateDefault }
  const [state, setState] = useState(initialState)
  const performRequest = useRequest()

  const actions = useMemo(() => ({
    setState,
    request: async (apiEndpoint, params = {}, opts={}) => {
      const response = await performRequest(setState, resourceName, apiEndpoint, params)
      const att = opts.as || apiEndpoint
      setState(prevState => ({ ...prevState, [att]: {...response, responseAt: moment()} }))
      return response
    },
  }), [resourceName, performRequest])

  return [state, actions]
}

export default useResource