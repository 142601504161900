import { useEffect } from 'react'
import ReactGA from "react-ga4"
import { useLocation } from 'react-router-dom'
import { useFavourites } from 'contexts/FavouritesContext'
import { useAuthorization } from 'hooks'

const analyticsId = process.env.REACT_APP_GA_ID
if (analyticsId){
  ReactGA.initialize(
    analyticsId,
  )
}

const GoodleAnalytics = () => {
  const location = useLocation()
  const { widgets } = useFavourites()
  const { user } = useAuthorization()
  const gaEnabled = ReactGA.isInitialized

  // log pageviews
  useEffect(() => {
    if (gaEnabled){
      ReactGA.send({ hitType: 'pageview', page: location.pathname })
    }
  }, [location.pathname])

  // log favourites
  useEffect(() => {
    if (gaEnabled){
      ReactGA.set({ favourites: {widgets} })
      ReactGA.event({
        category: "favourites",
        action: "favourite widgets update",
        label: widgets.map(({id}) => id).join(', '),
        nonInteraction: true,
      })
    }
  }, [widgets])

  // log user
  useEffect(() => {
    if (gaEnabled && user?.email){
      ReactGA.set({ user, userId: user.email })
      ReactGA.event({
        category: "session",
        action: "user login",
        label: user.email,
        nonInteraction: true,
      })
    }
  }, [user])

  return null
}

export default GoodleAnalytics