/** @jsxImportSource @emotion/react */
import React from 'react'
import { DatePicker as DPicker, DatePickerProps as DPickerProps, PickersActionBarAction } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';

export type DatePickerProps = Omit<DPickerProps<Moment>, "value" | "onChange" | "slotProps"> & {
  onChange?: (event: { target: { value?: string } }) => void;
  value?: number | string | Date;
  name?: string;
  className?: string;
  clearable?: boolean;
  fullWidth?: boolean;
  adornmentPosition?: 'end' | 'start'

};

export const DatePicker = ({ name, value, onChange, className, clearable, fullWidth, adornmentPosition='start', ...rest }: DatePickerProps) => {

  const handleChange = date => {
    onChange && onChange({
      target: {
        value: date ? date.format('YYYY-MM-DD') : date
      }
    })
  }

  let actions = [] as PickersActionBarAction[]
  if(clearable) {
    actions.push('clear')
  }

  return <DPicker
    format="DD/MM/YYYY"
    {...rest}
    
    onChange={handleChange}
    value={value ? moment(value) : null}
    slotProps={{
      inputAdornment: {
        position: adornmentPosition
      },
      textField: {
        className,
        fullWidth,
        sx: {'& .MuiInputAdornment-root': { paddingRight: '4px' }}
      },
      actionBar: {
        actions
      },
      field: {
        clearable: true
      }
    }}
  />

}

export default DatePicker