import React, {useState} from 'react'
import { ControlledForm, CenteredCard, ErrorBanner, PromiseButton } from 'components'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography'
import {useHistory} from 'react-router-dom'
import {PasswordChangeParams, useTokens} from "contexts/TokensContext";
import {useSnackbar} from "contexts/SnackbarContext";
import { useLocation } from "react-router-dom"
import qs from 'qs'

const Reset = () => {
  const [tokens, actions] = useTokens()
  const location = useLocation()
  const history = useHistory()
  const snackbar = useSnackbar()
  const [formData, setFormData] = useState<Partial<PasswordChangeParams>>({})

  const params = qs.parse(location.search, {ignoreQueryPrefix: true})

  const submit = async () => {
    await actions.reset({...formData, ...params} as PasswordChangeParams)
    snackbar.show("Your password has been reset, please sign in")
    history.push('/tokens/sign_in')
  }

  const errors = tokens.errors?.reset

  return (
    <CenteredCard centerVertically={false}>
      <ControlledForm data={formData} errors={errors} onChange={setFormData} onSubmit={submit}>
        <CardContent>
          <Typography variant='h6'>Reset your password</Typography>
          {tokens.errors.reset && <ErrorBanner>{tokens.errors.reset?.message}</ErrorBanner>}
          <FormGroup>
            <TextField fullWidth name='password'             type='password' autoFocus/>
            <TextField fullWidth name='passwordConfirmation' type='password' label='Password'/>
          </FormGroup>
        </CardContent>
        <CardActions>
          <PromiseButton type='submit' variant='contained' fullWidth>Submit</PromiseButton>
        </CardActions>
      </ControlledForm>
    </CenteredCard>
  )
}

export default Reset
