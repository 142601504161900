/** @jsxImportSource @emotion/react */
import React, {Component, Fragment, MouseEventHandler} from 'react'
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab'
import { PropTypes } from "@mui/material";
import { StylesObject } from 'types';

export type FABFixedProps = {
  color: PropTypes.Color;
  onClick?: MouseEventHandler;
  children: NonNullable<React.ReactNode>;
}
export class FABFixed extends Component<FABFixedProps>{

  render = () =>
    <Fragment>
      <Zoom
        in={true}
        timeout={300}
        css={{transitionDelay: '500'}}
        unmountOnExit
      >
        <Fab color={this.props.color ?? 'primary'} css={styles.fab} onClick={this.props.onClick}>
          {this.props.children}
        </Fab>
      </Zoom>
      <div css={styles.buttonPadding}/>
    </Fragment>
}

const styles =  {
  fab: theme => ({
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1
  }),
  buttonPadding: {
    height: 50
  }
} as StylesObject

export default FABFixed