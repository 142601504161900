/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { DatePicker } from 'components'
import Grid from '@mui/material/Grid'

export const ARC_TYPES = [
  {id: '',  label: 'Latest'},
  {id: 'V', label: 'Provisional'},
  {id: 'T', label: 'Interim'},
  {id: 'F', label: 'Final'},
]

const ArcFilter = ({filter, onChange}) => {

  const handleChange = att => event => {
    onChange({...filter, [att]: event.target.value})
  }

  const { arcType, from } = filter

  return (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <FormControl variant="standard" fullWidth>
          <InputLabel shrink id="arc-type">Type</InputLabel>
          <Select
            labelId="arc-type"
            value={arcType || ''}
            label="Type"
            onChange={ handleChange('arcType') }
            displayEmpty
          >
            {ARC_TYPES.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DatePicker
          label="Trading Date"
          value={from}
          onChange={handleChange('from')}
          fullWidth
        />
      </Grid>
    </>
  )

}

const styles = {
}

export default ArcFilter