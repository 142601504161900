/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource} from 'hooks'
import { Widgets } from './'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import WindPowerIcon from '@mui/icons-material/WindPower'

const WindForecastGraph = (props) => {
  const widget = Widgets['wind-forecast-graph']
  const [wind, windActions] = useResource('wind')
  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  const updateWidget = () => {
    return Promise.all([
      windActions.request('generation'),
      windActions.request('forecast'),
    ])
  }

  const [loading, result, reload] = useDependency(updateWidget, [])
  const lastUpdatedAt = wind.forecast?.responseAt
  const tooltips = useState(false)

  return (
    <WidgetContainer
      widget={widget}
      title="North Island Wind Generation Forecast (MW)"
      icon={<WindPowerIcon/>}
      lastUpdatedAt={lastUpdatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={loading}
      onWidgetRefresh={reload}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      zoom={zoom}
      tooltips={tooltips}
      {...props}
    >
      <Graphs.WindForecast
        generationData={wind.generation?.data}
        forecastData={wind.forecast?.data}
        zoomCallback={zoomCallback}
        tooltips={tooltips}
      />
    </WidgetContainer>
);
}

const styles = {}

export default WindForecastGraph