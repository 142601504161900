/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { DatePicker, GenerationTypePicker } from 'components'
import Grid from '@mui/material/Grid'

export const EMISSION_TYPES = [
  {id: 'carbon', label: 'NZ'},
  {id: 'generation', label: 'Generation'},
]

const EmissionsFilter = ({filter, onChange}) => {

  const handleChange = att => event => {
    onChange({...filter, [att]: event.target.value})
  }

  const { emissionType, generationTypes, from, to } = filter

  return (
    <>
      <Grid item xs={12} md={6}>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="emission-type">Type</InputLabel>
          <Select
            labelId="emission-type"
            value={emissionType || ''}
            label="Type"
            onChange={ handleChange('emissionType') }
          >
            {EMISSION_TYPES.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
      </Grid>
      { emissionType === 'generation' &&
        <Grid item xs={12}>
          <GenerationTypePicker
            value={generationTypes}
            onChange={handleChange('generationTypes')}
            multiple
            fullWidth
          />
        </Grid>}
      <Grid item xs={12} md={6}>
        <DatePicker
          label="From"
          value={from}
          onChange={handleChange('from')}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          label="To"
          value={to}
          onChange={handleChange('to')}
          fullWidth
        />
      </Grid>
    </>
  )

}

const styles = {
}

export default EmissionsFilter