/** @jsxImportSource @emotion/react */
import React, {useState} from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import {WidgetContainer, Maps} from 'components'
import {useDependency, useResource} from 'hooks'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import { Widgets } from './'
import _ from 'lodash'

const RegionalPriceMap = (props) => {
  const widget = Widgets['regional-price-map']
  const [price, priceActions] = useResource('price')
  const [priceLoading, result, reloadPrices] = useDependency(() => priceActions.request('region'))
  const mapUpdatedAt = _.last(price.region?.data?.items || [])?.timestamp

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  return (
    <WidgetContainer
      widget={widget}
      title="Regional Price Overview ($/MWh)"
      icon={<LocationOnIcon/>}
      lastUpdatedAt={mapUpdatedAt}
      loading={priceLoading}
      onWidgetRefresh={reloadPrices}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      zoom={zoom}
      {...props}
    >
      <Maps.RegionalPrice
        data={price.region?.data}
        zoomCallback={zoomCallback}
      />
    </WidgetContainer>
);
}

const styles = {}

export default RegionalPriceMap