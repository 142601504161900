/** @jsxImportSource @emotion/react */
import React, {useMemo} from 'react'
import moment from 'moment'
import { round } from 'utils'
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
} from 'recharts';
import { TooltipList, TooltipListItem, TooltipHeader, formatTimestamp } from './Tooltip'
import Legend from './Legend'
import { useSeries } from 'hooks'

const SERIES = {
  'Battery': {
    label: 'Battery',
    color: '#6388d7',
  },
  'Co-Gen': {
    label: 'Co-Gen',
    color: '#00a9a5',
  },
  'Coal': {
    label: 'Coal',
    color: '#ED8B00',
  },
  'Gas': {
    label: 'Gas',
    color: '#C6C6C6',
  },
  'Geothermal': {
    label: 'Geothermal',
    color: '#CBE612',
  },
  'Hydro': {
    label: 'Hydro',
    color: '#8552AF',
  },
  'Wind': {
    label: 'Wind',
    color: '#B1E9E6',
  },
  'Liquid': {
    label: 'Diesel/Oil',
    color: '#CF0059',
  },
  'Solar': {
    label: 'Solar',
    color: '#FFD52D',
  }
}

const SERIES_OPTIONS = {
  xTicks: {every: 'hour', add: 3},
  tooltipTreshold: 0.1
}

const CustomTooltip = ({ active, series, payload }: any) => {
  if (active && payload?.length && series?.length){
    const {timestamp, tradingPeriod, ...data} = payload[0].payload ?? {};
    return (
      <TooltipList>
        <TooltipHeader label={formatTimestamp(timestamp, {tradingPeriod})}/>
        { series.map((g, idx) => {
            const {label, color} = SERIES[g]
            return (
              <TooltipListItem
                key={idx}
                label={label}
                color={color}
                value={`${round(data[g])} MW`}
                secondaryLabel="Capacity"
                secondaryValue={`${round(data[`${g}Capacity`])} MW`}
              />
          )})
        }
      </TooltipList>
    )
  }
}


const Generation = ({data, width='100%', height='100%', zoomCallback}) => {

  // reformat the API data to something we can chart
  const generationData = useMemo(() => (data?.items || []).map(({generationType, timestamp, ...rest}) => ({
    ...(generationType || []).reduce((acc, curr) => ({
      ...acc,
      [curr.generationTypeName]: curr.generationMw,
      [`${curr.generationTypeName}Capacity`]: curr.generationCapacityMw,
    }), {}),
    timestamp,
    ...rest,
  })), [JSON.stringify(data)])

  const [graph, actions] = useSeries(SERIES, generationData, {...SERIES_OPTIONS, zoomCallback})

  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <LineChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('HH:mm')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />

          <Tooltip
            content={args => CustomTooltip({...args, series: graph.activeSeries})}
          />

          {graph.visibleSeries.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={SERIES[g].color}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={actions.isSeriesActive(g)}
            />
          )}

          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3"/>
        </LineChart>
      </ResponsiveContainer>
      <Legend
        series={SERIES}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
      />
    </>
  )

}

export default Generation