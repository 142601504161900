/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import BlurOnIcon from '@mui/icons-material/BlurOn';
import {WidgetContainer, Graphs} from 'components'
import {useDependency, useResource, useFilter} from 'hooks'
import { Widgets } from '.'
import moment from 'moment'
import { DEFAULT_WIDGET_REFRESH_MS } from 'constants'
import {useSavedFilters} from 'contexts/SavedFiltersContext'

const DEFAULT_FILTER = {
  region: 'NZ'
}

const NRSSRegionalPredictedLoadGraph = (props) => {
  const widget = Widgets['nrss-regional-predicted-load-graph']
  const [filter, setFilter] = useFilter(null, 'predictedload-nrss')
  const savedFilters = useSavedFilters()

  const [load, loadActions] = useResource('load')
  const [currentTime, setCurrentTime] = useState(moment())

  const updateWidget = () => {
    const {region} = filter
    setCurrentTime(moment().startOf('minute'))

    return region && Promise.all([
      loadActions.request('actual', {region}),
      loadActions.request('forecast', {
        from_trading_date: moment().subtract(1, 'days'),
        to_trading_date: moment().add(2, 'days'),
        run_type: 'N',
        node_id: region
      }),
    ])
  }

  const [loading, result, reload] = useDependency(updateWidget, [filter])
  const updatedAt = load.actual?.responseAt

  const [zoom, setZoom] = useState({})
  const zoomCallback = zoom => setZoom(zoom)

  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({...initialFilter, ...filter})
  }, [])

  return (
    <WidgetContainer
      widget={widget}
      title="NRSS Predicted Load By Region (MW)"
      icon={<BlurOnIcon/>}
      lastUpdatedAt={updatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={loading}
      onWidgetRefresh={reload}
      widgetAutoRefreshMs={DEFAULT_WIDGET_REFRESH_MS}
      renderFilter={() => <Graphs.RegionFilter filter={filter} onChange={setFilter} />}
      onFilterSave={() => savedFilters.update(widget, filter)}
      zoom={zoom}
      {...props}
    >
      <Graphs.NRSSRegionalPredictedLoad
        actualData={loading ? null : load.actual?.data}
        forecastData={loading ? null : load.forecast?.data}
        zoomCallback={zoomCallback}
        currentTime={currentTime}
      />
    </WidgetContainer>
  )
}

export default NRSSRegionalPredictedLoadGraph