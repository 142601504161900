/** @jsxImportSource @emotion/react */
import React from 'react'
import { Autocomplete } from './'

const GENERATION_TYPES = [
  {id: 'HYD', name: 'Hydro'},
  {id: 'WIN', name: 'Wind'},
  {id: 'GEO', name: 'Geothermal'},
  {id: 'COG', name: 'Cogen'},
  {id: 'BAT', name: 'Battery'},
  {id: 'GAS', name: 'Gas'},
  {id: 'CG', name: 'Coal'},
  {id: 'LIQ', name: 'Diesel/Oil'},
  {id: 'SOL', name: 'Solar'},
]

export const GenerationTypePicker = ({ label='Generation Type', value, onChange, fullWidth, multiple }) => {

  const getOptionLabel = ({name}) => name

  const currentValue = value || (multiple ? [] : '')

  return (
    <Autocomplete
      fullWidth={fullWidth}
      label={label}
      getOptionLabel={getOptionLabel}
      options={GENERATION_TYPES}
      value={currentValue}
      onChange={onChange}
      multiple
    />
  )

}

export default GenerationTypePicker