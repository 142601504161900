/** @jsxImportSource @emotion/react */
import React from 'react'
import Alert from '@mui/material/Alert'
import InfoIcon from '@mui/icons-material/Info'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

export const InfoTooltip = styled(({ className, tooltips, title, ...props }) => {
  const [tooltipsEnabled, setTooltips] = tooltips
  return tooltipsEnabled && title ?
    <Tooltip title={title} {...props} classes={{ popper: className }}>
      <InfoIcon fontSize="small" css={styles.infoIcon}/>
    </Tooltip> : null
})(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0288d1',
    color: '#fff',
    boxShadow: theme.shadows[2],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#0288d1',
  },
}));

export const InfoTip = ({ tooltips=[], children, ...props }) => {
  const [tooltipsEnabled, setTooltips] = tooltips
  return (tooltipsEnabled ?
    <Alert icon={<InfoIcon fontSize="inherit" />} severity="info" variant="filled" {...props} onClose={() => setTooltips(false)}>
      {children}
    </Alert> : null)
}



const styles = {
  infoIcon: {
    verticalAlign: 'text-top',
    color: '#0288d1',
    backgroundColor: '#fff',
    marginLeft: 8,
    borderRadius: 10,
  }
}