/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import {useResource, useFilter, useAuthorization} from 'hooks'
import {WidgetContainer, Reports} from 'components'
import AssessmentIcon from '@mui/icons-material/Assessment'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { Widgets } from './'
import moment from 'moment'
import { downloadBlob } from 'utils'
import { RCPD_YEARS } from 'constants'

// combined default for all report types
const DEFAULT_FILTER = {
  reportType: 'scada',
  scadaType: 'load',
  from: moment().format('YYYY-MM-DD'),
  to:   moment().format('YYYY-MM-DD'),
  nodes: [],
  priceType: 'average',
  runType: 'F',
  reserveReportType: 'aggregated',
  location: '',
  powerType: '',
  reserveType: '',
  arcType: '',
  emissionType: 'generation',
  generationTypes: [],
  rcpdType: 'load',
  region: 'UNI',
  year: RCPD_YEARS[0],
}

const REPORT_TYPES = ['scada', 'price', 'reserves', 'arc', 'emissions', 'rcpd']

const Filter = ({filter, onChange}) => {
  switch(filter.reportType){
  case 'scada':
    return <Reports.ScadaFilter filter={filter} onChange={onChange} />
  case 'price':
    return <Reports.PriceFilter filter={filter} onChange={onChange} />
  case 'reserves':
    return <Reports.ReservesFilter filter={filter} onChange={onChange} />
  case 'arc':
    return <Reports.ArcFilter filter={filter} onChange={onChange} />
  case 'emissions':
    return <Reports.EmissionsFilter filter={filter} onChange={onChange} />
  case 'rcpd':
    return <Reports.RCPDFilter filter={filter} onChange={onChange} />
  default:
    return null
  }
}

const filename = ({reportType, scadaType, priceType, reserveReportType, arcType, emissionType, region, year}) => {
  const prefix = moment().format('YYYY-MM-DD-HHmm')

  switch(reportType){
  case 'scada':
    return `${prefix}_scada_${scadaType}.csv`
  case 'price':
    return `${prefix}_price_${priceType}.csv`
  case 'reserves':
    return `${prefix}_reserves_${reserveReportType}.csv`
  case 'arc':
    return `${prefix}_arc_${arcType}.csv`
  case 'emissions':
    return `${prefix}_emissions_${emissionType}.csv`
  case 'rcpd':
    return `${prefix}_rcpd_${region}_${year}.csv`
  default:
    return null
  }
}

const Report = (props) => {
  const widget = Widgets['report']
  const [filter, setFilter] = useFilter(null, 'report')
  const [report, reportActions] = useResource('report')
  const [loading, setLoading] = useState(false)
  const [preview, setPreview] = useState(null)
  const { permittedReports } = useAuthorization()

  const fetchReport = ({csv=false}={}) => async () => {
    try {
      const { reportType } = filter
      setLoading(true)
      if (REPORT_TYPES.includes(reportType)){
        const action = `${reportType}${csv ? '_csv' : ''}`
        const {data} = await reportActions.request(action, filter)
        csv && downloadBlob(data, filename(filter))
        !csv && setPreview({filter, data})
      }
    } catch (e) {
      console.log(e)
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setFilter({...DEFAULT_FILTER, reportType: permittedReports[0], ...filter})
  }, [])

  useEffect(() => {
    fetchReport({csv: false})()
  }, [filter])

  return (
      <WidgetContainer
        widget={widget}
        title="Report"
        icon={<AssessmentIcon/>}
        lastUpdatedAt={null}
        loading={loading}
        onWidgetRefresh={fetchReport}
        renderFilter={() => (
          <Grid container rowSpacing={0} columnSpacing={{xs: 0.5, sm: 1}}>
            { /* First select a report type */ }
            <Reports.ReportTypeFilter filter={filter} onChange={setFilter} />
            { /* Then show a filter specific to the selected report type */ }
            <Filter filter={filter} onChange={setFilter}/>
          </Grid>
        )}
        renderActions={() => (
          <Button size="small" onClick={fetchReport({csv: true})} variant="contained">
            Download
          </Button>
        )}
        {...props}
        toggleFullScreen={null}
      >
      <Reports.Report
        filter={preview?.filter}
        data={preview?.data}
        error={report.errors[filter.reportType]}
      />
    </WidgetContainer>

  )
}

const styles = {
}

export default props => (
  <Report {...props}/>
)