/** @jsxImportSource @emotion/react */
import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export const GRID_ZONES = [
  {id: '15', label: 'All'},
  {id: '1', label: 'Northland'},
  {id: '2', label: 'Auckland'},
  {id: '3', label: 'Hamilton'},
  {id: '4', label: 'Edgecumbe'},
  {id: '5', label: 'Hawkes_bay'},
  {id: '6', label: 'Taranaki'},
  {id: '7', label: 'Bunnythorpe'},
  {id: '8', label: 'Wellington'},
  {id: '9', label: 'Nelson'},
  {id: '10', label: 'Christchurch'},
  {id: '11', label: 'Canterbury'},
  {id: '12', label: 'West_coast'},
  {id: '13', label: 'Otago'},
  {id: '14', label: 'Southland'},
]

const GridZoneFilter = ({filter, onChange}) => {
  const {gridZone} = filter

  return (
    <div css={styles.container}>
      <FormControl variant="standard" fullWidth>
          <InputLabel id="arc-type">Grid Zone</InputLabel>
          <Select
            labelId="grid-zone"
            value={gridZone || ''}
            label="Grid Zone"
            onChange={({target: {value: gridZone}}) => onChange({...filter, gridZone})}
          >
            {GRID_ZONES.map(({id, label}) => <MenuItem key={id} value={id}>{label}</MenuItem>)}
          </Select>
        </FormControl>
    </div>
  )

}

const styles = {
  container: {
    display: 'flex',
  }
}

export default GridZoneFilter