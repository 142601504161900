/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import PersonIcon from '@mui/icons-material/Person'
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import AssessmentIcon from '@mui/icons-material/Assessment';
import WindPowerIcon from '@mui/icons-material/WindPower';
import BoltIcon from '@mui/icons-material/Bolt';
import PowerIcon from '@mui/icons-material/Power';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import InfoIcon from '@mui/icons-material/Info';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTokens } from 'contexts/TokensContext';
import { useAuthorization, useVersion } from 'hooks';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { StylesObject } from 'types'
import { CurrentTradingPeriod, EnvironmentLabel } from 'components'
import _ from 'lodash'

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  borderRight: 'none',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  // hide left nav on mobile
  [theme.breakpoints.only('xs')]: {
    width: 0,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Nav = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const [open, setOpen] = React.useState(false);
  const [, tokensActions] = useTokens()
  const authorization = useAuthorization()
  const [version] = useVersion()
  const history = useHistory()
  const { pathname } = useLocation()
  const userMenuButtonRef = React.useRef(null)
  const [useMenuOpen, setUserMenuOpen] = React.useState(false)

  const pages = _.compact([
    {text: 'Favourites', Icon: FavoriteIcon, to: '/favourites'},
    {text: 'Overview', Icon: EmojiObjectsIcon, to: '/'},
    {text: 'Generation', Icon: BoltIcon, to: '/generation'},
    {text: 'Peak Demand', Icon: PowerIcon, to: '/demand'},
    {text: 'Trading', Icon: BusinessCenterIcon, to: '/trading'},
    authorization.permittedReports.length ? {text: 'Reports', Icon: AssessmentIcon, to: '/reports'} : null,
  ])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar position="sticky" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            css={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div css={styles.head}>
            <Link to='/' css={styles.home}>
              <img src="/logo.svg" alt="EM6" css={styles.logo} />
              {!isMobile && <Typography css={styles.title} variant="h5">Electricity Market Overview</Typography>}
            </Link>
          </div>
          {!isMobile && <EnvironmentLabel/>}
          {!isMobile && <CurrentTradingPeriod/>}
          <IconButton
            color="inherit"
            onClick={() => setUserMenuOpen(true)}
            ref={userMenuButtonRef}
            size="large">
            <PersonIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        id="user-menu"
        anchorEl={userMenuButtonRef.current}
        open={useMenuOpen}
        onClose={() => setUserMenuOpen(false)}
      >
        <MenuItem
          onClick={() => {
            history.push('/profile')
            setUserMenuOpen(false)
          }}> My Profile
        </MenuItem>
        <MenuItem onClick={tokensActions.destroy}>Logout</MenuItem>
      </Menu>
      <Drawer
        variant="permanent"
        open={open}
        onMouseLeave={() => open && handleDrawerClose()}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon/>
          </IconButton>
        </DrawerHeader>
        <List onMouseOver={() => !open && handleDrawerOpen()} css={styles.list}>
          {pages.map(({text, Icon, to}) => {
              const active = pathname === to
              return (
                <ListItem key={text} disablePadding sx={{ display: 'block' }} style={active ? {backgroundColor: 'rgba(0,32,48,.5019607843137255)'} : {}}>
                  <ListItemButton
                    onClick={() => {
                      to && history.push(to)
                      handleDrawerClose()
                    }}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon fontSize='large' style={{color: active ? '#00A9A5' : '#fff'}}/>
                    </ListItemIcon>
                    <ListItemText css={styles.navText} primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              )})}
        </List>
      </Drawer>
    </>
  );
}

const styles = {
  head: {
    width: '100%',
  },
  home: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    paddingLeft: 25,
  },
  logo: {
    margin: '10px 0px 15px 10px',
  },
  drawer: {
    '& .MuiPaper-root': {
      width: 200,
    }
  },
  list: {
    paddingTop: 0,
  },
  navText: {
    '& .MuiListItemText-primary': {
      textTransform: 'uppercase',
    }
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 10,
    fontSize: 11,
    color: "#9a9a9a"
  }
} as StylesObject

export default Nav
