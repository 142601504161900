/** @jsxImportSource @emotion/react */
import {useEffect, Fragment, useRef} from 'react'
import { SessionStatus } from '../../constants'
import * as Containers from 'containers'
import { Routes, Nav } from '.'
import CssBaseline from '@mui/material/CssBaseline'
import {SnackbarProvider} from "contexts/SnackbarContext";
import {FavouritesProvider} from "contexts/FavouritesContext";
import {SavedFiltersProvider} from "contexts/SavedFiltersContext";
import {TokensProvider, useTokens} from "contexts/TokensContext";
import {NodesProvider} from "contexts/NodesContext"
import { GoogleAnalytics } from 'components'

const styles = {
  contentWrapper: {
    flex: '1 0 auto',
    display: 'flex',
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      //IE ONLY
      minHeight: 'calc(100vh - 130px)'
    },
    '& > div': {
      flex: '1 0 auto',
      maxWidth: '100%'
    }
  }
}

const CurrentRoutes = ({loginState}) => {
  switch (loginState) {
    case SessionStatus.UNKNOWN:         { return <Routes.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Routes.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Routes.Unauthenticated/> }
    default: { return null }
  }
}

const CurrentNav = ({loginState}) => {
  switch (loginState) {
    case SessionStatus.UNKNOWN:         { return <Nav.Unknown/> }
    case SessionStatus.AUTHENTICATED:   { return <Nav.Authenticated/> }
    case SessionStatus.UNAUTHENTICATED: { return <Nav.Unauthenticated/> }
    default: { return null }
  }
}

const App = () => {
  const [tokens, actions] = useTokens();

  useEffect(() => {
    actions.verify(true)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <Fragment>
      <GoogleAnalytics/>
      <CssBaseline />
      <CurrentNav loginState={tokens.loginState} />
      <div css={styles.contentWrapper} ref={containerRef}>
        <CurrentRoutes loginState={tokens.loginState} />
      </div>
      <Containers.Shared.Snackbar/>
      <Containers.Shared.Footer/>
    </Fragment>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: any) => (
  <TokensProvider>
    <SnackbarProvider>
      <FavouritesProvider>
        <SavedFiltersProvider>
          <NodesProvider>
            <App {...props} />
          </NodesProvider>
        </SavedFiltersProvider>
      </FavouritesProvider>
    </SnackbarProvider>
  </TokensProvider>
)