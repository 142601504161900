/** @jsxImportSource @emotion/react */
import React from 'react'
import Grid from '@mui/material/Grid'
import {PageContainer} from 'components'
import {useFavourites} from 'contexts/FavouritesContext'
import { StylesObject } from "types"
import { Widget, Widgets } from '../../widgets'


const Favourites = () => {

  const {widgets} = useFavourites()

  return (
    <PageContainer>

      <Grid container spacing={2}>
        {widgets.map(widget => <Widget key={widget.id} id={widget.id} favourite/>)}
      </Grid>

    </PageContainer>
  )
}

const styles = {

} as StylesObject

export default Favourites