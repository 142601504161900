export function debounce(fnc: (...args: any[]) => any, wait?: number, context?: any){
  let timer, promise, resolve
  return function (...args: any[]) {
    promise = promise || new Promise(r => resolve = r)
    clearTimeout(timer)
    timer = setTimeout(() => {
      resolve(fnc.bind(context)(...args))
      promise = null
    }, wait || 250)
    return promise
  }
}

export function throttle(fnc: (...args: any[]) => any, wait?: number, context?: any){
  let isQueued = false
  let promise, resolve, result

  return function (...args: any[]) {
    if(isQueued) return promise
    isQueued = true
    promise = new Promise(r => resolve = r)
    result = fnc.bind(context)(...args)
    setTimeout(() => {
      resolve(result)
      isQueued = false
      promise = null
    }, wait || 250)
    return promise
  }
}

export function timeout(time=0){
  return new Promise(resolve => setTimeout(resolve, time))
}
