/** @jsxImportSource @emotion/react */
import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {intervalForTime} from 'utils'
import Chip from '@mui/material/Chip'


export const CurrentTradingPeriod = () => {

  const [time, setTime] = useState(moment())

  const interval = intervalForTime(time)

  useEffect(() => {
    const _timer = setInterval(() => setTime(moment()), 1000)
    return () => clearInterval(_timer)
  }, [])

  return (
    <div css={styles.container}>
      <Chip css={styles.chip} label={`${time.format('HH:mm:ss')} - TP${interval}`}/>
    </div>
  )
}

const styles = {
  container: {
    paddingRight: 10
  },
  chip: {
    backgroundColor: '#002030',
    color: '#fff',
    fontWeight: 600,
  }
}

export default CurrentTradingPeriod