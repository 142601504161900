/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import AirlineStopsIcon from '@mui/icons-material/AirlineStops'
import { WidgetContainer, Graphs } from 'components'
import {useSavedFilters} from 'contexts/SavedFiltersContext'

import { useDependency, useResource, useFilter } from 'hooks'
import { Widgets } from '.'
import _ from 'lodash'
const DEFAULT_FILTER = {
  region: 'UNI',
  regionLoad:"load48hrs"
}

const DEFAULT_DAY_SERIES = [
  'maxPeak',
  'avgPeak',
  'minPeak',
  'today',
  'yesterday',
  'revenueYesterday',
]
const DEFAULT_HOUR_SERIES = [
  'rcpd1Mw',
  'currentTpAvg',
]
const RegionalPeaksGraph = (props) => {

  const widget = Widgets['regional-peaks-graph']
  const [filter, setFilter] = useFilter(null, 'regionalPeaks')
  const savedFilters = useSavedFilters()
  const [regionalPeak, regionalPeakActions] = useResource('regionalPeak')
  const [zoom, setZoom] = useState({})
  const regionLoad = filter?.regionLoad
  const refreshRate = (regionLoad === "load48hrs" ? 5000 : 1000) * 60
  const series = regionLoad === "load48hrs" ? DEFAULT_DAY_SERIES : DEFAULT_HOUR_SERIES
  const [regionalPeakLoading, result, reloadRegionalPeaks] = useDependency(() =>
    filter && Object.keys(filter).length && regionalPeakActions.request(regionLoad, filter)
    , [filter, regionLoad])
  const zoomCallback = zoom => setZoom(zoom)
  const getLastUpdatedTime = () => {
    if(regionLoad === "load48hrs") return _.last(regionalPeak?.[regionLoad]?.data?.today || [])?.timestamp
    if(regionLoad === "load2hrs") return _.last(regionalPeak?.[regionLoad]?.data?.periodData || [])?.timestamp
    return null
  }

  useEffect(() => {
    const initialFilter = savedFilters.load(widget) || DEFAULT_FILTER
    setFilter({ ...initialFilter, ...filter })
  }, [])

  const regionalPeakUpdatedAt = getLastUpdatedTime()

  return (
    <WidgetContainer
      widget={widget}
      title="Regional Peaks (MW)"
      icon={<AirlineStopsIcon />}
      lastUpdatedAt={regionalPeakUpdatedAt}
      lastUpdatedAtAddMinutes={0}
      loading={regionalPeakLoading}
      onWidgetRefresh={reloadRegionalPeaks}
      widgetAutoRefreshMs={refreshRate}
      renderFilter={() => <Graphs.RegionalPeaksFilter filter={filter} onChange={setFilter}/>}
      onFilterSave={() => savedFilters.update(widget, filter)}
      zoom={zoom}
      {...props}
    >

      <Graphs.RegionalPeaks
        series={series}
        regionLoad={regionLoad}
        loading={regionalPeakLoading}
        data={regionalPeak[regionLoad]?.data}
        zoomCallback={zoomCallback}
      />
    </WidgetContainer>
  )
}

export default RegionalPeaksGraph