/** @jsxImportSource @emotion/react */
import React, { useMemo } from 'react'
import moment from 'moment'
import { round } from 'utils'
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area } from 'recharts';
import _ from 'lodash'
import { TooltipList, TooltipListItem, TooltipHeader, formatTimestamp } from './Tooltip'
import Legend from './Legend'
import { useSeries } from 'hooks'

const SERIES = {
  'maxPeak': {
    label: 'Max. TPM peak',
    color: '#cf0059',
  },
  'avgPeak': {
    label: 'Avg. TPM peak',
    color: '#00a9a5',
  },
  'minPeak': {
    label: 'Min. TPM peak',
    color: '#8552af',
  },
  'today': {
    label: 'Today',
    color: '#b1e9e6',
  },
  'yesterday': {
    label: 'Yesterday',
    color: '#cbe612',
    strokeDasharray: "5 4",
  },
  'revenueYesterday': {
    label: 'Yesterday\'s meter',
    color: '#ed8b00',
  },
  'rcpd1Mw': {
    label: 'Current Load',
    color: '#b1e9e6',
  },
  'currentTpAvg': {
    label: 'Current Trading Period Average Load',
    color: '#ed8b00',
  },

}


const CustomTooltip = ({ active, series, payload, regionLoad }: any) => {
  if (active && payload?.length && series?.length) {
    const { timestamp, tradingPeriod, ...data } = payload[0].payload ?? {};
    return (
      <TooltipList>
        <TooltipHeader label={formatTimestamp(timestamp, {tradingPeriod})} />
        {regionLoad === "load2hrs" && <TooltipListItem color="white" strokeDasharray="1"label="Time" value={moment(timestamp).format('h:mm a')} />}
        {regionLoad === "load2hrs" && <TooltipListItem color="white" strokeDasharray="1" label="Min Peak" value={data['regionMinPeak']} />}
        {series.map((g, idx) => {
          const { label, color, strokeDasharray } = SERIES[g]
          return (
            <TooltipListItem
              key={idx}
              label={label}
              color={color}
              strokeDasharray={strokeDasharray}
              value={`${round(data[g])} MW`}
            />
          )
        })
        }
      </TooltipList>
    )
  }
}
const RegionalPeaks = ({ data = {}, width = '100%', height = '100%', zoomCallback, series = null, regionLoad }) => {

  const get48HourData = () => {
    const { yesterday, today, regionTotals } = data
    const { avgPeak, minPeak, maxPeak } = regionTotals?.[0] || {}
    const todayByTimestamp = _.groupBy(today, 'tradingPeriod')
    const yesterdayByTimestamp = _.groupBy(yesterday, 'timestamp')
    return format48HourData(yesterdayByTimestamp, todayByTimestamp, avgPeak, minPeak, maxPeak)
  }

  const format48HourData = (yesterdayByTimestamp, todayByTimestamp, avgPeak, minPeak, maxPeak) =>
    Object.keys(yesterdayByTimestamp).map(
      timestamp => ({
        timestamp: moment(timestamp).valueOf(),
        ...(yesterdayByTimestamp[timestamp].reduce((acc: any, curr) => ({
          ...acc,
          tradingPeriod: curr.tradingPeriod,
          yesterday: curr.scadaMw,
          revenueYesterday: curr.revenueMw,
          avgPeak: avgPeak,
          maxPeak: maxPeak,
          minPeak: minPeak,
          today: todayByTimestamp[`${curr.tradingPeriod}`]?.[0].scadaMw || null
        }), {}))
      })
    ) || []

  const format2HourData = () => {
    const { periodData, regionPeaks } = data
    const { currentTpAvg, minPeak } = regionPeaks?.[0] || {}
    return periodData?.map(period => ({
      ...period,
      currentTpAvg: currentTpAvg,
      regionMinPeak: minPeak
    })) || []
  }
  const createData = () => regionLoad === "load48hrs" ? get48HourData() : format2HourData()

  const graphData = useMemo(() => createData(), [JSON.stringify(data), regionLoad])
  const [graph, actions] = useSeries(SERIES, graphData, { zoomCallback })
  return (
    <>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={graph.data}
          {...actions.chartProps}
        >
          <XAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            dataKey="timestamp"
            scale="time"
            type="number"
            domain={graph.xDomain}
            ticks={graph.xTicks}
            tickFormatter={tick => moment(tick).format('HH:mm')}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            tick={{
              fill: 'white',
              fontSize: '14px',
            }}
            type="number"
            axisLine={false}
            tickLine={false}
            scale={graph.yScale}
            domain={graph.yDomain}
          />

          <Tooltip
            content={args => CustomTooltip({ ...args, series: graph.activeSeries, regionLoad: regionLoad })}
          />

          {graph.visibleSeries.includes("today") && <Area type="monotone" dataKey="today" fill="#B1E9E6" />}
          {graph.visibleSeries.includes("rcpd1Mw") && <Area type="monotone" dataKey="rcpd1Mw" fill="#B1E9E6" />}
          {graph.visibleSeries.map(g =>
            <Line
              key={g}
              type="linear"
              dataKey={g}
              stroke={SERIES[g].color}
              strokeDasharray={SERIES[g].strokeDasharray}
              animationDuration={300}
              dot={false}
              strokeWidth={2}
              activeDot={actions.isSeriesActive(g)}
            />
          )}


          {actions.renderZoomOverlay()}

          <CartesianGrid stroke="#fff" opacity={0.25} strokeDasharray="3 3" />
        </ComposedChart>
      </ResponsiveContainer>
      <Legend
        key={regionLoad}
        series={series ? _.pick(SERIES, series) : SERIES}
        visible={graph.visibleSeries}
        onSeriesToggle={actions.setVisibleSeries}
      />
    </>
  )
}

export default RegionalPeaks