import moment from 'moment'

export function userFriendlyDate(date){
  return moment(date).format('MMM Do YY, h:mm:ss a')
}

export function roundDateOrMoment(date, nearestMins){
  const duration = moment.duration(nearestMins, 'minute')
  return moment(Math.floor((+date) / (+duration)) * (+duration))
}

export function formatLocaleDate(date, {includeYear=true}={}){
  return moment(date).format(`DD/MM${includeYear ? '/YYYY' : ''}`)
}

export function formatLocaleDateTime(date, {includeYear=true, includeSeconds=false}={}){
  return moment(date).format(`HH:mm${includeSeconds ? ':ss' : ''} DD/MM${includeYear ? '/YYYY' : ''}`)
}

export function currentIntervalTime(){
  return roundDateOrMoment(moment(), 30)
}

export function previousIntervalTime(){
  return currentIntervalTime().subtract(30, 'minutes')
}

export function intervalForTime(time){
  // @ts-ignore
  return parseInt(moment(time).diff(moment(time).startOf('day'), 'minutes') / 30, 10) + 1
}

// start time
export function timeForInterval(interval, time=moment()){
  return time.startOf('day').add((interval-1)*30, 'minutes')
}