/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { StylesObject } from 'types';

export type LoaderProps = {
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  size?: number;
}

// TODO: should this component be removed in favor of MUI CircularProgress?
export class Loader extends Component<LoaderProps> {
  static defaultProps = {
    size: 200
  }

  loaderStyle = () => {
    return {
      width: this.props.size,
      height: this.props.size
    }
  }

  render = () =>
    <div css={[styles.loader, this.loaderStyle()]} />
}

const styles = {
  loader: (theme) => ({
    margin: "20px auto",
    fontSize: "10px",
    position: "relative",
    textIndent: "-9999em",
    borderTop: `1.1em solid ${theme.palette.secondary.main}`,
    borderRight: `1.1em solid ${theme.palette.secondary.main}`,
    borderBottom: `1.1em solid ${theme.palette.secondary.main}`,
    borderLeft: "1.1em solid rgba(0, 0, 0, 0)",
    transform: "translateZ(0)",
    animation: "load8 1.1s infinite linear",
    borderRadius: "50%",
    width: "10em",
    height: "10em",
    '& :after': {
      borderRadius: "50%",
      width: "10em",
      height: "10em"
    },
    '@keyframes load8': {
      '0%': {
        transform: "rotate(0deg)"
      },
      '100%': {
        transform: "rotate(360deg)"
      }
    }
  }),
} as StylesObject
export default Loader
